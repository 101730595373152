import React from "react";
import { Button, Dropdown } from "react-bootstrap";
import { Person } from "react-bootstrap-icons";
import Tooltip from "@mui/material/Tooltip";
import { X } from "react-bootstrap-icons";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { getTrailInfo } from "../../apis/trail";
import { toastHelper } from "../../helpers/toast";
import "../../style/navbar.scss";
import { getCookie } from "../../helpers/cookie";
import { Link, Typography } from "@mui/material";
import DropDownChatNew from "../DropDownChat/DropDownChatNew";
import DropDownChat from "../DropDownChat/DropDownChat";
var company_id = getCookie("company_id");
interface NavbarProps {
  pathName: string;
  videoSrc: string;
}

interface NavbarState {
  tooltipOpen: boolean;
  infoTipOpen: boolean;
  hover: boolean;
  trialDays: number;
}

class Navbar extends React.Component<NavbarProps, NavbarState> {
  msgBodyRef = React.createRef<HTMLDivElement>();

  constructor(props: NavbarProps) {
    super(props);
    this.state = {
      tooltipOpen: false,
      infoTipOpen: false,
      hover: false,
      trialDays: -1,
    };
  }

  componentDidMount(): void {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const ChatbotParam = urlSearchParams.get("botname");
    if (ChatbotParam == "nav") {
      this.setState({ tooltipOpen: true });
    }
    if (ChatbotParam == "ask") {
      this.setState({ tooltipOpen: true });
    }
  }

  checkTrial = async () => {
    try {
      const res: any = await getTrailInfo();
      this.setState({ trialDays: res.trail_days_left });
    } catch (err: any) {
      console.log(err);
      toastHelper.error(err.response.data.message);
    }
  };
  handleLogout = () => {
    // List of cookies to clear
    const cookiesToClear = [
      "company_name",
      "company_id",
      "current_user",
      "company_url",
      "token",
      "email",
      "__stripe_mid",
      "__stripe_sid",
    ];

    // Iterate through the list and clear each cookie
    cookiesToClear.forEach((cookieName) => {
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.neuralmind.io;`;
      // document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=localhost;`;
    });
    localStorage.clear();
    // Redirect to the logout URL or your desired location
    // window.location.href = "http://localhost:4000/logout";
    window.location.href = "https://api.neuralmind.io/logout";
  };
  render(): React.ReactNode {
    return (
      <div className="d-flex justify-content-between align-items-center bg-white my-2 px-3">
        <div className="d-flex justify-content-between align-items-center">
          {this.props.pathName == "Agents" ||
          this.props.pathName == "API" ||
          this.props.pathName == "Plans and Billing" ||
          this.props.pathName === "Existing Connect DB" ? (
            <Breadcrumbs
              separator={
                <img
                  src="/assets/images/gray-arrow.svg"
                  style={{ fill: "#909090" }}
                />
              }
              aria-label="breadcrumb"
            >
              {[
                <Link
                  underline="hover"
                  key="1"
                  color="inherit"
                  href={`https://app.neuralmind.io/${
                    this.props.pathName === "Existing Connect DB"
                      ? "connectdb"
                      : "admin"
                  }`}
                  fontWeight={600}
                  // onClick={handleClick}
                >
                  {`Neuralmind - ${
                    this.props.pathName === "Existing Connect DB"
                      ? "Connect DB"
                      : "Admin"
                  }`}
                </Link>,
                <Typography key="3" fontWeight={600} color="text.primary">
                  {this.props.pathName}
                </Typography>,
              ]}
            </Breadcrumbs>
          ) : (
            <p className="m-0 fw-bolder fs-5">
              Neuralmind - {this.props.pathName}
            </p>
          )}

          <div className="ms-3">
            <Tooltip
              className="list-item-info"
              open={this.state.hover || this.state.infoTipOpen}
              placement="right-start"
              classes={{
                tooltip: this.state.infoTipOpen ? "navbar__infotip" : "",
              }}
              onMouseEnter={() => {
                this.setState({ hover: true });
              }}
              onClick={() => {
                this.setState({
                  infoTipOpen: this.state.infoTipOpen ? false : true,
                });
              }}
              onMouseLeave={() => {
                this.setState({ hover: false });
              }}
              title={
                this.state.infoTipOpen ? (
                  <div className="bg-white tool-manager p-3 border shadow text-black">
                    <div className="text-end">
                      <X
                        width="30"
                        height="30"
                        role="button"
                        onClick={() =>
                          this.setState({ infoTipOpen: false, hover: false })
                        }
                      />
                    </div>
                    <div className="d-flex flex-column h-100">
                      {window.location.pathname === "/chat" ? (
                        <p className="fs-6 fw-bold">
                          About this feature:{" "}
                          <span className="fs-6 fw-normal">
                            Test the responses of our AI engine by querying the
                            Insights and Ask modules. We have connected and
                            trained our model on some sample data to help you
                            get started. Insights provides analytics on your
                            data and Ask provides answers to product FAQs and
                            other documentation.
                          </span>
                          <br />
                          <a
                            href="https://docs.neuralmind.io/test-and-go-live/chat"
                            target="_blank"
                            rel="noreferrer"
                          >
                            More
                          </a>
                        </p>
                      ) : window.location.pathname === "/embed" ? (
                        <p className="fs-6 fw-bold">
                          About this feature:{" "}
                          <span className="fs-6 fw-normal">
                            Now that you're ready to go live, you can place your
                            Analytics embed it in your Software or website by
                            generating an embed script below.
                          </span>
                          <br />
                          <a
                            href=" https://docs.neuralmind.io/test-and-go-live/embed"
                            target="_blank"
                            rel="noreferrer"
                          >
                            More
                          </a>
                        </p>
                      ) : window.location.pathname === "/connectdb" ? (
                        <p className="fs-6 fw-bold">
                          About this feature:{" "}
                          <span className="fs-6 fw-normal">
                            Connect your Database and see our Analytics engine
                            in action. We currently support MySQL, Postgres and
                            Maria DB. If you use a different database, get in
                            touch and we'll build the connection.
                          </span>
                          <br />
                          <a
                            href=" https://docs.neuralmind.io/connect-database/connect-db"
                            target="_blank"
                            rel="noreferrer"
                          >
                            More
                          </a>
                        </p>
                      ) : window.location.pathname === "/admin" ? (
                        <p className="fs-6 fw-bold">
                          About this feature:{" "}
                          <span className="fs-6 fw-normal">
                            Add agents to your account, access your API key or
                            upgrade your plan.
                          </span>
                          <br />
                        </p>
                      ) : window.location.pathname === "/admin/agent" ? (
                        <p className="fs-6 fw-bold">
                          About this feature:{" "}
                          <span className="fs-6 fw-normal">
                            Add agents to your account, access your API key or
                            upgrade your plan.
                          </span>
                          <br />
                        </p>
                      ) : window.location.pathname === "/admin/api" ? (
                        <p className="fs-6 fw-bold">
                          About this feature:{" "}
                          <span className="fs-6 fw-normal">
                            Add agents to your account, access your API key or
                            upgrade your plan.
                          </span>
                          <br />
                        </p>
                      ) : window.location.pathname === "/admin/bills" ? (
                        <p className="fs-6 fw-bold">
                          About this feature:{" "}
                          <span className="fs-6 fw-normal">
                            Add agents to your account, access your API key or
                            upgrade your plan.
                          </span>
                          <br />
                        </p>
                      ) : (
                        <p className="fs-6 fw-bold">
                          About this feature:{" "}
                          <span className="fs-6 fw-normal">
                            The train section is designed to handle
                            product-centric queries, frequently asked questions,
                            and any other material you train it with. Whether
                            you're building and maintaining your Knowledge Base
                            or instantly drawing from documentation on an
                            existing URL, Neuralmind's AI model will adapt to
                            your content and answer your customer queries.
                          </span>
                          <br />
                          <a
                            href=" https://docs.neuralmind.io/ask/train-ai-model"
                            target="_blank"
                            rel="noreferrer"
                          >
                            More
                          </a>
                        </p>
                      )}
                      {/* <p className="fs-6 fw-bold">Video Guide:</p>
                      <div className="col">
                        <iframe
                          className="navbar__youtube"
                          src={this.props.videoSrc}
                          title="YouTube video player"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                        />
                      </div> */}
                    </div>
                  </div>
                ) : this.state.hover ? (
                  <div
                    style={{
                      color: "white",
                      border: "1px solid black",
                      backgroundColor: "black",
                      padding: "3px",
                      borderRadius: "5px",
                    }}
                  >
                    More Information
                  </div>
                ) : null
              }
            >
              <img
                width="30"
                height="30"
                src="/assets/images/info-icon.svg"
                role="button"
                alt=""
              />
            </Tooltip>
          </div>
        </div>
        <div
          className="py-2 d-flex align-items-center"
          style={{ marginRight: "-17px" }}
        >
          {this.state.trialDays != -1 && (
            <div className="me-3">
              <p className="mb-0">Trial Days Left: {this.state.trialDays}</p>
            </div>
          )}
          <div>
            <a
              href="https://neuralmindsupport.slack.com/join/shared_invite/zt-1nnyve4jn-oCN9vlanZKYI1~X_AvSaiQ#/shared-invite/email"
              target="_blank"
            >
              <img
                src="/assets/images/slack-icon.svg"
                width="30"
                height="30"
                alt=""
              />
            </a>
          </div>
          <Dropdown className="mx-2">
            <Dropdown.Toggle variant="default">
              <Person color="#212529" size={30} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {/* <Dropdown.Item href={`https://api.neuralmind.io/account`}> */}
              <Dropdown.Item
                href={`https://api.neuralmind.io/account?id=${company_id}`}
                // href={`http://localhost:4000/account?id=${company_id}`}
              >
                Companies
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                // href="https://api.neuralmind.io/logout"
                // href="http://localhost:4000/logout"
                // onClick={() => localStorage.clear()}
                onClick={this.handleLogout}
              >
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {/* <Tooltip
            open={this.state.tooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            placement="bottom-start"
            PopperProps={{
              sx: { zIndex: "unset" },
            }}
            classes={{
              tooltip: "navbar__tooltip",
            }}
            onClick={() => {
              if (this.state.tooltipOpen) this.setState({ tooltipOpen: false });
              else this.setState({ tooltipOpen: true });
            }}
            title={
              <div className="border h-100">
                <DropDownChat botname="nav" />
              </div>
            }
          >
            <Button variant="light" className="navbar__btn">
              <img src="/assets/images/logo.svg" width="35" height="35" />
            </Button>
          </Tooltip> */}
        </div>
      </div>
    );
  }
}

export default Navbar;
