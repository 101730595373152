import React, { Component } from "react";
import { ChatContent, ChatTextArea } from "./ChatBody";
import { Button, styled } from "@mui/material";
import { getCookie } from "../../helpers/cookie";
import { Button as MuiButton } from "@mui/material";
import axiosNode from "../../helpers/funcs/axiosNode";
import { v4 as uuidv4 } from "uuid";
import { Card, Carousel, Col, Row } from "antd";
import "../../style/insights.scss";
import { getConnectDBs } from "../../apis/connect-db";

interface TProps {
  handleDelete: (e: any) => void;
  handleChange: (e: any) => void;
  handleSubmit: (e: any) => void;
  handleArrowButtonClick: (e: any) => void;
  handleDatasetArrowButtonClick: () => void;
  embed: boolean;
  clearChat: (e: any) => void;
  isTyped?: any;
  // newResponseReceived?: any;
  setIsTyped?: any;
  initialMsg?: string;
  botname: string;
  path: string;
  currTab: number;
  query: string;
  msgArr: {
    body: any;
    align: string;
  }[];
  loading: boolean;
  msgBodyRef: React.RefObject<HTMLDivElement>;
  showModalOption?: boolean;
  handleModelChange: (e: string) => void;
  modelArr: any[];
}

interface TState {
  showDialog: boolean;
  showDetails: boolean;
  isLoggedIn: boolean;
  slideData: Array<string>;
  isSampleConnection: boolean;
  clickedInsightContent: string | null;
  msgArr: { body: any; align: string }[];
  query: string;
}
const StyledMuiButton = styled(MuiButton)({
  "&:hover": {
    backgroundColor: "transparent", // Set the background color to transparent for the hover state
  },
});

// const slideData = [
//   "Ticket volume went up by 20% last week",
//   "Lead generation fell by 10% last month",
//   "Lead generation fell by 10% last month",
//   "Lead generation fell by 10% last month",
//   "Lead generation fell by 10% last month",
// ];
const insightCards = [
  {
    content: "List top 5 countries by revenue.",
    type: "story",
  },
  {
    content: "Show me the revenue from Greece based on gender.",
    type: "story",
  },
  {
    content: "Compare the revenue between Germany and Poland.",
    type: "analysis",
  },
  // Add more cards as needed
];
// const CustomNextArrow = (props: any) => {
//   const { onClick, embed, botname, query } = props;
//   return (
//     <button
//       className={
//         !embed && botname !== "nav"
//           ? "custom-carousel-arrow slick-next"
//           : " custom-carousel-arrow slick-next1"
//       }
//       onClick={onClick}
//     >
//       <img
//         src="/assets/images/right-arrow-brown.svg"
//         width={20}
//         height={20}
//         alt=""
//       />
//     </button>
//   );
// };
// const CustomPrevArrow = (props: any) => {
//   const { onClick, embed, botname } = props;
//   return (
//     <button
//       className={
//         !embed && botname !== "nav"
//           ? "custom-carousel-arrow slick-prev"
//           : " custom-carousel-arrow slick-prev1"
//       }
//       onClick={onClick}
//     >
//       <img
//         src="/assets/images/left-arrow-brown.svg"
//         width={20}
//         height={20}
//         alt=""
//       />
//     </button>
//   );
// };

export default class Insights extends Component<TProps, TState> {
  carouselRef: React.RefObject<any>;
  constructor(props: TProps) {
    super(props);
    this.carouselRef = React.createRef<any>();
    this.state = {
      showDialog: false,
      showDetails: false,
      isLoggedIn: false,
      isSampleConnection: false,
      msgArr: this.props.msgArr,
      slideData: ["loading...."],
      clickedInsightContent: null,
      query: "",
    };
  }

  // async componentDidMount() {
  // const isLoggedIn = await this.checkIsLoggedIn();
  // this.setState({ isLoggedIn: true });
  // // if (isLoggedIn) {
  // //   let slideData = await this.getPredefinedInsight();
  // this.setState({
  //   slideData: [
  //     "Ticket volume went up by 20% last week.Lead generation fell by 10% last month",
  //     "Lead generation fell by 10% last month",
  //     "Lead generation fell by 10% last month",
  //     "Lead generation fell by 10% last month",
  //     "Lead generation fell by 10% last month",
  //     "Lead generation fell by 10% last month",
  //     "Lead generation fell by 10% last month",
  //     "Lead generation fell by 10% last month",
  //   ],
  // });
  // // }

  async componentDidMount() {
    try {
      const { dbs }: any = await getConnectDBs();
      if (dbs.length && dbs[0]?.isSample) {
        this.setState({ isSampleConnection: dbs[0].isActive ?? false });
      }
    } catch (error) {}
    const isLoggedIn = await this.checkIsLoggedIn();
    this.setState({ isLoggedIn });
    // if (isLoggedIn) {
    // let slideData = await this.getPredefinedInsight();
    // this.setState({ slideData });
    // }
  }
  handleArrowClick = (value: any) => {
    this.props.handleArrowButtonClick(value);
    // this.setState({
    //   query: "",
    // });
  };

  handleDatasetArrowClick = () => {
    this.props.handleDatasetArrowButtonClick();
    // this.setState({
    //   query: "",
    // });
  };

  checkIsLoggedIn = async () => {
    const uniqueIdentifier = localStorage.getItem("uniqueIdentifier");
    let response = await axiosNode.get(
      `/insights/check-login?uniqueIdentifier=${uniqueIdentifier}`
    );
    return response?.data?.login || false;
  };

  getPredefinedInsight = async () => {
    let response = await axiosNode.get(`/insights/predefined-insight`);
    return response.data;
  };

  company_id = getCookie("company_id");
  getCookie = (cname: string) => {
    try {
      const name = cname + "=";
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie?.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    } catch (error) {
      console.log(error);
    }
  };
  handleShowDialog = () => {
    this.setState({ showDialog: true });
  };

  handleCloseDialog = () => {
    this.setState({ showDialog: false, showDetails: false });
  };

  toggleDetails = () => {
    const { showDetails } = this.state;
    if (!showDetails) {
      this.setState({ showDialog: true });
    } else {
      this.setState({ showDialog: false });
    }
    this.setState((prevState) => ({
      showDetails: !prevState.showDetails,
    }));
  };

  handleLogout = () => {
    // Perform logout logic
    localStorage.setItem("uniqueIdentifier", uuidv4());
    this.setState({ isLoggedIn: false, msgArr: [] });
    this.props.clearChat("insights");
  };

  handleLogin = () => {
    const uniqueIdentifier = localStorage.getItem("uniqueIdentifier");
    const currentUrl = window.location.href;

    window.location.href = `${
      process.env.REACT_APP_NODE_REST_API
    }api/v1/insights/auth?company_id=${
      this.company_id
    }&uniqueIdentifier=${uniqueIdentifier}&currTab=${this.props.currTab}&chat=${
      this.props.path || ""
    }${this.props.botname ? `&botname=${this.props.botname}` : ""}`;
  };

  handleNextClick = () => {
    if (this.carouselRef.current) {
      this.carouselRef.current.next();
    }
  };

  handlePrevClick = () => {
    if (this.carouselRef.current) {
      this.carouselRef.current.prev();
    }
  };

  render() {
    const {
      handleDelete,
      handleChange,
      handleSubmit,
      clearChat,
      query,
      msgArr,
      loading,
      msgBodyRef,
      initialMsg,
      modelArr,
      handleModelChange,
    } = this.props;
    const { showDialog, showDetails, isLoggedIn, slideData } = this.state;
    const cardsPerPage = 4;

    const pages = [];
    for (let i = 0; i < slideData.length; i += cardsPerPage) {
      const page = slideData.slice(i, i + cardsPerPage);
      pages.push(page);
    }
    return (
      <>
        <div className="dropDownChat__msgBody mb-3 px-3 h-100">
          <div className={"d-flex flex-column col "} ref={msgBodyRef}>
            {(msgArr.length === 0 && this.props.embed) ||
            (msgArr.length === 0 && this.props.botname == "nav") ? (
              <div
                ref={this.carouselRef}
                className="crousal-background custom-slick1 inactive-slick1"
                // dotPosition={"bottom"}
                // nextArrow={<p>net</p>}
                // arrows
                // prevArrow={
                //   <CustomPrevArrow
                //     onClick={this.handlePrevClick}
                //     embed={this.props.embed}
                //     botname={this.props.botname || ""}
                //   />
                // }
                // nextArrow={
                //   <CustomNextArrow
                //     onClick={this.handleNextClick}
                //     embed={this.props.embed}
                //     botname={this.props.botname || ""}
                //   />
                // }
              >
                {slideData.map((text, index) => (
                  <div key={index}>
                    <Row gutter={[0, 24]}>
                      <Col span={24}>
                        {/* <img
                          className="ms-4"
                          src="/assets/images/light_bulb.svg"
                          width={20}
                          height={20}
                          alt=""
                        /> */}
                      </Col>
                      <Col span={24} style={{ paddingBottom: "35px" }}>
                        <p className="crousal-text">{text}</p>
                      </Col>
                    </Row>
                  </div>
                ))}
              </div>
            ) : (
              <div
                style={{ paddingInline: "30px" }}
                ref={this.carouselRef}
                className=" custom-slick inactive-slick inactive-slick1 "
                // dotPosition="bottom"
                // arrows
                // prevArrow={<CustomPrevArrow onClick={this.handlePrevClick} />}
                // nextArrow={<CustomNextArrow onClick={this.handleNextClick} />}
              >
                {pages.map((page, pageIndex) => (
                  <div key={pageIndex}>
                    <Row style={{ justifyContent: "left" }} gutter={[12, 20]}>
                      {page.map((text, index) => (
                        <Col span={6} key={index}>
                          {/* <img
                        className="ms-4"
                        src="/assets/images/light_bulb.svg"
                        width={20}
                        height={20}
                        alt=""
                      /> */}
                          {/* <Card className="carosel-card">
                            <p className="crousal-text">{text}</p>
                          </Card> */}
                        </Col>
                      ))}
                    </Row>
                  </div>
                ))}
              </div>
            )}
            {/* {!this.props.embed && this.props.botname !== "nav" && (
              <></>
              // <hr style={{ opacity: 0.1 }} />
            )} */}
            {!isLoggedIn &&
            !this.props.embed &&
            this.props.botname !== "nav" ? (
              <div className="d-flex justify-content-between ">
                <div className="dropDownChat__msg rounded shadow py-2 px-3 mt-4 mb-4">
                  <pre>
                    {initialMsg ||
                      "Welcome to Neuralmind Insights!Here are some frequently asked questions you can pose to our demo database.\nYour customers will have a similar experience while trying to query their product data from your software."}
                  </pre>
                </div>

                <div style={{ height: "85px" }}>
                  <div
                    className={
                      " d-flex h-100  w-100 " +
                      (isLoggedIn
                        ? "justify-content-start"
                        : "pb-2 justify-content-center")
                    }
                  >
                    <div
                      className={
                        isLoggedIn
                          ? "dropDownChat__msg rounded shadow py-2 px-3 mb-4 align-items-center texting "
                          : "box-login"
                      }
                    >
                      {/* <div
                        className="d-flex justify-content-center "
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          onClick={this.handleLogin}
                          src="/assets/images/login-new.svg"
                        ></img>
                      </div> */}
                      {isLoggedIn ? "How can I help you?" : <></>}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="dropDownChat__msg rounded shadow py-2 px-3 mb-4"
                style={{
                  overflowX: "unset",
                }}
              >
                {/* {!isLoggedIn && (
              <div className="dropDownChat__msg rounded shadow py-2 px-3 mb-4"> */}
                <pre>
                  {initialMsg ||
                    "Welcome! I will be your AI Copilot and assist as you as you use our product. How can I help?"}
                </pre>
                {/* </div>
            )}  */}
                {/* <pre>{this.props.initialMsg}</pre> */}
              </div>
            )}

            {/*  old ui  */}
            {!this.props.embed && this.props.botname !== "nav" && (
              // this.state.isSampleConnection &&
              <div
                className="d-flex justify-content-between "
                // style={{ marginLeft: "30px" }}
              >
                <div
                  className="dropDownChat__msg1"
                  // style={{ overflowX: "unset" }}
                >
                  <pre>
                    <div
                      // bordered={false}
                      style={{
                        // marginTop: "20px",
                        overflow: "hidden",
                        minWidth: "654px",
                      }}
                    >
                      {/* Card content */}
                      {insightCards.map((item, index) => (
                        <>
                          <div
                            onClick={() => this.handleArrowClick(item.content)}
                            className="d-flex main-class"
                            style={{
                              width: "100%",
                              padding: "10px",
                              marginTop: "10px",
                              cursor: "pointer",
                            }}
                          >
                            <div
                              style={{
                                alignItems: "center",
                                display: "flex",
                              }}
                            >
                              {" "}
                              <div
                                className="d-flex list-item"
                                style={{
                                  // padding: "10px",
                                  marginLeft: "15px",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-image">
                                  {" "}
                                  <img src="/assets/images/message.svg" />{" "}
                                </div>
                                <div>
                                  <span key={index} className="card-content">
                                    {item.content}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div>
                              {" "}
                              <span
                                className="arrow-icon"
                                style={{ paddingRight: "10px" }}
                              >
                                <img src="/assets/images/new-arrow.svg" />
                              </span>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>

                    {/* Footer content */}
                    <hr className="underline" />
                    <div
                      className="d-flex dataset1"
                      style={{
                        // marginLeft: "15px",
                        padding: "10px",
                        width: "100%",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        className="d-flex dataset1"
                        style={{
                          // marginLeft: "15px",
                          width: "100%",
                          border: "none",
                        }}
                      >
                        <div
                          className="d-flex dataset-new-content"
                          onClick={() => this.handleDatasetArrowClick()}
                        >
                          <div
                            className="d-flex "
                            style={{ paddingLeft: "15px" }}
                          >
                            {" "}
                            <div className="card-image">
                              <img src="/assets/images/dataset.svg" />{" "}
                            </div>
                            <div className="dataset-new-content">
                              <div className="card-content">Dataset</div>
                            </div>
                          </div>
                          <div>
                            <div>
                              {" "}
                              <span
                                className="arrow-icon1"
                                style={{ paddingRight: "10px" }}
                              >
                                <img src="/assets/images/blue-arrow-icon.svg" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </pre>
                </div>
                <div style={{ height: "85px" }}>
                  <div
                    className={
                      " d-flex h-100  w-100 " +
                      (isLoggedIn
                        ? "justify-content-start"
                        : "pb-2 justify-content-center")
                    }
                  >
                    {/* <div
                    className={
                      isLoggedIn
                        ? "dropDownChat__msg rounded shadow py-2 px-3 mb-4 align-items-center texting "
                        : "box-login"
                    }
                  >
                    <div
                      className="d-flex justify-content-center "
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        onClick={this.handleLogin}
                        src="/assets/images/login-new.svg"
                      ></img>
                    </div>
                    {isLoggedIn ? "How can I help you?" : <></>}
                  </div> */}
                  </div>
                </div>
              </div>
            )}
            {/* 
            <div
              // style={{border:"1px solid black"}}
              className={
                " d-flex h-100 align-items-end   w-100 " +
                (isLoggedIn
                  ? "justify-content-start"
                  : "pb-2 justify-content-center")
              }
            >
              {!isLoggedIn &&
                (this.props.embed || this.props.botname == "nav") && (
                  <div className={"box-login-embed"}>
                    <div className="d-flex justify-content-center ">
                      <img
                        className="border mb-3 rounded p-2 "
                        src="/assets/images/login.svg"
                      ></img>
                    </div>
                    {isLoggedIn ? (
                      "How can I help you?"
                    ) : (
                      <>
                        {!isLoggedIn &&
                          (this.props.embed || this.props.botname == "nav") && (
                            <span className="mt-2">
                              Please login to access Insights.
                            </span>
                          )}
                      </>
                    )}
                    {!isLoggedIn &&
                      (this.props.embed || this.props.botname == "nav") && (
                        <div className="rounded d-flex justify-content-center align-center w-100">
                          <Button
                            style={{
                              borderRadius: "5px",
                              border: "unset",
                              background:
                                "linear-gradient(132deg, #4776F1 0%, #1333B6 100%)",
                              boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.15)",
                              width: "50%",
                              maxWidth: "150px",
                              color: "#fff",
                            }}
                            className="mt-3"
                            onClick={this.handleLogin}
                            disabled={this.props.embed}
                          >
                            Login
                          </Button>
                        </div>
                      )}
                  </div>
                )}
            </div> */}
            <div className="loading-card">
              <ChatContent
                isTyped={this.props.isTyped}
                setIsTyped={this.props.setIsTyped}
                embed={this.props.embed}
                loading={loading}
                msgArr={this.state.msgArr}
                msgBodyRef={msgBodyRef}
                isLoggedIn={isLoggedIn}
              />
            </div>
          </div>
        </div>
        {isLoggedIn && (
          <div
            className=" mt-2 align-center logout-button"
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderTop: "1px solid #d7dff3",
            }}
          >
            <div
              className="content-logout"
              style={{ marginLeft: "20px", paddingTop: "5px" }}
            >
              You are logged in
            </div>
            <div>
              <StyledMuiButton
                startIcon={<img src="/assets/images/logout.svg"></img>}
                variant="text"
                style={{ textTransform: "capitalize" }}
                onClick={this.handleLogout}
              >
                <span className="logout-text">Logout</span>
              </StyledMuiButton>
            </div>
          </div>
        )}
        {!this.props.embed ? (
          <ChatTextArea
            // style={{ marginTop: "10%" }}
            botname={this.props.botname || ""}
            embed={this.props.embed}
            handleChange={handleChange}
            handleDelete={handleDelete}
            handleSubmit={handleSubmit}
            query={query}
            resetInsight={(e: any) => {
              this.setState({ msgArr: [] });
              this.props.handleDelete(e);
            }}
            insights={true}
            handleModelChange={handleModelChange}
          />
        ) : (
          <div
            style={{
              opacity: 0.6,
              pointerEvents: "none",
            }}
          >
            <ChatTextArea
              botname={this.props.botname || ""}
              embed={this.props.embed}
              handleChange={handleChange}
              handleDelete={handleDelete}
              handleSubmit={handleSubmit}
              query={query}
              // showModalOption={showModalOption}
              handleModelChange={handleModelChange}
              // modelArr={modelArr}
            />
          </div>
        )}
      </>
    );
  }
}
