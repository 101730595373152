import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  useStripe,
  useElements,
  Elements,
  AddressElement,
} from "@stripe/react-stripe-js";
import { Button, Form } from "react-bootstrap";

const Subscribe = (props: any) => {
  const [clientSecret] = useState(props.clientSecret);
  const [subscriptionId] = useState(props.subscriptionId);
  const [name, setName] = useState("");
  const [messages, _setMessages] = useState("");
  const [paymentIntent, setPaymentIntent] = useState<any>();
  const [address, setAddress] = useState<any>();

  const setMessage = (message: any) => {
    _setMessages(`${messages}\n\n${message}`);
  };

  const stripe: any = useStripe();
  const elements: any = useElements();

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      console.log("stripe is undefined");
      return;
    }

    let { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://example.com/order/123/complete",
      }
    });

    if (error) {
      setMessage(error.message);
      return;
    }
    setPaymentIntent(paymentIntent);
  };

  if (paymentIntent && paymentIntent.status === "succeeded") {
    console.log("Payment success");
  }

  return (
    <form onSubmit={handleSubmit}>
      <AddressElement options={{ mode: 'billing' }} />
      <PaymentElement />
      <h5 className="my-3 text-danger">
        {messages}
      </h5>
      <div className="mt-3">
        <Button type="submit" className="btn-green">Pay {props.cost}</Button>
      </div>
    </form>
  );
};

const stripePromise = loadStripe(
  "pk_test_51MgS0KSDEFpjrVfNcvcNy2ND1Nq02K3REDDlgwuCjCPCS76duVDmerXsmEHajkkHnFAvM4mjklfFTR0LMTI9GGGq00V3gZ1Duq"
);

const Wrapper = (props: any) => {
  return (
    <Elements stripe={stripePromise} options={{ clientSecret: props.clientSecret }}>
      <Subscribe {...props} />
    </Elements>
  );
}

export default Wrapper;
