import * as React from "react";
import { styled } from "@mui/system";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const CustomPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: "unset",
  boxShadow: "unset",
  borderRadius: "unset",
  overflow: "auto",
  "&": {
    height: "100%",
    maxHeight: "500px",
  },
  "&::-webkit-scrollbar": {
    width: "5px",
    background: "#b5b5b5",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#3e6fe1",
    borderRadius: "4px",
  },
}));

interface BasicTableProps {
  msg: any;
}

class BasicTable extends React.Component<BasicTableProps> {
  render() {
    const { msg } = this.props;
    const keys = Object.keys(msg?.data[0] || {});
    return (
      <TableContainer component={CustomPaper}>
        <Table
          className="table-data"
          sx={{ minWidth: 400 }}
          size="small"
          aria-label="simple table"
        >
          <TableHead
            style={{
              position: "sticky",
              top: "0",
              backgroundColor: "white",
              zIndex: "1",
            }}
          >
            <TableRow>
              {keys.map((field: string) => (
                <TableCell align="left">{field}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {msg?.data?.map((row: any) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {keys.map((field: string) => (
                  <TableCell align="left">{row[field]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default BasicTable;
