import * as React from "react";
import { Navigate } from "react-router-dom";
import "../style/admin.scss";
interface AdminProps { }

interface AdminState {
  navigate: Boolean;
  to: string;
}

class Admin extends React.Component<AdminProps, AdminState> {
  state = { navigate: false, to: "" };

  navigateTo(to: string) {
    this.setState({ navigate: true, to });
  }

  renderCard(title: string, imagePath: string, description: string, link: string) {
    return (
      <div
        className="bg-white rounded shadow-sm p-3 customized-card "
        style={{overflow:"hidden"}}
        onClick={() => this.navigateTo(link)}
        role="button"
      >
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center gap-3">
              <div className="p-2 rounded">
                <img src={imagePath} alt="" />
              </div>
              <h4 className="fw-bold card-title">{title}</h4>
            </div>
            <img className="p-3" src="/assets/images/right-arrow-icon.svg" alt="" />
          </div>
          <div>
            <p className="mb-0 card-description">{description}</p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        {this.state.navigate && <Navigate to={this.state.to} replace={true} />}
        <div className="d-flex justify-content-around align-items-start my-5 gap-3 ">
          {this.renderCard(
            "Agents",
            "/assets/images/agent-icon.svg",
            "Manage agent addition and removal",
            "/admin/agent"
          )}

          {this.renderCard(
            "API",
            "/assets/images/apis-icon.svg",
            "Generate and manage your API Key",
            "/admin/api"
          )}

          {this.renderCard(
            "Plans and Billing",
            "/assets/images/billing-icon.svg",
            "Choose the best plan for your business",
            "/admin/bills"
          )}
        </div>
      </>
    );
  }
}

export default Admin;
