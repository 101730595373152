import axios from "../helpers/axios";

export const getArticles = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        "/api/v1/article"
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getArticleById = async (_id: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `/api/v1/article/${_id}`
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const postArticle = async (article: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        "/api/v1/article",
        article
      );
      console.log(response);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteArticle = async (article: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.delete(
        "/api/v1/article/" + article._id
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const putArticle = async (article: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.put(
        "/api/v1/article",
        article
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};
