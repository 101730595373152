import axios from "../helpers/axios";
import { getCookie } from "../helpers/cookie";

export const getSlacks = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.get(
        process.env.REACT_APP_NODE_REST_API + "api/v1/slack"
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getSlackById = async (_id: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.get(
        `/api/v1/slack/${_id}`
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const postSlack = async (slack: any) => {
  slack.owner = getCookie("company_id");
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.post(
        "/api/v1/slack",
        slack
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteSlack = async (slack: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.delete(
        "/api/v1/slack/" + slack._id
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const putSlack = async (slack: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.put(
        "/api/v1/slack",
        slack
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};
