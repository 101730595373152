import React from "react";
import useCollapse from "react-collapsed";

export default function Collapse(props: any) {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({ defaultExpanded: true });

  return (
    <div>
      <div>{props.expand(getToggleProps)}</div>
      <section {...getCollapseProps()}>{props.content}</section>
    </div>
  );
}
