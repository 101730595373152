import * as React from "react";
import { Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import sha256 from "crypto-js/sha256";
import Select from "react-select";

import toast from "../../helpers/toast";
import Collapse from "../../helpers/collapse";
import { Article } from "../../interfaces/article";
import { getArticles, postArticle, putArticle } from "../../apis/article";
import { ArrowDownShort, ChevronDown } from "react-bootstrap-icons";
import { getCookie } from "../../helpers/cookie";
import { typeOption } from "../../helpers/modalType";
import "../../style/folder-tree.scss";
import { Link } from "react-router-dom";
import { Avatar, Box, Chip, Divider, Menu, MenuItem } from "@mui/material";
import styled from "@emotion/styled";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { Select as MuiSelect } from "@mui/material";

// Styled Chip component with conditional styling
const CustomChip = styled(Chip)(({ isActive }: { isActive: boolean }) => ({
  // Primary blue border
  cursor: "pointer",
  border: isActive ? "1px solid #3E6FE1" : "",
  color: isActive ? "#3E6FE1" : "",
  backgroundColor: isActive ? "#E4F2FF" : "",
}));

interface FolderTreeProps {
  navigate: any;
  activeModel: Function;
  currTab: number;
}

interface FolderTreeState {
  dump: Article[];
  activeModel: string;
  newModelName: string;
  newModelType:
    | {
        value: string;
        label: string;
      }
    | undefined;
  createNewModelLoading: boolean;
  navigateToFile: string;
  newModal: boolean;
  showOptions: boolean;
  anchorEl: null | HTMLElement;
  isFileOpened: boolean;
}

class FolderTree extends React.Component<FolderTreeProps, FolderTreeState> {
  state: FolderTreeState = {
    dump: [],
    activeModel: "",
    newModelName: "",
    newModelType: {
      value: "Please select the Industry",
      label: "Please select the Industry",
    },
    createNewModelLoading: false,
    navigateToFile: "render-01",
    newModal: false,
    showOptions: false,
    anchorEl: null,
    isFileOpened: true,
  };

  constructor(props: FolderTreeProps) {
    super(props);
    this.handleCloseNewModal = this.handleCloseNewModal.bind(this);
    this.handleShowNewModal = this.handleShowNewModal.bind(this);
    this.onCreateNewModel = this.onCreateNewModel.bind(this);
  }

  handleCloseNewModal() {
    this.setState({ ...this.state, newModal: false });
  }

  handleShowNewModal() {
    this.setState({ ...this.state, newModal: true });
  }

  async componentDidMount() {
    await this.getData();
  }

  async getData() {
    try {
      let response: any = await getArticles();
      response = await getArticles();
      this.setState({
        dump: response.articles,
        activeModel: response.articles[0].model_name,
      });
      this.props.activeModel(response.articles[0]._id);
    } catch (error) {
      console.log(error);
      // Handled in nodebackend 
      // await postArticle({
      //   model_name: `General ${getCookie("company_name")}`,
      //   industry: "general",
      //   categorys: [
      //     {
      //       category_name: "Untitled",
      //       folders: [
      //         {
      //           folder_name: "Untitled",
      //           files: [{ file_name: "Start here." }],
      //         },
      //       ],
      //     },
      //   ],
      // });
      // this.getData();
    }
  }

  async saveChanges(valueX: any, index: number) {
    const temp: any = [...this.state.dump];
    temp[index] = valueX;
    this.setState({ dump: temp });
    await this.updateDB();
  }

  async newFile(valueX: any, index: number) {
    const temp: any = [...this.state.dump];
    temp[index] = valueX;

    try {
      const articles = [...this.state.dump];
      for (let index = 0; index < articles.length; index++) {
        const article = articles[index];
        const response = await putArticle(article);
      }
    } catch (error) {
      console.log(error);
    }
    try {
      let response: any = await getArticles();

      this.setState({
        dump: response.articles,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async updateDB() {
    try {
      const articles = [...this.state.dump];
      for (let index = 0; index < articles.length; index++) {
        const article = articles[index];
        const response = await putArticle(article);
      }
    } catch (error) {
      console.log(error);
    }
  }

  onChange(event: any) {
    this.setState({ activeModel: event.target.value });
    let selectedModel = this.state.dump.find(
      (model) => model.model_name === event.target.value
    );
    this.props.activeModel(selectedModel?._id);
  }

  onChangeInputFieldNewModel(event: any) {
    this.setState({ newModelName: event.target.value });
  }

  // Function to handle the filter image click
  handleFilterClick = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({
      showOptions: true,
      anchorEl: event.currentTarget,
    });
  };

  // Function to handle the select item click
  handleMenuItemClick = (value: string) => () => {
    this.setState({
      showOptions: false,
      anchorEl: null,
    });
    // Do something with the selected value
  };

  // Function to handle the menu close
  handleCloseMenu = () => {
    this.setState({
      showOptions: false,
      anchorEl: null,
    });
  };

  async onCreateNewModel(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!this.state.newModelName) {
      toast.error("Please name AI model name to proceed.");
      return;
    } else if (
      !this.state.newModelType ||
      this.state.newModelType.value == "Please select the Industry"
    ) {
      toast.error("Please select an Industry.");
      return;
    }

    try {
      this.setState({ createNewModelLoading: true });
      const response: any = await postArticle({
        model_name: this.state.newModelName,
        industry: this.state.newModelType.value,
        categorys: [
          {
            category_name: "Untitled",
            folders: [
              {
                folder_name: "Untitled",
                files: [{ file_name: "Start here." }],
              },
            ],
          },
        ],
      });

      const temp: any = this.state.dump;
      temp[this.state.dump.length] = response.result;
      this.setState({ dump: temp });
      this.render();
      toast.success(`Successfully created ${this.state.newModelName} model!`);
      this.setState({ createNewModelLoading: false, newModelName: "" });
      this.handleCloseNewModal();
    } catch (error) {
      console.log(error);
      toast.error(`Please try another model name!`);
      this.setState({ createNewModelLoading: false, newModelName: "" });
    }
  }
  render() {
    return (
      <div className="folderTree bg-white p-2">
        <div className="d-flex justify-content-between align-items-center mb-3 mt-2">
          <p className="m-0 fw-bolder fs-5">Model Name</p>
          <img
            className="list-item-add-model"
            src="/assets/images/add-icon.svg"
            role="button"
            width="35"
            height="35"
            onClick={this.handleShowNewModal}
          />
        </div>
        <div>
          {/* <Form.Select
            aria-label="Select Model"
            onChange={(event) => this.onChange(event)}
          >
            {this.state.dump.map((value: Article) => (
              <option value={value.model_name}>{value.model_name}</option>
            ))}
          </Form.Select> */}

          <FormControl style={{ margin: 1 }} fullWidth size="small">
            <MuiSelect
              IconComponent={ChevronDown}
              value={this.state.activeModel}
              onChange={(event) => this.onChange(event)}
            >
              {this.state.dump.map((value: Article) => (
                <MenuItem value={value.model_name}>{value.model_name}</MenuItem>
              ))}
            </MuiSelect>
          </FormControl>

          <hr style={{ opacity: 0.1 }} />

          {/* <FormControl variant="outlined" size="small" className="w-100">
            <OutlinedInput
              id="filled-adornment-password"
              type={"text"}
              style={{ borderRadius: 5 }}
              placeholder="Search (Coming Soon)"
              startAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="start"
                  >
                    <img src={"/assets/images/search-icon.svg"} />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl> */}
        </div>

        <div className="d-flex my-3 justify-content-between rounded">
          <div>
            <Link to="/" style={{ marginRight: 5 }}>
              <CustomChip
                className="list-item-text"
                label="Text"
                isActive={this.props.currTab === 0}
                onClick={() => this.setState({ isFileOpened: true })}
                avatar={
                  <Avatar
                    alt="Natacha"
                    src={`/assets/images/a-${
                      this.props.currTab === 0 ? "blue-" : ""
                    }icon.svg`}
                    style={{ padding: 5, width: 20, height: 20 }}
                  />
                }
                variant={this.props.currTab === 0 ? "filled" : "outlined"}
              />
            </Link>
            {/* <Link to="/link" >
              <CustomChip
              className="list-item-link"
                label="Link"
                isActive={this.props.currTab === 1}
                avatar={
                  <Avatar
                    alt="Natacha"
                    src={`/assets/images/link-${
                      this.props.currTab === 1 ? "blue-" : ""
                    }icon.svg`}
                    style={{ padding: 5, width: 20, height: 20 }}
                  />
                }
                variant={"outlined"}
              />
            </Link> */}
          </div>
          <div>
            {/* <div
              className="border rounded"
              style={{ cursor: "pointer" }}
              onClick={this.handleFilterClick}
            >
              <img src="/assets/images/filter-icon.svg" alt="Filter Icon" />
            </div> */}
            <Menu
              anchorEl={this.state.anchorEl}
              open={this.state.showOptions}
              onClose={this.handleCloseMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={this.handleMenuItemClick("all")}>
                Show All
              </MenuItem>
              <MenuItem
                color="success"
                onClick={this.handleMenuItemClick("synced")}
              >
                Synced
              </MenuItem>
              <MenuItem onClick={this.handleMenuItemClick("notSynced")}>
                Not Synced
              </MenuItem>
            </Menu>
          </div>
        </div>

        <div className="folderTree__scroll pe-2">
          {this.props.currTab === 0 &&
            this.state.dump.map((valueX: Article, indexX) => {
              if (valueX.model_name != this.state.activeModel) return;
              return (
                <div className="position-relative">
                  {valueX.categorys.map((value, index) => (
                    <div>
                      <Collapse
                        expand={(getToggleProps: any) => (
                          <p className="d-flex align-items-center">
                            <span
                              className="d-flex align-items-center"
                              {...getToggleProps()}
                            >
                              <span>
                                <img
                                  src="/assets/images/down-icon.svg"
                                  style={{
                                    transform: getToggleProps()["aria-expanded"]
                                      ? "rotate(0deg)"
                                      : "rotate(180deg)",
                                    transition: "transform 0.2s",
                                  }}
                                />
                              </span>
                            </span>
                            <Box ml={1}>
                              <img
                                className="folderTree__folderIcon"
                                src="/assets/images/folder-icon.svg"
                                alt=""
                                width={18}
                                height={18}
                              />
                            </Box>

                            <input
                              className="folderTree__editTextBox"
                              type="text"
                              value={value.category_name}
                              onChange={(event) => {
                                value.category_name = event.target.value;
                                this.saveChanges(valueX, indexX);
                              }}
                            />
                            {/* {index == 0 && ( */}
                            {
                              <>
                                <img
                                  src="/assets/images/plus.svg"
                                  className="folderTree__addIcon"
                                  alt=""
                                  onClick={() => {
                                    valueX.categorys.push({
                                      category_name: "Untitled",
                                      folders: [
                                        {
                                          folder_name: "Untitled",
                                          files: [{ file_name: "Start here." }],
                                        },
                                      ],
                                    });

                                    this.newFile(valueX, indexX);
                                  }}
                                />
                              </>
                            }
                          </p>
                        )}
                        content={
                          <div className="position-relative ms-3">
                            {value.folders.map((valueFolder, index) => (
                              <Collapse
                                expand={(getToggleProps: any) => (
                                  <p className="d-flex align-items-center">
                                    <span
                                      className="d-flex align-items-center"
                                      {...getToggleProps()}
                                    >
                                      <span></span>
                                      <img
                                        src="/assets/images/down-icon.svg"
                                        style={{
                                          transform: getToggleProps()[
                                            "aria-expanded"
                                          ]
                                            ? "rotate(0deg)"
                                            : "rotate(180deg)",
                                          transition: "transform 0.2s",
                                        }}
                                      />
                                    </span>
                                    <Box ml={1}>
                                      <img
                                        className="folderTree__folderIcon"
                                        src="/assets/images/subfolder-icon.svg"
                                        alt=""
                                        width={15}
                                        height={15}
                                      />
                                    </Box>

                                    <input
                                      className="folderTree__editTextBox"
                                      type="text"
                                      value={valueFolder.folder_name}
                                      onChange={(event) => {
                                        valueFolder.folder_name =
                                          event.target.value;

                                        this.saveChanges(valueX, indexX);
                                      }}
                                    />
                                    {/* WARNING ICON */}
                                    {/* <img
                                      src="/assets/images/warning-icon.svg"
                                      className="folderTree__addIcon"
                                      alt=""
                                      width={20}
                                      height={20}
                                    /> */}
                                    {
                                      <img
                                        src="/assets/images/plus.svg"
                                        className="folderTree__addIcon"
                                        alt=""
                                        style={{
                                          visibility:
                                            index === 0 ? "visible" : "hidden",
                                        }}
                                        onClick={() => {
                                          value.folders.push({
                                            folder_name: "Untitled",
                                            files: [
                                              { file_name: "Start here." },
                                            ],
                                          });
                                          this.newFile(valueX, indexX);
                                        }}
                                      />
                                    }
                                  </p>
                                )}
                                content={
                                  <div className="ms-5">
                                    {valueFolder.files.map(
                                      (valueFile, index) => (
                                        <>
                                          {this.state.isFileOpened &&
                                            index === 0 &&
                                            this.props.currTab === 0 && (
                                              <>
                                                {this.props.navigate(
                                                  `/${sha256(
                                                    `${valueFile._id}`
                                                  ).toString()}.json?model=${
                                                    valueX._id
                                                  }`,
                                                  this.setState({
                                                    isFileOpened: false,
                                                  })
                                                )}
                                              </>
                                            )}
                                          <p className="d-flex align-items-center">
                                            {" "}
                                            <img
                                              className="folderTree__fileIcon"
                                              src="/assets/images/file-icon.svg"
                                              alt=""
                                              width={20}
                                              height={20}
                                              onClick={() => {
                                                this.props.navigate(
                                                  `/${sha256(
                                                    `${valueFile._id}`
                                                  ).toString()}.json?model=${
                                                    valueX._id
                                                  }`
                                                );
                                              }}
                                            />
                                            <input
                                              className="folderTree__editTextBox"
                                              type="text"
                                              value={valueFile.file_name}
                                              onChange={(event) => {
                                                valueFile.file_name =
                                                  event.target.value;
                                                this.saveChanges(
                                                  valueX,
                                                  indexX
                                                );
                                              }}
                                            />
                                            {/* WARNING ICON */}
                                            {/* <img
                                            src="/assets/images/warning-icon.svg"
                                            className="folderTree__addIcon"
                                            alt=""
                                            width={20}
                                            height={20}
                                          /> */}
                                            {
                                              <img
                                                src="/assets/images/plus.svg"
                                                className="folderTree__addIcon"
                                                style={{
                                                  visibility:
                                                    index === 0
                                                      ? "visible"
                                                      : "hidden",
                                                }}
                                                alt=""
                                                onClick={() => {
                                                  valueFolder.files.push({
                                                    file_name: "Start here.",
                                                  });
                                                  this.newFile(valueX, indexX);
                                                }}
                                              />
                                            }
                                          </p>
                                        </>
                                      )
                                    )}
                                  </div>
                                }
                              ></Collapse>
                            ))}
                          </div>
                        }
                      ></Collapse>
                    </div>
                  ))}
                </div>
              );
            })}
        </div>
        <Modal show={this.state.newModal} onHide={this.handleCloseNewModal}>
          <Modal.Header closeButton className="border-0" />
          <form onSubmit={this.onCreateNewModel}>
            <Modal.Body className="pt-0">
              <h3 className="fw-bold mb-4 model-heading">
                Create a new AI model
              </h3>
              <Form.Group className="mb-3">
                <label className="form-label  form-font-black">
                  AI Model Name
                </label>
                <Form.Control
                  type="text"
                  required
                  placeholder="AI model name"
                  value={this.state.newModelName}
                  onChange={(event) => this.onChangeInputFieldNewModel(event)}
                />
              </Form.Group>
              <label className="form-label  form-font-black">Industry</label>

              <Select
                placeholder="Select One"
                required
                isClearable={true}
                isSearchable={true}
                // value={this.state.newModelType}
                onChange={(e: any) =>
                  this.setState({ ...this.state, newModelType: e })
                }
                //@ts-ignore
                options={typeOption}
              />
            </Modal.Body>
            <hr />
            <Modal.Footer className="d-flex justify-content-end border-0">
              <Button
                className="btn-outline btn-container"
                variant="primary"
                onClick={this.handleCloseNewModal}
              >
                <img src="/assets/images/blue-cross-icon.svg" />
                Cancel
              </Button>
              <Button
                className="btn-blue btn-container"
                type="submit"
                disabled={this.state.createNewModelLoading}
              >
                <img src="/assets/images/white-tick-icon.svg" />
                Create
                {this.state.createNewModelLoading && (
                  <div className="spinner-border text-light ms-2" role="status">
                    &nbsp; <span className="sr-only"></span>
                  </div>
                )}
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    );
  }
}

export default FolderTree;
