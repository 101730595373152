import sha256 from "crypto-js/sha256";

export const foundArticleFromTree = async (trees: any[], search: string) => {
  let location;

  for (let i = 0; i < trees.length; i++) {
    const tree = trees[i];
    // Loop through all modles^
    for (let j = 0; j < tree.categorys.length; j++) {
      const category = tree.categorys[j];
      // Loop through all categorys^
      for (let x = 0; x < category.folders.length; x++) {
        const folder = category.folders[x];
        // Loop through all folders^
        for (let y = 0; y < folder.files.length; y++) {
          const file = folder.files[y];
          // Loop through all files^
          if (`${sha256(`${file._id}`).toString()}.json` === search)
            return [i, j, x, y];
        }
      }
    }
  }

  return [];
};

export const indexOfArticleSearch = (trees: any, articleName: string) => {
  for (let i = 0; i < trees.length; i++) {
    const tree = trees[i];
    // Loop through all modles^
    for (let j = 0; j < tree.categorys.length; j++) {
      const category = tree.categorys[j];
      // Loop through all categorys^
      for (let x = 0; x < category.folders.length; x++) {
        const folder = category.folders[x];
        // Loop through all folders^
        for (let y = 0; y < folder.files.length; y++) {
          const file = folder.files[y];
          // Loop through all files^
          if (file.file_name === articleName) {
            console.log(file);
            return [i, j, x, y];
          }
        }
      }
    }
  }
};

export const prepareCSVToEditor = (
  model: any,
  categoryName: string,
  folderName: string,
  title: string
) => {
  let locationOfCategory = -1;
  let locationOfFolder = -1;
  let foundCategory = 0;
  let foundFolder = 0;
  for (let j = 0; j < model.categorys.length; j++) {
    const category = model.categorys[j];
    // Loop through all categorys^

    if (category.category_name === categoryName) {
      foundCategory++;

      if (locationOfCategory === -1) locationOfCategory = j;
    }

    for (let x = 0; x < category.folders.length; x++) {
      const folder = category.folders[x];
      if (folder.folder_name === folderName) {
        foundFolder++;
        if (locationOfFolder === -1) locationOfFolder = x;
      }
    }
  }

  // Two conditions If the category is not there create it other wise add to the exsiting
  // If the folder is not there create it other wise add to the exsiting folder

  if (foundCategory > 0) {
    // Means the category is exist
    if (foundFolder > 0) {
      // Means the folder exist
      // So let's add to the exsiting location easily

      model.categorys[locationOfCategory].folders[locationOfFolder].files.push({
        file_name: title,
      });
    }

    if (foundFolder === 0) {
      // Means the category exist but the folder not
      model.categorys[locationOfCategory].folders.push({
        folder_name: folderName,
        files: [{ file_name: title }],
      });
    }
  }

  if (foundCategory === 0) {
    // Means we need to create the category
    model.categorys.push({
      category_name: categoryName,
      folders: [
        {
          folder_name: folderName,
          files: [{ file_name: title }],
        },
      ],
    });
  }

  return model;
};
