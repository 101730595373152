import React from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Button } from "@mui/material";

interface DataType {
  key: React.Key;
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  gender: string;
  ip_address: string;
  phone_number: string;
  age: number;
  revenue_in_USD: number;
  date: string;
  country: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    width: 50,
  },
  {
    title: "First Name",
    dataIndex: "first_name",
    key: "first_name",
  },
  {
    title: "Last Name",
    dataIndex: "last_name",
    key: "last_name",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Gender",
    dataIndex: "gender",
    key: "gender",
  },
  {
    title: "IP Address",
    dataIndex: "ip_address",
    key: "ip_address",
  },
  {
    title: "Phone Number",
    dataIndex: "phone_number",
    key: "phone_number",
  },
  {
    title: "Age",
    dataIndex: "age",
    key: "age",
    sorter: true,
  },
  {
    title: "Revenue (USD)",
    dataIndex: "revenue_in_USD",
    key: "revenue_in_USD",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Country",
    dataIndex: "country",
    key: "country",
  },
];

const data: DataType[] = [
  {
    key: "1",
    id: 1,
    first_name: "Tanitansy",
    last_name: "Toke",
    email: "ttoke0@sakura.ne.jp",
    gender: "Female",
    ip_address: "131.44.194.134",
    phone_number: "918-644-0180",
    age: 23,
    revenue_in_USD: 341859,
    date: "2022-01-01",
    country: "Russia",
  },
  {
    key: "2",
    id: 2,
    first_name: "Maureene",
    last_name: "Guerrin",
    email: "mguerrin1@slate.com",
    gender: "Female",
    ip_address: "37.187.71.247",
    phone_number: "790-764-7339",
    age: 32,
    revenue_in_USD: 273646,
    date: "2021-10-07",
    country: "India",
  },
  {
    key: "3",
    id: 3,
    first_name: "Gertrudis",
    last_name: "Dormon",
    email: "gdormon2@cnbc.com",
    gender: "Female",
    ip_address: "27.13.186.78",
    phone_number: "871-579-7021",
    age: 32,
    revenue_in_USD: 436906,
    date: "2021-12-01",
    country: "Greece",
  },
  {
    key: "4",
    id: 4,
    first_name: "Lonnie",
    last_name: "McOrkill",
    email: "lmcorkill3@google.de",
    gender: "Male",
    ip_address: "122.138.212.217",
    phone_number: "909-755-7332",
    age: 52,
    revenue_in_USD: 409927,
    date: "2021-07-25",
    country: "Poland",
  },
  {
    key: "5",
    id: 5,
    first_name: "Kassi",
    last_name: "Coghlan",
    email: "kcoghlan4@apple.com",
    gender: "Female",
    ip_address: "190.163.126.106",
    phone_number: "256-624-5854",
    age: 52,
    revenue_in_USD: 496814,
    date: "2022-01-02",
    country: "USA",
  },
  {
    key: "6",
    id: 6,
    first_name: "Aldous",
    last_name: "Gerry",
    email: "agerry5@tinyurl.com",
    gender: "Male",
    ip_address: "172.139.228.35",
    phone_number: "939-206-3915",
    age: 21,
    revenue_in_USD: 133141,
    date: "2022-08-23",
    country: "Nepal",
  },
];

const DatasetTable: React.FC = () => (
  <>
    <div className="w-100 d-flex justify-content-end mb-3 ">
      <Button
        href="assets/connectdb_CSV_schema/mock_data.csv"
        download
        style={{
          marginTop: "16px",
          color: "white",
          paddingInline: "15px",
        }}
        className="btn-blue"
      >
        Download CSV
      </Button>
    </div>
    <Table columns={columns} dataSource={data} scroll={{ x: 1300 }} />
  </>
);

export default DatasetTable;
