import axios from "../helpers/axios";
import { getCookie } from "../helpers/cookie";

export const getAgents = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.get(
        "/api/v1/agent"
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getAgentById = async (_id: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.get(
        `/api/v1/agent/${_id}`
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const postAgent = async (agent: any) => {
  agent.owner = getCookie("company_id");
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.post(
        "/api/v1/agent",
        agent
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteAgent = async (agent: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.delete(
        "/api/v1/agent/" + agent._id
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const putAgent = async (agent: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.put(
        "/api/v1/agent",
        agent
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};
