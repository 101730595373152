import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import DBDataset, { DatasetTableDetails } from "../../interfaces/db-dataset";
import { DragIndicator } from "@mui/icons-material";
import "../../style/dbDataset.scss";
import { ReactElement } from "react-markdown/lib/react-markdown";
import DatasetTable from "./DatasetTable";

interface ModalProps {
  isOpen: boolean;
  data: DBDataset;
  handleOnClose: () => void;
}

interface ModalState {}

interface ContentProps {
  handleOnClose: () => void;
  data: DBDataset;
}

interface ContentState {
  selectedTable?: DatasetTableDetails;
}

interface ListItemProps {
  key: number;
  primary: string;
  active?: boolean;
  icon: string | ReactElement;
}

export default class DatasetModal extends React.Component<
  ModalProps,
  ModalState
> {
  constructor(props: ModalProps) {
    super(props);
    this.state = {};
  }
  componentDidMount(): void {
    console.log("Modal");
  }
  render() {
    return (
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={this.props.isOpen}
        onClose={this.props.handleOnClose}
      >
        <DialogContent>
          <DatasetContent
            data={this.props.data}
            handleOnClose={this.props.handleOnClose}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

class DatasetContent extends React.Component<ContentProps, ContentState> {
  constructor(props: ContentProps) {
    super(props);
    this.state = {};
  }
  componentDidMount(): void {
    this.setState({ selectedTable: this.props?.data?.tableDetails?.[0] });
  }
  handleListItemClick(itemIndex: number): void {
    this.setState({
      selectedTable: this.props?.data?.tableDetails?.[itemIndex],
    });
  }
  render() {
    return (
      <Grid container columnSpacing={4} className="dataset-content-container">
        {/* LEFT SIDE CONTENT */}
        <Grid
          item
          sm={3}
          xs={12}
          className="dataset-content-table-container"
          style={{ paddingLeft: 0 }}
        >
          <Grid container rowSpacing={1}>
            <Grid item sm={12} xs={12} className="dataset-content-heading">
              <p className="fw-bold">Tables</p>
              <hr className="underline" />
            </Grid>
            <Grid item sm={12} xs={12} className="dataset-content-table-list">
              {this.props?.data?.tableDetails?.map(
                ({ tableName }: { tableName: string }, index) => (
                  <this.CustomListItem
                    key={index}
                    primary={tableName}
                    active={this.state.selectedTable?.tableName === tableName}
                    icon={<DragIndicator />}
                  />
                )
              )}
            </Grid>
          </Grid>
        </Grid>
        {/* RIGHT SIDE CONTENT */}
        <Grid
          item
          sm={9}
          xs={12}
          className="no-scrollbar"
          style={{ overflow: "auto", height: "80vh" }}
        >
          <Grid container rowSpacing={2}>
            <Grid item sm={12} xs={12}>
              <p className="fw-bold">{this.state?.selectedTable?.tableName}</p>

              <IconButton
                aria-label="close"
                onClick={this.props.handleOnClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item sm={12} xs={12} className="column-metadata-container">
              <div className="dataset-table-description-container">
                <div className="my-3">
                  <b>Description</b>
                </div>
                <input
                  className="form-control"
                  value={this.state?.selectedTable?.description}
                  disabled
                />
              </div>
              <Divider
                className="fw-bold"
                style={{ borderColor: "rgba(0, 0, 0, 0.25)", margin: "16px 0" }}
              >
                DataSet
              </Divider>
              <DatasetTable />
              <Divider
                className="fw-bold"
                style={{ borderColor: "rgba(0, 0, 0, 0.25)", margin: "16px 0" }}
              >
                Column Metadata
              </Divider>
              <div className="dataset-column-container">
                <ul
                  className="dataset-column-list"
                  style={{
                    padding: 0,
                    paddingRight: "24px",
                    listStyleType: "none",
                    textAlign: "left",
                  }}
                >
                  {this.state?.selectedTable?.column?.map(
                    (column: any, index) => (
                      <li key={column?._id} className="dataset-column-name">
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "0.9rem",
                            opacity: 1,
                            ml: 2,
                          }}
                        >
                          {column?.name}
                        </Typography>
                        <input
                          style={{ marginLeft: 15 }}
                          className="dataset-text-box form-control"
                          value={column?.description}
                          disabled
                        />
                      </li>
                    )
                  )}
                </ul>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  CustomListItem = ({ key, primary, active, icon }: ListItemProps) => {
    const itemclass = `list-item-${primary.toLowerCase()}`;
    return (
      <div
        className="list-container"
        style={{ width: "100%", paddingInlineEnd: "8px", marginBottom: "8px" }}
      >
        <button
          disabled={true}
          onClick={() => this.handleListItemClick(key)}
          style={{
            width: "100%",
            border: active ? "1px solid #CED8EF" : "",
          }}
          className={`${
            active ? "sidebar__active" : ""
          } fw-normal text-black  d-block text-decoration-none ml-2 rounded ${itemclass}`}
        >
          <ListItemButton
            sx={{
              borderRadius: 1,
            }}
          >
            {icon}
            <ListItemText
              primary={
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    fontSize: "0.9rem",
                    opacity: 1,
                    ml: 2,
                  }}
                >
                  {primary}
                </Typography>
              }
            />
          </ListItemButton>
        </button>
      </div>
    );
  };
}
