import React, { Component } from "react";
import Nango from "@nangohq/frontend";
import { Button } from "react-bootstrap";
import { postIntegration } from "../../apis/integration";
import { toastHelper } from "../../helpers/toast";
import { toast } from "react-toastify";

export default class Integrations extends Component {
  state = { loading: false };
  integrateNotion = async () => {
    try {
      this.setState({ loading: true });
      const connectionId = Math.round(Math.random() * 10000000000);
      var nango = new Nango({
        publicKey: "f598ba0f-25bc-46dd-b70c-1c0a10f6fbbb",
      });

      nango
        .auth("notion", `${connectionId}`)
        .then(async (result) => {
          await postIntegration({
            provider: "notion",
            connectionId: result.connectionId,
          });
          // Create WebSocket connection

          const ws = new WebSocket(`${process.env.REACT_APP_CRON}`);
          ws.onopen = () => {
            console.log("WebSocket connected");
            ws.send(
              JSON.stringify({
                type: "sync",
                connection_id: connectionId,
                provider: "notion",
              })
            );
          };
          const id: any = toastHelper.loading("Syncing ...");

          ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.sync !== undefined && data.total !== undefined) {
              const progress = (data.sync / data.total) * 100;
              // console.log(`Sync progress: ${progress}%`);

              // Update the toast message with the progress value
              toast.update(id, {
                render: `Sync progress: ${progress.toFixed(1)}%`,
                type: toast.TYPE.INFO,
              });
            } else if (data.message || data.error) {
              // Handle the final response or errors
              if (data.message) {
                toastHelper.success("Sync Done!", id);
              }

              if (data.error) {
                toastHelper.success("Error: Integration!", id);
              }
              //sconsole.log(data.message || data.error);
            }
          };

          ws.onclose = () => {
            console.log("WebSocket disconnected");
            // Clean up the WebSocket connection
            ws.close();
          };

          this.setState({ loading: false });
          // toastHelper.success(
          //   "Sync Done!",
          //   id
          //   // "Integration successful, your notion data will be available in a new model within 10 minutes."
          // );
        })
        .catch((error) => {
          console.error(
            `There was an error in the OAuth flow for integration: ${error.message}`
          );
        });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <div className="bg-white p-3 h-100">
        <div className="d-flex align-items-center">
          <div className="rounded shadow">
            <img
              width="150"
              height="100"
              src="/assets/images/notion-logo.png"
              alt=""
            />
          </div>
          <div className="ms-5">
            <h5 className="fw-bold">
              Bring in your product docs, KB from notion.
            </h5>
            <Button className="btn-green" onClick={this.integrateNotion}>
              Connect
            </Button>
          </div>
        </div>
        <div className="mt-5">
          <h4>More products soon! (Confluence, Zendesk, Intercom and more)</h4>
        </div>
      </div>
    );
  }
}
