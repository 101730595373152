import axios from "axios";
import { getCookie } from "../cookie";
axios.defaults.headers["Access-Control-Allow-Origin"] = "*";

axios.defaults.headers["Accept"] = "*/*";
axios.defaults.headers["Accept-Language"] = "en";
axios.defaults.headers["Content-Language"] = "en-US";

const token = getCookie("token");
const company_id = getCookie("company_id");
const current_user = getCookie("current_user");
const uniqueIdentifier = localStorage.getItem("uniqueIdentifier");

axios.defaults.headers.authorization = `Bearer ${token}`;
axios.defaults.headers.uniqueIdentifier = uniqueIdentifier;
axios.defaults.headers["x-companyid"] = company_id;
axios.defaults.headers["x-appengine-company-user"] = current_user;

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_NODE_REST_API}api/v1`,
});

instance.interceptors.request.use(
  (config: any) => {
    if(config?.headers)
    config.headers.uniqueIdentifier = `${localStorage.getItem("uniqueIdentifier")}`;
    return config;
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // if (error.response && error.response.status === 401) {
    //   document.cookie =
    //     "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie =
    //     "company_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie =
    //     "current_user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie =
    //     "company_name=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie =
    //     "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   localStorage.clear();

    //   // Redirect to the login page using window.location or similar method
    //   window.location.href = "https://api.neuralmind.io/login"; // or any other method you prefer
    // }
    return Promise.reject(error);
  }
);

export default instance;
