import axios from "../helpers/axios";
import { getCookie } from "../helpers/cookie";

export const getEmbeds = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.get(
        "/api/v1/embedding"
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getEmbedById = async (_id: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.get(
        `/api/v1/embedding/${_id}`
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const postEmbed = async (embedding: any) => {
  embedding.owner = getCookie("company_id");
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.post(
        "/api/v1/embedding",
        embedding
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteEmbed = async (embedding: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.delete(
        "/api/v1/embedding/" +
        embedding._id
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const putEmbed = async (embedding: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.put(
        "/api/v1/embedding",
        embedding
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const postEmbedQuery = async (body: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.post(
        "https://neuralmind-db-dot-rich-aspect-374708.uc.r.appspot.com/api/v1/query",
        body
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const uploadEmbedIcon = async (file: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      let formData = new FormData();
      formData.append("file", file);
      const response: any = await axios.post(
        "/api/v1/upload-file",
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};
