import { Component } from "react";
import { Table } from "react-bootstrap";
import { getBills } from "../../apis/bill";
import { toastHelper } from '../../helpers/toast';

interface UsageProps {
  year: number,
}
interface UsageState {
  usages: any[]
}

export default class Usage extends Component<UsageProps, UsageState> {
  constructor(props: UsageProps) {
    super(props);
    this.state = { usages: [] };
  }

  async getUsageCount(year: number){
    try {
      const usages: any = await getBills(year);
      if(!Object.entries(usages.total).length){
        toastHelper.success("Service has not been used in the year "+this.props.year);
        this.setState({usages: []});
        return;
      }

      this.setState({ usages: Object.entries(usages.total) });
    } catch (error) {
      console.log(error);
    }
  }
  async componentDidMount() {
    this.getUsageCount(this.props.year);
  }
  
  render() {
    return (
      <Table striped className="mb-0 border">
        <thead>
          <tr>
            <th className="fw-bold fs-6 p-3">Month</th>
            <th className="fw-bold fs-6 text-end p-3">Words</th>
          </tr>
        </thead>
        <tbody>
          {this.state.usages.map((val: any, i: number) => {
            return (
              <tr key={i}>
                <td className="p-3">{val[0]}</td>
                <td className="text-end p-3">
                  <span className="bg-white fw-bold border shadow py-1 px-2 rounded small">
                    {val[1].totalWords}
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }
}
