import React, { useState, useEffect } from "react";
import { Button, Modal } from "antd";
import "../../style/preview-full-embed.scss";

interface EmbedModalProps {
  isModalOpen: boolean;
  openModal: () => void;
  handleCancel: () => void; // Added handleCancel prop
}

const EmbedModal: React.FC<EmbedModalProps> = ({
  isModalOpen,
  openModal,
  handleCancel, // Added handleCancel prop
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(isModalOpen);
  }, [isModalOpen]);

  const handleOk = () => {
    setIsVisible(false);
  };

  // Call handleCancel when the modal is canceled (closed)
  const onCancel = () => {
    setIsVisible(false);
    handleCancel(); // Call the handleCancel prop
  };

  return (
    <>
      <div className="view-full-size" onClick={openModal}>
        View full size
      </div>

      <Modal
        centered
        width={"fit-content"}
        visible={isVisible}
        onOk={handleOk}
        onCancel={onCancel} // Use onCancel function
        footer={null}
      >
        <div>
          <img
            src="/assets/images/fullEmbedScreen.svg"
            style={{ height: "80vh", objectFit: "contain", marginTop: "20px" }}
          />
        </div>
      </Modal>
    </>
  );
};

export default EmbedModal;
