import axios from "../helpers/axios";

export const getEditorJsDataById = async (id: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `/api/v1/editor/${id}`
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const checkSync = async (id: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `/api/v1/editor/checksync/${id}`
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const postEditor = async (editor: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        "/api/v1/editor",
        editor
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const syncEditor = async (_id: string, model: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        "/api/v1/editor/train/" +
          _id +
          "/" +
          model.replace(/\s+/g, "-").toLowerCase()
      );
      if (!response) return reject(new Error("414"));
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const unSyncEditor = async (_id: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        "/api/v1/editor/un-train/" + _id
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};
