import React, { Component } from "react";

// import DropDownChat from "../components/DropDownChat/DropDownChat";

import "../style/chat.scss";
import DropDownChatNew from "../components/DropDownChat/DropDownChatNew";

export default class Chat extends Component {
  render() {
    return (
      <>
        {/* <div className="chat"> */}
        {/* <DropDownChat path={"chat"} showModalOption ={true} /> */}
        <DropDownChatNew
          // botname="embed"
          path={"chat"}
          // initialMsg={this.state.insightInitialMsg}
          // insightInitialMsg={this.state.insightInitialMsg}
          // modelArr={this.state.modelArr}
          showModalOption={true}
        />
        {/* </div> */}
      </>
    );
  }
}
// import * as React from "react";
// import DropDownChat from "../components/DropDownChat/DropDownChat";
// import Tooltip from "@mui/material/Tooltip";
// import { Button } from "react-bootstrap";
// // import { getEmbedModels } from "../apis/embed";
// import toastHelper from ".././helpers/toast";

// import "../style/chat.scss";
// import FullEmbed from "../components/DropDownChat/DropDownChatNew";
// import { getEmbeds } from "../apis/embed";
// import DropDownChatNew from "../components/DropDownChat/DropDownChatNew";
// // import FullEmbed from "./FullChatEmbed";

// interface TProps {}

// interface TState {
//   initialMsg: string;
//   insightInitialMsg: string;
//   btnText: string;
//   placement: string;
//   icon: string;
//   tooltipOpen: boolean;
//   modelArr: any[];
// }

// export default class Chat extends React.Component<TProps, TState> {
//   constructor(props: TProps) {
//     super(props);
//     this.state = {
//       initialMsg: "ask",
//       insightInitialMsg:
//         "Welcome to Neuralmind Ask, here are some popular Product FAQs you can ask our trained AI model.",
//       btnText: "",
//       placement: "",
//       icon: "",
//       tooltipOpen: false,
//       modelArr: [],
//     };
//   }

//   componentDidMount(): void {
//     // this.fetchModels();
//     const urlSearchParams = new URLSearchParams(window.location.search);
//     const chatbotParam = urlSearchParams.get("botname");

//     if (chatbotParam == "embed") {
//       this.setState({ tooltipOpen: true });
//     }
//   }

//   // fetchModels = async () => {
//   //   try {
//   //     const res: any = await getEmbeds();
//   //     this.setState({
//   //       modelArr: res.models,
//   //       initialMsg: res.welcome_message,
//   //       insightInitialMsg: res.welcome_message_insight,
//   //       btnText: res.button_text,
//   //       placement: res.placement,
//   //       icon: res.icon,
//   //     });
//   //   } catch (err) {
//   //     toastHelper.error("Could not fetch models!");
//   //   }
//   // };

//   render() {
//     return (
//       <>
//         {/* <Tooltip
//           open={this.state.tooltipOpen}
//           disableFocusListener
//           disableHoverListener
//           disableTouchListener
//           //@ts-ignore
//           placement={this.state.placement || "bottom-start"}
//           classes={{
//             tooltip: "tooltip",
//           }}
//           PopperProps={{
//             sx: { zIndex: "unset" },
//           }}
//           onClick={() => {
//             if (this.state.tooltipOpen) this.setState({ tooltipOpen: false });
//             else this.setState({ tooltipOpen: true });
//           }}
//           title={
//             <div className="chat h-100">
//               {/* <DropDownChat
//                 botname="embed"
//                 initialMsg={this.state.insightInitialMsg}
//                 insightInitialMsg={this.state.insightInitialMsg}
//                 modelArr={this.state.modelArr}
//               /> */}
//         {/* </div> */}
//         {/* } */}
//         {/* > */}
//         {/* <Button variant="light">
//             {this.state.btnText ? (
//               this.state.btnText
//             ) : (
//               <img src={this.state.icon} width="35" height="35" />
//             )}
//           </Button> */}
//         {/* </Tooltip> */}
//         <DropDownChatNew
//           botname="embed"
//           initialMsg={this.state.insightInitialMsg}
//           // insightInitialMsg={this.state.insightInitialMsg}
//           // modelArr={this.state.modelArr}
//         />
//       </>
//     );
//   }
// }
