import * as React from "react";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { getArticles } from "../../apis/article";
import { getEmbeds, postEmbed, uploadEmbedIcon } from "../../apis/embed";
import { getConnectDBs } from "../../apis/connect-db";
import PreviewEmbed from "./PreviewEmbed";
import toast, { toastHelper } from "../../helpers/toast";
import PreviewFullEmbed from "./PreviewFullEmbed";
interface EmbedProps {
  onEmbedKeyChange: any;
  handleFullEmbedKeyChange: any;
  selectedEmbedKey: any;
  toggleShowCode: () => void;
  handleEmbedTypeChange: any;
  embedTypeProps: string;
}

interface EmbedState {
  loading: boolean;
  embedName: string;
  welcomeMsg: string;
  WelcomeMsgInsights: string;
  insightCheckbox: boolean;
  productCheckbox: boolean;
  modelOptions: any;
  dbTableOptions: any;
  selectedModels: any[];
  selectedDbTable: any[];
  localImgUrl: any;
  companyLogo: any;
  statusCopy: string;
  embeddings: any[];
  buttonType: string;
  embedButtonType: string;
  buttonText: string;
  placement: "top-start" | "top-end" | "bottom-start" | "bottom-end";
  previewModalOpen: boolean;
  errors: {
    [key: string]: string;
  };
  selectedEmbeddings: any;
  modelEmbeddings: any;
}

class CreateEmbed extends React.Component<EmbedProps, EmbedState> {
  state: EmbedState = {
    loading: false,
    embedName: "",
    welcomeMsg:
      "Welcome! I will be your AI Copilot and assist as you as you use our product. How can I help?",
    WelcomeMsgInsights: "Welcome to Neuralmind Insights",
    selectedModels: [],
    selectedEmbeddings: [],
    selectedDbTable: [],
    insightCheckbox: true,
    productCheckbox: true,
    companyLogo: null,
    localImgUrl: "",
    errors: {},
    modelOptions: [],
    modelEmbeddings: [],
    dbTableOptions: [],
    statusCopy: "Copy",
    embeddings: [],
    placement: "bottom-end",
    previewModalOpen: false,
    buttonType: "text",
    embedButtonType: "full_page",
    buttonText: "AI Copilot",
  };

  componentDidMount() {
    this.getArticles();
    this.getDbs();
  }

  async getDbs() {
    try {
      const dbResponse: any = await getConnectDBs();
      const dbs = dbResponse.dbs.map((value: any) => {
        return { _id: value._id, db: value.database };
      });

      this.setState({ dbTableOptions: dbs });
    } catch (err) {
      console.log(err);
    }
  }

  async getArticles() {
    try {
      const articleResponse: any = await getArticles();

      let modelOptions = [];

      for (let i = 0; i < articleResponse.articles.length; i++) {
        let article = articleResponse.articles[i];
        modelOptions.push({
          value: article._id,
          label: article.model_name,
        });
      }
      this.setState({ modelOptions });
      this.getEmbedData();
    } catch (error) {
      console.log(error);
    }
  }

  getEmbedData = async () => {
    try {
      const response: any = await getEmbeds();
      let embeddings = [];
      let modelEmbeddings = [];

      for (let index = 0; index < response.embeddings.length; index++) {
        const embed = response.embeddings[index];
        embed.id = index + 1;
        embed.models = embed.models.map((modelId: any) => {
          const modelOption = this.state.modelOptions.find(
            (model: any) => modelId === model.value
          );
          return modelOption ? modelOption.label : modelId;
        });
        embeddings.push(embed);
        modelEmbeddings.push({
          value: embed.id,
          label: embed.embedding_name,
        });
      }
      this.setState({ embeddings });
      this.setState({ modelEmbeddings });
    } catch (error) {
      console.log(error);
    }
  };

  generateIframeEmbedCode = () => {
    this.props.toggleShowCode();
    // Get the selected value from the dropdown (selected embedding)
    const selectedEmbeddingValue = this.state.selectedEmbeddings?.value;

    // Find the corresponding row in the dropdown options (modelEmbeddings)
    const selectedRow = this.state.modelEmbeddings.find(
      (row: { value: any }) => row.value === selectedEmbeddingValue
    );
    if (selectedRow) {
      // Extract the embed key from the selected row
      const embedKey = this.state.embeddings.find(
        (row) => row.embedding_name === selectedRow.label
      )?.apiKey;

      const fullEmbedKey = this.state.embeddings.find(
        (row) => row.id === selectedRow.value
      )?.embed_type;

      if (embedKey && fullEmbedKey) {
        // Generate the iframe embed code using the embed key
        const iframeEmbedCode = `<iframe src="https://your-website.com/${fullEmbedKey}/${embedKey}" frameborder="0" width="100%" height="600"></iframe>`;
        // Do something with the generated iframeEmbedCode (e.g., show it in a toast or copy to clipboard)
        console.log(iframeEmbedCode);
        toast.success("Iframe Embed code generated.");
        this.props.onEmbedKeyChange(embedKey);
        this.props.handleFullEmbedKeyChange(fullEmbedKey);
      } else {
        toast.error("Embed key not found for the selected embedding.");
      }
    } else {
      toast.error("Please select an embedding from the dropdown.");
    }
  };

  handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { embedName } = this.state;

    // Validate the form
    let errors: EmbedState["errors"] = {};
    if (!embedName) {
      errors.embedName = "Embed Name is required";
    }

    if (this.state.selectedModels.length == 0) {
      errors.selectModel = "At least select one model";
    }

    console.log(errors);

    console.log(this.state);
    this.setState({ errors });

    // If there are no errors, submit the form
    if (Object.keys(errors).length === 0) {
      // Make API call to add the bot to Slack using the token and secret
      // ...
      try {
        this.setState({ loading: true });

        let imgUrl = "";
        let btnText = "";

        if (this.state.buttonType == "text") {
          btnText = this.state.buttonText;
        } else {
          // if (this.state.companyLogo != null) imgUrl = await uploadEmbedIcon(this.state.companyLogo);
          imgUrl = "https://app.neuralmind.io/assets/images/logo.svg";
        }

        const response: any = await postEmbed({
          embedding_name: embedName,
          //@ts-ignore
          models: [this.state.selectedModels.value],
          // dbs: this.state.selectedDbTable.map((item: any) => item.value),
          welcome_message: this.state.welcomeMsg,
          welcome_message_insight: this.state.WelcomeMsgInsights,
          icon: imgUrl,
          button_text: btnText,
          placement: this.state.placement,
          embed_type: this.props.embedTypeProps,
          // insights: this.state.insightCheckbox,
          // products: this.state.productCheckbox
        });

        this.setState({
          embedName: "",
          selectedModels: [],
          welcomeMsg: "",
          WelcomeMsgInsights: "",
          companyLogo: null,
          // selectedDbTable: [],
          // insightCheckbox: true,
          // productCheckbox: true,
          loading: false,
        });

        toast.success("New iframe embed created.");
        this.getEmbedData();
      } catch (error: any) {
        toastHelper.error(error.message || "Something went wrong");
        this.setState({ loading: false });
        console.log(error);
      }
    }
  };
  handlePlacementClick = (placement: any) => {
    this.setState({ placement });
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ [event.target.name]: event.target.value } as any);
  };
  handleCopyLink = (row: { embed_type: any; apiKey: any }) => {
    const embedType = row.embed_type === "Chat_window" ? "embed" : "full_embed";
    const link = `https://api.neuralmind.io/v1/${embedType}/${row.apiKey}`;
    navigator.clipboard.writeText(link);
    toast.success("Link copied to clipboard.");
  };

  handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //@ts-ignore
    let file = e.target.files[0];
    this.setState({ companyLogo: file });

    let reader = new FileReader();
    reader.onloadend = () => {
      this.setState({ localImgUrl: reader.result });
    };
    reader.readAsDataURL(file);
  };

  columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Sl. No.",
      flex: 0.3,
      filterable: false,
      sortable: false,
      headerClassName: "custom-column-header",
    },
    {
      field: "embedding_name",
      headerName: "Embedding Name",
      flex: 1,
      filterable: false,
      sortable: false,
      headerClassName: "custom-column-header",
    },
    {
      field: "models",
      headerName: "Models Included",
      headerClassName: "custom-column-header",
      sortable: false,
      flex: 1,
      minWidth: 150,
      filterable: false,
      renderCell: (params) => params.value.join(", "),
    },
    {
      field: "apiKey",
      headerName: "Testing Link",
      headerClassName: "custom-column-header",
      sortable: false,
      minWidth: 200,
      flex: 1,
      filterable: false,
      renderCell: (params) => {
        let displayValue = params.value;
        if (displayValue.length > 15)
          displayValue = displayValue.slice(0, 15) + "...";

        return (
          <div className="d-flex">
            {/* <div>{displayValue}</div> */}
            <div role="button" onClick={() => this.handleCopyLink(params.row)}>
              <img src="/assets/images/copy-link.svg" width="24" height="24" />
              <span
                style={{ color: "#3E6FE1", fontWeight: 500, marginLeft: "5px" }}
              >
                Copy Link
              </span>
            </div>
          </div>
        );
      },
    },
  ];

  render() {
    let errors: EmbedState["errors"] = this.state.errors;
    const { embedTypeProps } = this.props;
    const isFullPage = embedTypeProps === "full_page";

    // Define a CSS class based on the isFullPage flag
    const containerClassName = isFullPage ? "gray-background" : "";
    return (
      <div>
        <div className="d-flex">
          <Form className="col-sm-6" onSubmit={this.handleSubmit}>
            <p
              style={{ fontWeight: 600, fontSize: "20px" }}
              className="embed-item-step-1"
            >
              Create an Embed
            </p>
            <Form.Group className="my-3">
              <Form.Label>Embedding Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter Embedding Name"
                name="embedName"
                value={this.state.embedName}
                onChange={this.handleChange}
              />
              {errors.embedName && (
                <div className="text-danger">{errors.embedName}</div>
              )}
            </Form.Group>

            <Form.Group className="my-3">
              <Form.Label>Embed Type</Form.Label>
              <Form.Select
                required
                placeholder="Select Type"
                name="embedButtonType"
                value={this.props.embedTypeProps}
                onChange={this.props.handleEmbedTypeChange}
              >
                <option value="full_page">Full page</option>
                <option
                  disabled
                  className={containerClassName}
                  value="Chat_window"
                >
                  Chat window (Coming soon!)
                </option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="my-3">
              <Form.Label>Trained AI models</Form.Label>
              <Select
                isMulti={false}
                placeholder="Select Trained AI models"
                options={this.state.modelOptions}
                value={this.state.selectedModels}
                onChange={(selectedModels: any) => {
                  this.setState({ selectedModels });
                }}
              />
              {errors.selectModel && (
                <div className="text-danger">{errors.selectModel}</div>
              )}
            </Form.Group>

            {/* <Form.Group className="my-3">
              <Form.Label>Connect Database</Form.Label>
              <Select
                isMulti
                placeholder="Connect Database"
                options={this.state.dbTableOptions}
                value={this.state.selectedDbTable}
                onChange={(selectedDbTable: any) => {
                  this.setState({ selectedDbTable });
                }}
              />
              {errors.selectDbTable && (
                <div className="text-danger">{errors.selectDbTable}</div>
              )}
            </Form.Group> */}
            <Form.Group className="my-3">
              <Form.Label>Welcome Message-Insights</Form.Label>
              <Form.Control
                required
                as="textarea"
                placeholder="Enter Welcome Message"
                name="WelcomeMsgInsights"
                value={this.state.WelcomeMsgInsights}
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group className="my-3">
              <Form.Label>Welcome Message-Ask</Form.Label>
              <Form.Control
                required
                as="textarea"
                placeholder="Enter Welcome Message"
                name="welcomeMsg"
                value={this.state.welcomeMsg}
                onChange={this.handleChange}
              />
            </Form.Group>

            {this.props.embedTypeProps === "Chat_window" && (
              <>
                <Form.Group className="my-3">
                  <Form.Label>Button type: </Form.Label>
                  <Form.Select
                    required
                    name="buttonType"
                    value={this.state.buttonType}
                    //@ts-ignore
                    onChange={this.handleChange}
                  >
                    <option value="text">Text only</option>
                    <option value="image">Image only</option>
                  </Form.Select>
                </Form.Group>

                {this.state.buttonType === "text" && (
                  <Form.Group className="my-3">
                    <Form.Label>Button Text</Form.Label>
                    <Form.Control
                      required
                      placeholder="Button text"
                      name="buttonText"
                      value={this.state.buttonText}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                )}

                <div>
                  <div>Placement</div>
                  <div style={{ color: "#868686", fontWeight: 500 }}>
                    Choose embed placement.{" "}
                  </div>
                </div>

                <div className="row" style={{ paddingLeft: "unset" }}>
                  <div
                    style={{ paddingLeft: "unset" }}
                    onClick={() => this.handlePlacementClick("top-start")}
                    className={`col d-flex flex-column align-items-center ${
                      this.state.placement === "top-start"
                        ? "selected-placement"
                        : ""
                    }`}
                  >
                    {this.state.placement === "top-start" ? (
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          cursor: "pointer",
                        }}
                        src="/assets/images/bottom-lefts.svg"
                      ></img>
                    ) : (
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          cursor: "pointer",
                        }}
                        src="/assets/images/bottom-left.svg"
                      ></img>
                    )}
                    <span>Bottom Left</span>
                  </div>
                  <div
                    style={{ paddingLeft: "unset" }}
                    onClick={() => this.handlePlacementClick("top-end")}
                    className={`col d-flex flex-column align-items-center ${
                      this.state.placement === "top-end"
                        ? "selected-placement"
                        : ""
                    }`}
                  >
                    {this.state.placement === "top-end" ? (
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          cursor: "pointer",
                        }}
                        src="/assets/images/bottom-rights.svg"
                      ></img>
                    ) : (
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          cursor: "pointer",
                        }}
                        src="/assets/images/bottom-right.svg"
                      ></img>
                    )}

                    <span>Bottom Right</span>
                  </div>
                  <div
                    style={{ paddingLeft: "unset" }}
                    onClick={() => this.handlePlacementClick("bottom-start")}
                    className={`col d-flex flex-column align-items-center ${
                      this.state.placement === "bottom-start"
                        ? "selected-placement"
                        : ""
                    }`}
                  >
                    {this.state.placement === "bottom-start" ? (
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          cursor: "pointer",
                        }}
                        src="/assets/images/top-lefts.svg"
                      ></img>
                    ) : (
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          cursor: "pointer",
                        }}
                        src="/assets/images/top-left.svg"
                      ></img>
                    )}
                    <span>Top Left</span>
                  </div>
                  <div
                    style={{ paddingLeft: "unset" }}
                    onClick={() => this.handlePlacementClick("bottom-end")}
                    className={`col d-flex flex-column align-items-center ${
                      this.state.placement === "bottom-end"
                        ? "selected-placement"
                        : ""
                    }`}
                  >
                    {this.state.placement === "bottom-end" ? (
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          cursor: "pointer",
                        }}
                        src="/assets/images/top-rights.svg"
                      ></img>
                    ) : (
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          cursor: "pointer",
                        }}
                        src="/assets/images/top-right.svg"
                      ></img>
                    )}
                    <span>Top Right</span>
                  </div>
                </div>
              </>
            )}
            {/* <Form.Group className="my-3">
              <Form.Check
                label="Insights"
                defaultChecked
                //@ts-ignore
                value={this.state.insightCheckbox}
                onChange={(e) => this.setState({ insightCheckbox: e.target.checked })}
              />
            </Form.Group>

            <Form.Group className="my-3">
              <Form.Check
                label="Product"
                defaultChecked
                //@ts-ignore
                value={this.state.productCheckbox}
                onChange={(e) => this.setState({ productCheckbox: e.target.checked })}
              />
            </Form.Group> */}

            <div className="mt-3">
              <Button
                style={{
                  borderRadius: "5px",
                  border: "unset",
                  background:
                    "linear-gradient(132deg, #4776F1 0%, #1333B6 100%)",
                  boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.15)",
                }}
                type="submit"
                disabled={this.state.loading}
              >
                Create a new Embedding
                {this.state.loading && (
                  <div className="spinner-border text-light ms-2" role="status">
                    &nbsp; <span className="sr-only"></span>
                  </div>
                )}
              </Button>
            </div>
          </Form>

          <div className="col-sm-6 px-4">
            {this.props.embedTypeProps === "Chat_window" ? (
              <PreviewEmbed
                companyLogo={
                  this.state.localImgUrl ? this.state.localImgUrl : ""
                }
                welcomeMsg={this.state.welcomeMsg}
                welcomeMsgInsights={this.state.WelcomeMsgInsights}
                placement={this.state.placement}
                productChecbox={this.state.productCheckbox}
                insightCheckbox={this.state.insightCheckbox}
                buttonType={this.state.buttonType}
                buttonText={this.state.buttonText}
              />
            ) : (
              <PreviewFullEmbed />
            )}
          </div>
        </div>
        <div className="col-10 mt-5 datagrid-column-remove">
          <p
            style={{ color: "#000", fontWeight: 600 }}
            className="embed-item-step-3"
          >
            Live Embeds{` (${this.state.embeddings.length})`}
          </p>
          <DataGrid
            rows={this.state.embeddings}
            columns={this.columns}
            getRowId={(row) => row._id}
            hideFooterPagination
            checkboxSelection={false}
            rowsPerPageOptions={[]}
            disableColumnMenu
            disableSelectionOnClick
            style={{ height: "300px", width: "100%" }}
          />
          <div className="box-design mt-5" style={{ backgroundColor: "#ffff" }}>
            <p
              className="embed-item-step-4"
              style={{ color: "#000", fontWeight: 600 }}
            >
              Iframe Embed Code
            </p>
            <div
              className="row"
              style={{
                border: " 1px solid #E1E1E1",
                margin: 0,
                padding: 15,
                borderRadius: "6px",
              }}
            >
              <div className="mb-3">Select Embed</div>
              <div className="col">
                <Select
                  isMulti={false}
                  placeholder="Select Embed"
                  options={this.state.modelEmbeddings}
                  value={this.state.selectedEmbeddings}
                  menuPlacement="auto" // This will automatically open the dropdown up if there is not enough space below
                  onChange={(selectedEmbeddings: any) => {
                    this.setState({ selectedEmbeddings });
                  }}
                />
              </div>
              <div className="col">
                <Button
                  style={{
                    borderRadius: "5px",
                    border: "unset",
                    background:
                      "linear-gradient(132deg, #4776F1 0%, #1333B6 100%)",
                    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.15)",
                  }}
                  type="submit"
                  onClick={this.generateIframeEmbedCode} // Call the function on button click
                >
                  Generate Iframe Embed code
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateEmbed;
