import axios from "axios";
import { getCookie } from "./cookie";

axios.defaults.headers["Access-Control-Allow-Origin"] = "*";

axios.defaults.headers["Accept"] = "*/*";
axios.defaults.headers["Accept-Language"] = "en";
axios.defaults.headers["Content-Language"] = "en-US";

const token = getCookie("token");
const company_id = getCookie("company_id");
const current_user = getCookie("current_user");

axios.defaults.headers.authorization = `Bearer ${token}`;
axios.defaults.headers["x-companyid"] = company_id;
axios.defaults.headers["x-appengine-company-user"] = current_user;

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_PYTHON_ENGINE}api/v1`,
});

export default instance;
