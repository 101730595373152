import React, { Component } from "react";
import "../../style/preview-full-embed.scss";
import EmbedModal from "./EmbedModal";

interface PreviewFullEmbedProps {}

interface PreviewFullEmbedState {
  isModalOpen: boolean;
}

class PreviewFullEmbed extends React.Component<
  PreviewFullEmbedProps,
  PreviewFullEmbedState
> {
  constructor(props: PreviewFullEmbedProps) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }
  // Function to open the modal
  openModal = () => {
    this.setState({ isModalOpen: true });
  };
  // Function to handle modal cancellation (closing)
  handleCancel = () => {
    this.setState({ isModalOpen: false });
  };
  render() {
    return (
      <div className="preview-full-embed">
        <h4 className="fw-bold embed-item-step-2">Preview</h4>
        <div>
          {/* Attach the openModal function to the onClick event of the image */}
          <img
            style={{ cursor: "pointer" }}
            className="full-embed"
            src="/assets/images/fullEmbedScreen.svg"
            onClick={this.openModal}
          />
        </div>
        <div>
          {/* Pass isModalOpen state, openModal, and handleCancel functions as props */}
          <EmbedModal
            isModalOpen={this.state.isModalOpen}
            openModal={this.openModal}
            handleCancel={this.handleCancel}
          />
        </div>
      </div>
    );
  }
}
export default PreviewFullEmbed;
