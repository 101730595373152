import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import ProgressBar from "react-bootstrap/ProgressBar";

import DeleteModal from "../../helpers/delete-modal";
import axios from "../../helpers/axios-python";
import { toastHelper } from "../../helpers/toast";

interface TProps {
  model: string;
}

interface TState {
  currLink: string;
  linkArr: any[];
  delteModalOpen: boolean;
  loading: boolean;
  learnedArr: any[];
  selectedRow:
    | {
        type: string;
        index: number;
        learned: boolean;
      }
    | undefined;
  showProgres: boolean;
  totalUrl: number;
  syncedUrl: number;
}

export default class LinksList extends Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = {
      currLink: "",
      delteModalOpen: false,
      loading: false,
      selectedRow: undefined,
      linkArr: [],
      learnedArr: [],
      showProgres: false,
      totalUrl: 0,
      syncedUrl: 0,
    };
    this.fetchSyncUrl();
  }

  componentDidMount() {
    const localStorageSessionValue = localStorage.getItem("sessionId");
    if (localStorageSessionValue) {
      this.listenForMsg(localStorageSessionValue);
    }
  }
  componentDidUpdate(prevProps: Readonly<TProps>): void {
    if (prevProps.model !== this.props.model) this.fetchSyncUrl();
  }
  fetchSyncUrl = async () => {
    this.setState({ loading: true });
    try {
      const res = await axios.get(`/synced-urls?model_id=${this.props.model}`);

      this.setState({ learnedArr: res.data, loading: false });
    } catch (err: any) {
      console.log("err", err);
      toastHelper.error(err.message || "Something went wrong!");
      this.setState({ loading: false });
    }
  };

  syncAndCrawl = async () => {
    this.setState({ loading: true });
    try {
      const res = await axios.post("/urls-same-domain", {
        url: this.state.currLink,
      });

      this.setState({ linkArr: res.data, loading: false });
    } catch (err: any) {
      console.log("err", err);
      toastHelper.error(err.message || "Something went wrong!");
      this.setState({ loading: false });
    }
  };

  handlePreDelete = (type: string, index: number, learned: boolean) => {
    this.setState({ selectedRow: { type, index, learned } });
  };

  confirmDelete = () => {
    if (!this.state.selectedRow) return;

    const selectedRow = this.state.selectedRow;

    if (selectedRow.type === "single") {
      if (selectedRow.learned) this.deleteLearnedRow(selectedRow.index);
      else this.deleteNewRow(selectedRow.index);
    } else {
      if (selectedRow.learned) this.deletAllLearned();
      else this.deletAllNew();
    }

    this.setState({ delteModalOpen: false });
  };

  deleteNewRow = (i: number) => {
    let tmpArr = [...this.state.linkArr];
    tmpArr.splice(i, 1);
    this.setState({ linkArr: tmpArr });
  };

  deleteLearnedRow = async (i: number) => {
    this.setState({ loading: true });
    try {
      const res = await axios.post("/un-train", {
        filename: this.state.learnedArr[i].filename,
      });

      if (res.status === 200) {
        let tmpArr = [...this.state.learnedArr];
        tmpArr.splice(i, 1);
        this.setState({ learnedArr: tmpArr, loading: false });
      }
    } catch (err: any) {
      console.log("err", err);
      toastHelper.error(err.message || "Something went wrong!");
      this.setState({ loading: false });
    }
  };

  deletAllNew = () => {
    this.setState({ linkArr: [] });
  };

  deletAllLearned = () => {
    this.setState({ learnedArr: [] });
  };

  handleAfterSynced = () => {
    const prevLearn = this.state.learnedArr;
    const prevLink = this.state.linkArr.map((val: any) => ({
      filename: val,
      model: this.props.model,
    }));
    this.setState({
      learnedArr: [...prevLearn, ...prevLink],
      linkArr: [],
      loading: false,
    });

    toastHelper.success("Learning Complete!");
  };

  listenForMsg = (sessionId: string) => {
    const socket = new WebSocket(
      `wss://${process.env.REACT_APP_PYTHON_ENGINE_DOMAIN}/ws/${sessionId}`
    );

    const handleSocketMsg = (e: MessageEvent<any>) => {
      const data = JSON.parse(e.data);
      const total_urls = data.total_urls;
      const synced_urls = data.synced_urls;
      if (total_urls === synced_urls) {
        this.setState({
          totalUrl: 0,
          syncedUrl: 0,
          showProgres: false,
          loading: false,
        });
        this.handleAfterSynced();
        socket.removeEventListener("message", handleSocketMsg);
        socket.close();
      } else {
        this.setState({
          totalUrl: total_urls,
          syncedUrl: synced_urls,
          showProgres: true,
          loading: true,
        });
      }
    };

    const handleScoketErr = (e: any) => {
      this.setState({
        showProgres: false,
        loading: false,
        totalUrl: 0,
        syncedUrl: 0,
      });
      toastHelper.error(e.data || "Something Went Wrong!");
      socket.removeEventListener("error", handleScoketErr);
      socket.close();
    };

    socket.addEventListener("message", handleSocketMsg);
    socket.addEventListener("error", handleScoketErr);
  };

  handleLearn = async () => {
    this.setState({ loading: true });
    try {
      const res = await axios.post("/crawl-and-sync", {
        urls: this.state.linkArr,
        model_id: this.props.model,
      });

      if (res.status === 200) {
        toastHelper.success("Learning process has started!");
        localStorage.setItem("sessionId", res.data.session_id);
        // localStorage.setItem("loader", "true");
        this.listenForMsg(res.data.session_id);
      }
    } catch (err: any) {
      console.log("err", err);
      toastHelper.error(err.message || "Something went wrong!");
      this.setState({ loading: false });
    }
  };

  handleSubmit = (e: any) => {
    e.preventDefault();
    this.syncAndCrawl();
  };

  render() {
    return (
      <>
        {this.state.showProgres && (
          <ProgressBar
            now={Math.floor((this.state.syncedUrl / this.state.totalUrl) * 100)}
            className="rounded-0"
            label={`${Math.floor(
              (this.state.syncedUrl / this.state.totalUrl) * 100
            )}%`} // Display the progress percentage inside the progress bar
          />
        )}
        <div className="p-3">
          <h5 className="fw-bold mb-3">Crawl</h5>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group className="d-flex">
              <Form.Control
                placeholder="https://www.example.com"
                value={this.state.currLink}
                onChange={(e) => this.setState({ currLink: e.target.value })}
                disabled={this.state.loading}
              />
              <Button
                className=" ms-3"
                style={{
                  borderRadius: "5px",
                  border: "unset",
                  width: "100px",

                  background:
                    "linear-gradient(132deg, #4776F1 0%, #1333B6 100%)",
                  boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.15)",
                }}
                type="submit"
                disabled={this.state.loading}
              >
                Fetch
              </Button>
            </Form.Group>
          </Form>
          {this.state.loading ? (
            <div>
              <div className="text-center mt-5">
                <div
                  className="spinner-border text-dark ms-2"
                  role="status"
                  style={{ width: 50, height: 50 }}
                >
                  &nbsp; <span className="sr-only"></span>
                </div>
                {this.state.showProgres && (
                  <div className="mt-4">
                    <p>Hold On! Learning Content. This could take a while.</p>
                    <p>
                      Learned {this.state.syncedUrl} out of
                      {this.state.totalUrl}
                    </p>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <>
              <div className="mt-4">
                <div className="d-flex justify-content-end align-items-center">
                  {!!this.state.linkArr.length && (
                    <div>
                      <Trash
                        width={15}
                        height={15}
                        style={{ fill: "#D14444", cursor: "pointer" }}
                        onClick={() => {
                          this.setState({
                            selectedRow: {
                              type: "bulk",
                              index: -1,
                              learned: false,
                            },
                            delteModalOpen: true,
                          });
                        }}
                      />
                      <Button
                        style={{
                          color: "#D14444",
                          border: "none",
                          backgroundColor: "unset",
                          fontSize: "16px",
                          fontWeight: 600,
                        }}
                        size="sm"
                        onClick={() => {
                          this.setState({
                            selectedRow: {
                              type: "bulk",
                              index: -1,
                              learned: false,
                            },
                            delteModalOpen: true,
                          });
                        }}
                      >
                        Delete All
                      </Button>
                    </div>
                  )}
                </div>
                <div className="mt-3">
                  {this.state.linkArr.map((val, i) => (
                    <div className="d-flex mb-2 align-items-center border rounded  px-2 py-1 ">
                      <p
                        className="col mb-0"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          wordWrap: "break-word",
                        }}
                      >
                        {val}
                      </p>
                      <div className="ps-3 py-1">
                        <Trash
                          color="#D14444"
                          width={20}
                          height={20}
                          role="button"
                          onClick={() => {
                            this.setState({
                              selectedRow: {
                                type: "single",
                                index: i,
                                learned: false,
                              },
                              delteModalOpen: true,
                            });
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div>
                  {this.state.linkArr.length > 0 && (
                    <Button
                      className="btn-green"
                      size="sm"
                      onClick={this.handleLearn}
                    >
                      Learn Content
                    </Button>
                  )}
                </div>
              </div>
              <div className="mt-5">
                <div
                  style={{
                    background: "#EFF1FF",
                    borderRadius: "4px",
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "5px",
                    }}
                  >
                    <img
                      src="/assets/images/disclaimer.svg"
                      alt=""
                      style={{
                        marginRight: "5px",
                        width: "18px",
                        height: "18px",
                      }}
                    />
                    <span style={{ fontSize: "14px", fontWeight: "500" }}>
                      Disclaimer
                    </span>
                  </div>
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#616585",
                      margin: "5px",
                    }}
                  >
                    We can sync upto 50 articles at a time.
                  </p>
                </div>
                <h4 className="mb-0, mt-3">Existing Content</h4>
                <div className="mt-3">
                  {this.state.learnedArr.length === 0 && (
                    <h5 className="text-secondary">
                      Nothing to show here! Start by fetching links.
                    </h5>
                  )}
                  {this.state.learnedArr.map((val: any, i) => (
                    <div className="d-flex mb-2 align-items-center border rounded px-2 py-1">
                      <p
                        className="col  mb-0"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          wordWrap: "break-word",
                        }}
                      >
                        {val.filename}
                      </p>
                      <div className="ps-3 py-1">
                        <Trash
                          color="#D14444"
                          width={20}
                          height={20}
                          role="button"
                          onClick={() => {
                            this.setState({
                              selectedRow: {
                                type: "single",
                                index: i,
                                learned: true,
                              },
                              delteModalOpen: true,
                            });
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
          <DeleteModal
            title="Are you sure you want to delete?"
            deleteAction={this.confirmDelete}
            open={this.state.delteModalOpen}
            close={() =>
              this.setState({ delteModalOpen: false, selectedRow: undefined })
            }
            loading={this.state.loading}
          />
        </div>
      </>
    );
  }
}
