import * as React from "react";
import { Button, Modal } from "react-bootstrap";
import "../../style/App.css";
import { deleteAPI, getAPIs, postAPI } from "../../apis/api";
import DeleteModal from "../../helpers/delete-modal";
import toast from "../../helpers/toast";
import { Trash } from "react-bootstrap-icons";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

interface NewAPIKeyProps {}

interface NewAPIKeyState {
  apis: any[];
  newAPIKey: string;
  newAPIKeyLoading: boolean;
  apiKeyDeleteLoading: boolean;
  openDeleteModal: boolean;
  deleteId: string;
  newModalOpen: boolean;
}

class NewAPIKey extends React.Component<NewAPIKeyProps, NewAPIKeyState> {
  state: NewAPIKeyState = {
    apis: [],
    newAPIKey: "",
    newAPIKeyLoading: false,
    apiKeyDeleteLoading: false,
    openDeleteModal: false,
    deleteId: "",
    newModalOpen: false,
  };
  columns: GridColDef[] = [
    {
      field: "createdAt",
      headerName: "Created on",
      sortable: false,
      width: 200,
      renderCell: (params: any) => {
        const date = new Date(params.value);

        // Format the date as "August 04, 2023"
        const formattedDate = new Intl.DateTimeFormat("en-US", {
          month: "long",
          day: "2-digit",
          year: "numeric",
        }).format(date);

        return <span style={{ color: "#545C70" }}>{formattedDate}</span>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 150,
      renderCell: (params: any) => (
        <Button
          className="delete-button"
          style={{
            color: "#D14444",
            border: "none",
            backgroundColor: "unset",
            fontSize: "16px",
            fontWeight: 600,
            padding: 0,
          }}
          onClick={() =>
            this.setState({ deleteId: params.row._id, openDeleteModal: true })
          }
        >
          <span>
            <Trash />
          </span>
          <span> Delete</span>
        </Button>
      ),
    },
  ];

  generateKey = async () => {
    try {
      this.setState({ newAPIKeyLoading: true });
      const response: any = await postAPI({});
      await this.getData();
      this.setState({
        newAPIKey: response.apiKey,
        newAPIKeyLoading: false,
        newModalOpen: false,
      });
      toast.success("New API key created.");
    } catch (error) {
      this.setState({ newAPIKeyLoading: false });
      console.log(error);
    }
  };

  getData = async () => {
    try {
      const response: any = await getAPIs();
      if (!response?.apis) {
        this.setState({ apis: [] });
      } else {
        this.setState({ apis: response.apis });
      }
    } catch (error) {
      console.log(error);
    }
  };

  deleteAPIKey = async (_id: string) => {
    try {
      this.setState({ apiKeyDeleteLoading: true });
      await deleteAPI({ _id });
      await this.getData();
      this.setState({ apiKeyDeleteLoading: false, openDeleteModal: false });
      toast.success("API key has been deleted.");
    } catch (error) {
      console.log(error);
    }
  };

  async componentDidMount() {
    try {
      this.getData();
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <div>
        <div>
          <p style={{ fontWeight: 600, fontSize: "20px" }}>API Key</p>

          <div className="d-flex align-items-center">
            <div className="api__key p-2 rounded" style={{ height: "42px" }}>
              <p className="mb-0">{this.state.newAPIKey}</p>
            </div>
            <div
              style={{
                borderRadius: "5px",
                border: "1px solid #1A43A3",
                boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.15)",
                width: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "8px",
              }}
              role="button"
              className="ms-3"
              onClick={() => {
                if (this.state.newAPIKey === "")
                  return toast.error("Nothing to copy.");

                navigator.clipboard.writeText(this.state.newAPIKey);
                toast.success("Copied to clipboard.");
              }}
            >
              <img src="/assets/images/copy-code.svg" alt="copy-icon" />
              <span style={{ color: "#1434B6", fontWeight: 600 }}>
                Copy Code
              </span>
            </div>
          </div>

          <Button
            className="btn-blue btn-container mt-3"
            onClick={() => this.setState({ newModalOpen: true })}
            disabled={this.state.newAPIKeyLoading}
          >
            Create a new API key
            {this.state.newAPIKeyLoading && (
              <div className="spinner-border text-light ms-2" role="status">
                &nbsp; <span className="sr-only"></span>
              </div>
            )}
          </Button>
        </div>
        <div className="remove-selector">
          <p className=" mt-5" style={{ fontWeight: 600, fontSize: "20px" }}>
            Past Keys:
          </p>
          <div style={{ height: 300, width: "100%" }}>
            <DataGrid
              rows={this.state.apis}
              columns={this.columns}
              getRowId={(row) => row._id}
              disableColumnMenu
              disableSelectionOnClick
              disableColumnSelector
              hideFooterPagination
              style={{ height: "300px", width: "100%", maxWidth: 400 }}
            />
          </div>
        </div>
        <DeleteModal
          open={this.state.openDeleteModal}
          close={() => this.setState({ openDeleteModal: false })}
          deleteAction={async () => this.deleteAPIKey(this.state.deleteId)}
          loading={this.state.apiKeyDeleteLoading}
          title="Delete API Key?"
          subtitle="you will lose all active integrations if you proceed."
        />
        <Modal
          show={this.state.newModalOpen}
          onHide={() => this.setState({ newModalOpen: false })}
        >
          <Modal.Header className="border-0" />
          <Modal.Body className="">
            <p className="model-heading">Generate a new API key?</p>
            <div className="info-container">
              <img
                src="/assets/images/blue-info-icon.svg"
                alt="info-icon"
                className="me-2"
              />
              <p className="info-text ">
                Please keep your API key safe and do not share publicly.
              </p>
            </div>
          </Modal.Body>
          <hr className="mx-4 " />
          <Modal.Footer className="d-flex justify-content-end border-0">
            <Button
              className="btn-outline btn-container"
              onClick={() => this.setState({ newModalOpen: false })}
            >
              <img src="/assets/images/blue-cross-icon.svg" alt="cross-icon" />
              Cancel
            </Button>
            <Button
              className="btn-blue btn-container"
              onClick={() => {
                this.setState({ newModalOpen: false });
                this.generateKey();
              }}
            >
              <img src="/assets/images/white-tick-icon.svg" alt="tick-icon" />
              Create
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default NewAPIKey;
