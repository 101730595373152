import Code from "../helpers/code";
import CreateEmbed from "../components/Embed/CreateEmbed";
import "../style/embed.scss";
import React from "react";

interface EmbedProps {}
interface EmbedState {
  selectedEmbedKey: string;
  selectedFullEmbedKey: string;
  showCode: boolean;
  embedTypeProps: string;
}

export default class Embed extends React.Component<EmbedProps, EmbedState> {
  codeRef: React.RefObject<HTMLDivElement>;

  constructor(props: EmbedProps) {
    super(props);
    this.codeRef = React.createRef();
  }
  state: EmbedState = {
    selectedEmbedKey: "",
    selectedFullEmbedKey: "",
    showCode: false,
    embedTypeProps: "full_page",
  };

  toggleShowCode = () => {
    this.setState(
      (prevState) => ({ showCode: true }),
      () => {
        if (this.state.showCode && this.codeRef.current) {
          this.codeRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }
    );
  };

  handleEmbedKeyChange = (selectedEmbedKey: string) => {
    this.setState({ selectedEmbedKey });
  };

  handleFullEmbedKeyChange = (selectedFullEmbedKey: string) => {
    this.setState({ selectedFullEmbedKey });
  };
  handleEmbedTypeChange = (event: any) => {
    const embedTypeProps = event.target.value;
    this.setState({ embedTypeProps });
  };

  render() {
    const { selectedEmbedKey, showCode, embedTypeProps } = this.state;

    const embedType =
      this.state.selectedFullEmbedKey === "Chat_window" ? "embed" : "fullEmbed";

    return (
      <div className="bg-white h-100 p-3">
        <div className="embed__scroll p-2">
          <CreateEmbed
            selectedEmbedKey={selectedEmbedKey}
            toggleShowCode={this.toggleShowCode}
            onEmbedKeyChange={this.handleEmbedKeyChange}
            handleFullEmbedKeyChange={this.handleFullEmbedKeyChange}
            embedTypeProps={embedTypeProps}
            handleEmbedTypeChange={this.handleEmbedTypeChange}
          />
          {showCode && (
            <div ref={this.codeRef}>
              <Code
                code={`<div id='root'></div>
      <script defer src='https://neuralmind.io/js/${embedType}.js'></script>
      <script>
      // Initialize with neuralmind with user details.
        window.onload = (event) => { neuralmind("${selectedEmbedKey}",  {
          // Add user details like user id, email, clientid, orgid which are related to user. Naming convention is your choice.
          userId: '1', 
          orgId: 'xyz'
        }); };
      </script>`}
                title="Iframe embed code"
                text={["Do not remove the double quotes"]}
                link="https://docs.neuralmind.io/integrations/embed-ui"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
