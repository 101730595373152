import axios from "../helpers/axios";
import { getCookie } from "../helpers/cookie";

export const getConnectDBs = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.get("/api/v1/connect-db");
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getConnectDBById = async (_id: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.get(`/api/v1/connect-db?id=${_id}`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getDBById = async (_id: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.get(`/api/v1/connect-db?id=${_id}`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const postConnectDB = async (connectDB: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.post("/api/v1/connect-db", connectDB);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const postAuthInfo = async (authInfo: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.post(
        "/api/v1/connect-db/authinfo",
        authInfo
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const putAuthInfo = async (authInfo: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.put(
        "/api/v1/connect-db/authinfo",
        authInfo
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const postInitInsights = async (authInfo: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.post(
        "/api/v1/connect-db/init/insights",
        authInfo
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const patchActiveStatus = async ({ id, isActive }: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.patch(
        `/api/v1/connect-db/active-state?id=${id}&isActive=${isActive}`
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const patchConnectDB = async (editConnectDB: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.patch(
        "/api/v1/connect-db",
        editConnectDB
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const postConnectDBCsv = async (connectDB: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.post(
        "/api/v1/connect-db/csv",
        connectDB
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const patchMetaData = async (selectedMetadata: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.patch(
        "/api/v1/connect-db/metadata",
        selectedMetadata
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const patchKeywords = async (keywordsBody: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.patch(
        "/api/v1/connect-db/keywords",
        keywordsBody
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const patchAuthInfo = async (authInfo: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.patch(
        "/api/v1/connect-db/authinfo",
        authInfo
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const patchApiDocs = async (docsInfo: object) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.patch(
        "/api/v1/connect-db/api-docs",
        docsInfo
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const patchQueryUrl = async (queryUrl: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.patch(
        "/api/v1/connect-db/queryurl",
        queryUrl
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getColumnDB = async (id: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.get(
        `https://dbconnector-qeu2vo6opq-ue.a.run.app/db?connection_id=${id}`
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteConnectDB = async (connectDB: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.delete(
        "/api/v1/connect-db/" + connectDB._id
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const putConnectDB = async (payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.put(
        "https://dbconnector-qeu2vo6opq-ue.a.run.app/db",
        payload
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};
