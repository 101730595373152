import toast from "./toast";

interface CodeProps {
  code: string;
  title: string;
  text: string[];
  link: string;
}

export default function Code(props: CodeProps) {
  const { code, title, text, link } = props;
  return (
    <div className="col-9 mt-5">
      <span>
        <h5 className="embed-item-step-4">{title}</h5>
      </span>
      <div className="api__curl p-1 pb-3 ps-3 rounded d-flex justify-content-between">
        <pre className="mb-0 mt-2 me-3">{code}</pre>
      </div>
      <div className="mt-3">
        {/* {text.map((val, i) => (
          <p key={i} className="mb-1">
            {val}
          </p>
        ))} */}
        <div
          role="button"
          className=" align-self-start"
          style={{
            borderRadius: "5px",
            border: "1px solid #1A43A3",
            boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.15)",
            width: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "6px",
          }}
          onClick={() => {
            navigator.clipboard.writeText(code);
            toast.success("Copied to clipboard.");
          }}
        >
          <img src="/assets/images/copy-code.svg" alt="copy-icon" />
          <span style={{ color: "#1434B6", fontWeight: 600 }}>Copy Code</span>
        </div>
        {/* <p className="mb-1">
          <a href={link} target="_blank" rel="noreferrer">
            More info
          </a>
        </p> */}
      </div>
    </div>
  );
}
