import React, { Component } from "react";
import { Table, Button, Switch } from "antd";
// import { Button } from "@mui/material";
import axios from "axios";
import { getConnectDBs, patchActiveStatus } from "../apis/connect-db";
import "../style/connect-db.scss";
import { Link } from "react-router-dom";
import { Input } from "antd";
import { InputGroup } from "react-bootstrap";
import { toastHelper } from "../helpers/toast";

interface ConnectDBProps {}

interface ConnectDBState {
  isActive: boolean;
  activeItemId: any;
}

interface ConnectDBState {
  dbData: any[];
}
export default class ConnectDB extends Component<
  ConnectDBProps,
  ConnectDBState
> {
  constructor(props: ConnectDBProps) {
    super(props);
    this.state = {
      dbData: [],
      isActive: false,
      activeItemId: null,
    };
  }

  handleGetData = async () => {
    try {
      const { dbs }: any = await getConnectDBs();
      this.setState({ dbData: dbs });
    } catch (error) {}
  };

  componentDidMount(): void {
    this.handleGetData();
  }

  /** calling patch api for active status */
  handleActiveStatus = async (_id: any, checked: boolean) => {
    // If the switch is being turned on
    if (checked) {
      const { dbData } = this.state;

      // Turn off all other switches in the UI
      const updatedDBData = dbData.map((db: any) => ({
        ...db,
        isActive: db._id === _id,
      }));

      this.setState({
        dbData: updatedDBData,
        activeItemId: _id, // Set the currently active item id
      });

      try {
        // Update the backend for the currently active item
        await patchActiveStatus({
          id: _id,
          isActive: true,
        });

        // Update the backend for all other items to be inactive
        await Promise.all(
          dbData
            .filter((db: any) => db._id !== _id)
            .map((db: any) =>
              patchActiveStatus({
                id: db._id,
                isActive: false,
              })
            )
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  render() {
    const { dbData } = this.state;

    const data = dbData?.map((dbs) => ({
      ...dbs,
      key: dbs._id,
      humanName: dbs.dbInfo.name,
      authType: dbs.authInfo?.authType,
      db_type: dbs.dbInfo.db_type,
      // id: db._id,
    }));

    const columns = [
      {
        title: "Name for Database",
        dataIndex: "humanName",
      },
      {
        title: "Authentication Type",
        dataIndex: "authType",
      },
      {
        title: "Database Type",
        dataIndex: "db_type",
      },
      {
        title: "",
        dataIndex: "id",
        render: (id: any, dbs: any) => (
          <div className="activeState d-flex justify-content-around ">
            <div className="text-right">
              {dbs.isActive ? (
                " Active"
              ) : (
                <span style={{ color: "#868686" }}> Inactive</span>
              )}
            </div>
            <div>
              <Switch
                checked={dbs.isActive}
                onChange={(checked) =>
                  this.handleActiveStatus(dbs._id, checked)
                }
              />
            </div>
          </div>
        ),
      },
      {
        title: "Action",
        dataIndex: "id",
        render: (id: any, dbs: any) => (
          <>
            <Link to={`/connectdb/${dbs._id}?is_sample=${dbs.isSample}`}>
              {dbs.isSample ? (
                <Button className="btn-outline" style={{ border: "none" }}>
                  <span style={{ color: "#3E6FE1" }}>&nbsp;View</span>{" "}
                </Button>
              ) : (
                <Button style={{ border: "none" }}>
                  <img
                    style={{ height: "20px", width: "20px" }}
                    src="/assets/images/editdb.svg"
                    alt="edit"
                  />
                  <span style={{ color: "#3E6FE1" }}>&nbsp;Edit</span>{" "}
                </Button>
              )}
            </Link>
          </>
        ),
      },
    ];

    return (
      <div className="bg-white h-100 p-3">
        <div className="row">
          <div className="col">
            <InputGroup style={{ width: "350px" }}>
              {/* <Input
                style={{
                  width: "100%",
                  height: "40px",
                }}
                placeholder="Search"
                prefix={
                  <img
                    src="/assets/images/search.svg"
                    alt="Icon"
                    style={{ height: "16px", marginRight: "8px" }}
                  />
                }
              /> */}
            </InputGroup>
          </div>
          <div className="col text-end">
            <Link to={"/connectdb"}>
              <Button
                style={{
                  background: "#3E6FE1",
                  color: "white",
                  width: "132px",
                  height: "40px",
                }}
                className="px-3 btn-blue"
              >
                + Add New DB
              </Button>
            </Link>
          </div>
        </div>
        <br />
        <div>
          <Table
            scroll={{ x: 950, y: "calc(100vh - 220px)" }}
            pagination={false}
            className="table"
            columns={columns}
            dataSource={data}
            bordered
          />
        </div>
      </div>
    );
  }
}
