import React from "react";
import { ToastContainer } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { Routes, Route, matchRoutes, Navigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import Header from "./components/common/Header";
import Admin from "./container/Admin";
import Agents from "./components/Admin/Agents";
import Api from "./components/Admin/Api";
import Bills from "./components/Admin/Bill";
import Chat from "./container/Chat";
import Train from "./container/Train";

import withLocation from "./helpers/location";
import Integrations from "./components/Integartion/integration";

import Navbar from "./components/common/Navbar";
import Embed from "./container/Embed";
import ConnectDB from "./container/ConnectDB";
import "./style/App.css";
import ExistingConnectDB from "./container/ExistingConnectDB";

const routesMatchArray = [
  {
    path: "/",
    key: 1,
    name: "Train",
    videoSrc: "https://www.youtube.com/embed/PS4Z1I4MPl4",
  },
  {
    path: "/chat",
    key: 2,
    name: "Chat",
    videoSrc: "https://www.youtube.com/embed/PS4Z1I4MPl4",
  },
  {
    path: "/admin",
    key: 3,
    name: "Admin",
    videoSrc: "https://www.youtube.com/embed/PS4Z1I4MPl4",
  },
  {
    path: "/admin/agent",
    key: 3,
    name: "Agents",
    videoSrc: "https://www.youtube.com/embed/PS4Z1I4MPl4",
  },
  {
    path: "/admin/api",
    key: 3,
    name: "API",
    videoSrc: "https://www.youtube.com/embed/PS4Z1I4MPl4",
  },
  {
    path: "/admin/bills",
    key: 3,
    name: "Plans and Billing",
    videoSrc: "https://www.youtube.com/embed/PS4Z1I4MPl4",
  },
  {
    path: "/:id",
    key: 4,
    name: "Train",
    videoSrc: "https://www.youtube.com/embed/PS4Z1I4MPl4",
  },
  {
    path: "/integrations",
    key: 5,
    name: "Integrations",
    videoSrc: "https://www.youtube.com/embed/PS4Z1I4MPl4",
  },
  {
    path: "/connectdb",
    key: 6,
    name: "Connect DB",
    videoSrc: "https://www.youtube.com/embed/PS4Z1I4MPl4",
  },
  {
    path: "/connectdb/:id",
    key: 7,
    name: "Connect DB",
    videoSrc: "https://www.youtube.com/embed/PS4Z1I4MPl4",
  },
  {
    path: "/embed",
    key: 8,
    name: "Embed",
    videoSrc: "https://www.youtube.com/embed/PS4Z1I4MPl4",
  },
  {
    path: "/existingdb",
    key: 9,
    name: "Existing Connect DB",
    videoSrc: "https://www.youtube.com/embed/PS4Z1I4MPl4",
  },
];

interface AppProps {
  location: any;
}

interface AppState {
  pathKey: number;
  pathName: string;
  videoSrc: string;
  open: boolean;
}

class App extends React.Component<AppProps, AppState> {
  constructor(props: any) {
    super(props);
    this.state = {
      pathKey: 0,
      pathName: "",
      videoSrc: "",
      open: false,
    };
  }

  componentDidMount(): void {
    const uniqueIdentifier = localStorage.getItem("uniqueIdentifier");
    if (!uniqueIdentifier) {
      localStorage.setItem("uniqueIdentifier", uuidv4());
    }
    let pathArr = matchRoutes(routesMatchArray, this.props.location);
    if (pathArr && pathArr.length) {
      if (this.state.pathName == pathArr[0].route.name) return;
      else
        this.setState({
          ...this.state,
          pathKey: pathArr[0].route.key,
          pathName: pathArr[0].route.name,
          videoSrc: pathArr[0].route.videoSrc,
        });
    }
  }

  componentDidUpdate(
    prevProps: Readonly<AppProps>,
    prevState: Readonly<AppState>,
    snapshot?: any
  ): void {
    let pathArr = matchRoutes(routesMatchArray, this.props.location);
    if (pathArr && pathArr.length) {
      if (prevState.pathName == pathArr[0].route.name) return;
      this.setState({
        ...this.state,
        pathKey: pathArr[0].route.key,
        pathName: pathArr[0].route.name,
        videoSrc: pathArr[0].route.videoSrc,
      });
    }
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render(): React.ReactNode {
    const { open } = this.state;
    const contentWidth = open ? "calc(100% - 250px)" : "calc(100% - 65px)";
    return (
      <div className="App">
        <ToastContainer icon={false} closeOnClick={true} />
        <div className="container-fluid">
          <div>
            <div className="col-sm-2 p-0">
              <Header
                pathKey={this.state.pathKey}
                togglesidebar={this.state.open}
                handleOpen={this.handleOpen}
                handleClose={this.handleClose}
              />
            </div>
            <div
              className="col-sm-10 d-flex flex-column "
              style={{
                width: contentWidth,
                transform: `translateX(${open ? "240px" : "65px"})`, // Adjust the transform value to control the position
                transition: "transform 0.3s", // Adjust the transition duration as needed
                overflowX: "hidden",
                height: "100vh",
                overflowY: "hidden",
              }}
            >
              <Navbar
                pathName={this.state.pathName}
                videoSrc={this.state.videoSrc}
              />
              <Routes>
                <Route path="/" element={<Navigate replace to="/chat" />} />
                <Route path="/chat" element={<Chat />} />
                <Route path="/train" element={<Train />} />
                <Route path="/link" element={<Train />} />
                <Route path="/:id" element={<Train />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/admin/agent" element={<Agents />} />
                <Route path="/admin/api" element={<Api />} />
                <Route path="/admin/bills" element={<Bills />} />
                <Route path="/integrations" element={<Integrations />} />
                <Route path="/embed" element={<Embed />} />
                <Route path="/connectdb/:id" element={<ConnectDB />} />
                <Route path="/connectdb" element={<ConnectDB />} />
                <Route path="/existingdb" element={<ExistingConnectDB />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withLocation(App);
