import React from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import { Chat } from "react-bootstrap-icons";
import Fab from "@mui/material/Fab";
// import DropDownChat from "../DropDownChat/DropDownChat";

class Chatbot extends React.Component<
  {},
  { open: boolean; tooltipOpen: boolean }
> {
  constructor(props: {}) {
    super(props);
    this.state = {
      open: false,
      tooltipOpen: false,
    };
  }

  // componentDidMount(): void {
  //   const urlSearchParams = new URLSearchParams(window.location.search);
  //   const chatbotParam = urlSearchParams.get("botname");

  //   if (chatbotParam == "chat") {
  //     this.setState({ tooltipOpen: true });
  //   }
  // }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChatbotClick = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
    }));
  };

  handleTooltipClick = () => {
    this.setState((prevState) => ({
      tooltipOpen: !prevState.tooltipOpen,
    }));
  };

  render() {
    const { tooltipOpen } = this.state;

    return (
      <></>
      // <Box
      //   sx={{
      //     height: 320,
      //     transform: "translateZ(0px)",
      //     flexGrow: 1,
      //     position: "fixed",
      //     bottom: "10px",
      //     right: "10px",
      //     zIndex: 1,
      //   }}
      // >
      //   <Tooltip
      //     open={tooltipOpen}
      //     placement="left-start"
      //     PopperProps={{
      //       sx: { zIndex: "unset" },
      //     }}
      //     classes={{
      //       tooltip: "navbar__tooltip",
      //     }}
      //     onClick={this.handleTooltipClick}
      //     title={
      //       <div className="border h-100">
      //         <DropDownChat botname={"chat"} />
      //       </div>
      //     }
      //   >
      //     {tooltipOpen ? (
      //       <Fab
      //         role="button"
      //         className={`dropDownChat__icon text-white d-flex justify-content-center align-items-center p-2 `}
      //         // aria-label="edit"
      //         sx={{
      //           position: "absolute",
      //           bottom: 16,
      //           right: 16,
      //         }}
      //       >
      //         <img
      //           src="/assets/images/cross-icon.svg"
      //           className="img-fluid"
      //           width="15"
      //           height="15"
      //         />
      //       </Fab>
      //     ) : (
      //       <Button
      //         sx={{
      //           position: "absolute",
      //           bottom: 16,
      //           right: 16,
      //           width: "max-content",
      //           backgroundColor: "#fff",
      //           "&:hover": {
      //             backgroundColor: "#fff",
      //           },
      //         }}
      //         variant="outlined"
      //         fullWidth
      //         startIcon={<img src="/assets/images/lab-icon.svg" />}
      //       >
      //         <span
      //           style={{
      //             fontWeight: 600,
      //             color: "#3E6FE1",
      //             textTransform: "capitalize",
      //           }}
      //         >
      //           {" "}
      //           Test AI Model
      //         </span>
      //       </Button>
      //     )}
      //   </Tooltip>
      // </Box>
    );
  }
}

export default Chatbot;
