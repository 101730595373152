import { useParams, useSearchParams } from "react-router-dom";

const withRouter = (WrappedComponent: any) => (props: any) => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <WrappedComponent
      {...props}
      params={params}
      queryModel={searchParams.get("model")}
    />
  );
};

export default withRouter;
// export default withRouter(ExpandConversation);
