import * as React from "react";
import FolderTree from "../components/Train/FolderTree";
import withRouter from "../helpers/params";
import withNavigate from "../helpers/navigate";
import Chatbot from "../components/Train/Chatbot";
import Editor from "../components/Train/Editor";
import LinksList from "../components/Train/LinksList";
import withLocation from "../helpers/location";

import "../style/train.scss";

interface TrainProps {
  location: any;
  params: { id: string };
  queryModel: string;
  navigate: any;
}

interface TrainState {
  activeModel: string;
}

class Train extends React.Component<TrainProps, TrainState> {
  constructor(props: TrainProps) {
    super(props);
    this.state = {
      activeModel: "",
    };
  }
  render() {
    return (
      <div className="row">
        <div className="col-sm-3">
          <FolderTree
            navigate={this.props.navigate}
            activeModel={(activeModel: string) =>
              this.setState({ activeModel })
            }
            currTab={this.props.location.pathname.includes("link") ? 1 : 0}
          />
        </div>
        <div className="col-sm-9 d-flex">
          <div className="train__scroll bg-white col pr-2">
            {this.props.location.pathname.includes("link") ? (
              <LinksList model={this.state.activeModel} />
            ) : (
              <Editor activeModel={this.state.activeModel} />
            )}
            <Chatbot />
          </div>
        </div>
      </div>
    );
  }
}

export default withNavigate(withLocation(withRouter(Train)));
