import React, { Component } from "react";

import { ChatContent, ChatTextArea } from "./ChatBody";
import { Card } from "antd";
import { Link } from "react-router-dom";

interface TProps {
  handleDelete: (e: any) => void;
  handleArrowButtonClick: (e: any) => void;
  handleChange: (e: any) => void;
  handleSubmit: (e: any) => void;
  isTyped?: any;
  // newResponseReceived?: any;
  setIsTyped?: any;
  query: string;
  embed: boolean;
  botname: string;
  msgArr: {
    body: any;
    align: string;
  }[];
  loading: boolean;
  msgBodyRef: React.RefObject<HTMLDivElement>;
  initialMsg?: string;
  showModalOption?: boolean;
  handleModelChange: (e: string) => void;
  modelArr: any[];
}

interface TState {}

export default class Ask extends Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
  }
  handleArrowClick = (value: any) => {
    this.props.handleArrowButtonClick(value);
    // Clear the textarea content
    this.setState({
      query: "",
    });
  };

  render() {
    const {
      handleDelete,
      handleChange,
      handleSubmit,
      query,
      msgArr,
      loading,
      msgBodyRef,
      initialMsg,
      showModalOption,
      modelArr,
      handleModelChange,
    } = this.props;

    const askCards = [
      {
        content: "How to setup automations inside Zendesk?",
        type: "story",
      },
      {
        content:
          "How to generate a pie charts of leads generated on Hubspot CRM?",
        type: "story",
      },
      {
        content:
          "What is the difference between a ticket field and customer field in Zendesk?",
        type: "analysis",
      },
      // {
      //   content: "Analyze the impact of climate change on global economies.",
      //   type: "analysis",
      // },
      // Add more cards as needed
    ];

    return (
      <>
        <div
          className="dropDownChat__msgBody mb-3 px-3"
          // style={{ height: "420px" }}
        >
          <div className="d-flex flex-column col" ref={msgBodyRef}>
            <div className="dropDownChat__msg rounded shadow py-2 px-3 mt-4 mb-4">
              <pre>
                {initialMsg ||
                  "Welcome to Neuralmind Ask, here are some popular Product FAQs you can ask our trained AI model."}
              </pre>
            </div>
            {!this.props.embed && this.props.botname !== "nav" && (
              <div className="d-flex justify-content-between ">
                <div
                  className="dropDownChat__msg1"
                  // style={{ overflowX: "unset" }}
                >
                  <pre>
                    <div
                      // bordered={false}
                      style={{
                        marginTop: "10px",
                        overflow: "hidden",
                        width: "654px",
                      }}
                      // bodyStyle={{ padding: "16px" }} // Optional: You can adjust the body padding
                    >
                      {/* Card content */}

                      {askCards.map((item, index) => (
                        <>
                          <div
                            onClick={() => this.handleArrowClick(item.content)}
                            className="d-flex main-class"
                            style={{ width: "100%", cursor: "pointer" }}
                          >
                            <div
                              style={{
                                alignItems: "center",
                                display: "flex",
                              }}
                            >
                              {" "}
                              <div
                                className="d-flex list-item"
                                style={{
                                  padding: "10px",
                                  marginLeft: "15px",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-image">
                                  {" "}
                                  <img src="/assets/images/message.svg" />{" "}
                                </div>
                                <div>
                                  <div key={index} className="card-content">
                                    {item.content}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              {" "}
                              <span className="arrow-icon">
                                <img src="/assets/images/new-arrow.svg" />
                              </span>
                            </div>
                          </div>
                        </>
                      ))}

                      {/* Footer content */}
                      <hr className="underline" />
                      <Link to="/train" className="insight-card-dataset ">
                        <div
                          style={{
                            width: "100%",
                            // padding: "10px",
                            paddingRight: "10px",
                            paddingLeft: "10px",
                            // paddingBottom: "5px",
                            paddingTop: "10px",
                            // marginTop: "10px",
                          }}
                          className="d-flex dataset1"
                          // style={{
                          //   marginLeft: "15px",
                          //   padding: "10px",
                          //   width: "100%",
                          //   borderBottom: "none",
                          // }}
                        >
                          <div
                            className="d-flex dataset-new-content"
                            style={{ paddingLeft: "15px" }}
                          >
                            {/* <Link to="/train" className="insight-card-dataset d-flex"> */}
                            <div className="d-flex ">
                              {" "}
                              <div className="card-image">
                                <img src="/assets/images/dataset.svg" />{" "}
                              </div>
                              <div className="dataset-new-content">
                                <p className="card-content">Dataset</p>
                              </div>
                            </div>
                            {/* </Link> */}
                            <div>
                              <div>
                                {" "}
                                <span
                                  className="arrow-icon1"
                                  style={{ paddingRight: "10px" }}
                                >
                                  <img src="/assets/images/blue-arrow-icon.svg" />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </pre>
                </div>
              </div>
            )}
            {/* {!this.props.embed &&
              askCards.map((card, index) => (
                <div key={index}>
                  <div className="insight-card d-flex">
                    <div
                      className="query-content"
                      onClick={() => this.handleArrowClick(card.content)}
                      style={{ cursor: "pointer" }}
                    >
                      {card.content}
                    </div>
                    <img
                      src="/assets/images/arrow.svg"
                      alt="Arrow"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.handleArrowClick(card.content)}
                    />
                  </div>
                  <br />
                </div>
              ))} */}
            {/* {!this.props.embed && (
              <Link to="/train" className="insight-card-dataset d-flex">
                <div className="dataset-content">Dataset</div>
                <img src="/assets/images/blue-arrow.svg" alt="Arrow" />
              </Link>
            )} */}

            <ChatContent
              embed={this.props.embed}
              isTyped={this.props.isTyped}
              // newResponseReceived={this.props.newResponseReceived}
              setIsTyped={this.props.setIsTyped}
              loading={loading}
              msgArr={msgArr}
              msgBodyRef={msgBodyRef}
            />
          </div>
        </div>
        <ChatTextArea
          botname={this.props.botname || ""}
          embed={this.props.embed}
          handleChange={handleChange}
          handleDelete={handleDelete}
          handleSubmit={handleSubmit}
          query={query}
          showModalOption={showModalOption}
          handleModelChange={handleModelChange}
          modelArr={modelArr}
        />
      </>
    );
  }
}
