import axios from "../helpers/axios";

export const isAdmin = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.get(
        "/api/v1/is-admin"
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};
