import EditorJS from "@editorjs/editorjs";
import { postEditor } from "../../apis/editor";
// @ts-ignore
import List from "@editorjs/list";
// @ts-ignore
import Header from "@editorjs/header";
// @ts-ignore
import Delimiter from "@editorjs/delimiter";
// @ts-ignore
import Image from "@editorjs/image";
// @ts-ignore
import CodeTool from "@editorjs/code";
// @ts-ignore
import Table from "@editorjs/table";

import axios from "axios";
import { getRESAPIEndPoint } from "../../apis/api";

const myEditor = (data: any, changeSyncStatus: Function) => {
  let _id = "";
  let timeout: any;
  const editor = new EditorJS({
    /**
     * Id of Element that should contain the Editor
     */
    data,
    holder: "editorjs",
    tools: {
      header: Header,
      list: List,
      table: Table,
      image: {
        class: Image,
        config: {
          uploader: {
            async uploadByFile(file: any) {
              let data = new FormData();
              data.append("file", file, file.name);

              const response = await axios.post(
                getRESAPIEndPoint() + "api/v1/upload-file",
                data,
                {
                  headers: {
                    accept: "application/json",
                    "Accept-Language": "en-US,en;q=0.8",
                    "Content-Type": `multipart/form-data; boundary=${file._boundary}`,
                  },
                }
              );

              return {
                success: 1,
                file: {
                  url: response.data.publicUrl, // downloadURL,
                },
              };
            },
          },
        },
      },
      // delimiter: Delimiter,
      code: CodeTool,
    },

    onChange: async (api, event) => {
      changeSyncStatus();
      clearTimeout(timeout);
      timeout = setTimeout(async () => {
        const outputData: any = await editor.save();
        console.log("out put data ", outputData)

        await postEditor({
          filename: _id,
          payload: outputData,
        });
      }, 500);

      const limit = 1800; // Word limit

      // Get the current content
      const content = await api.saver.save();
      
      // Calculate the total number of words in the editor
      let totalWords = 0;
      for (const block of content.blocks) {
        if ("text" in block.data) {
          const words = block.data.text.trim().split(/\s+/).filter(Boolean);
          totalWords += words.length;
        }
      }
      
      // Trim the text if it exceeds the limit
      if (totalWords > limit) {
        const workingBlockIndex = event.detail.index;
      
        // Create a new array of blocks with the trimmed text
        const updatedBlocks = [];
        let remainingWords = limit;
      
        for (const block of content.blocks) {
          if ("text" in block.data) {
            const words = block.data.text.trim().split(/\s+/).filter(Boolean);
            const wordCount = words.length;
            if (wordCount <= remainingWords) {
              // Add the whole block as it doesn't exceed the limit
              updatedBlocks.push(block);
              remainingWords -= wordCount;
              } else {
                // Trim the text to match the remaining word count
                const trimmedWords = words.slice(0, remainingWords);
                const trimmedText = trimmedWords.join(" ");
                updatedBlocks.push({
                  ...block,
                  data: {
                    ...block.data,
                    text: trimmedText,
                  },
                });
                break;
              }
          }
        }
      
        // Update the content with the new array of blocks
        const updatedContent = {
          ...content,
          blocks: updatedBlocks,
        };
      
        // Render the updated content in the editor
        await api.blocks.render(updatedContent);
      
        // Set the caret position to the end of the last working block
        api.caret.setToBlock(workingBlockIndex, "end");
      }
      
      return true;
      

    },
  });

  return {
    editor,
    refresh: async (newData: any, id: string) => {
      _id = id;
      await editor.blocks.render(newData.editor);
    },
    clean: async () => {
      await editor.blocks.render({
        time: 1674449797325,
        blocks: [
          {
            id: "BAgtkD0Vwr",
            type: "paragraph",
            data: { text: "Please wait a moment!" },
          },
        ],
        version: "2.26.4",
      });
    },
    save: async (id: string) => {
      try {
        // const outputData: any = await editor.save();

        // await postEditor({
        //   filename: id,
        //   payload: outputData,
        // });

        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
  };
};

export default myEditor;
