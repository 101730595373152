import React, { Component, ChangeEvent } from "react";
import { createTheme, Theme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import SalesChartTable from "./SalesChartTable";
import SalesPieChart from "./SalesPieChart";
import {
  Select,
  MenuItem,
  SelectChangeEvent,
  OutlinedInput,
} from "@mui/material";
import "../../style/sales-report.scss";
import DataBarChart from "./DataBarChart";
import DataLineChart from "./DataLineChart";

interface Props {
  handleClose: () => void;
  msg: any;
}

interface State {
  fullWidth: boolean;
  personName: string[];
  showPieChart: boolean;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["Pie chart", "Bar chart", "Line chart"];

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const theme = createTheme();

class MaxWidthDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      fullWidth: true,
      personName: [names[0]],
      showPieChart: false,
    };
  }

  handleChange = (event: SelectChangeEvent<typeof names>) => {
    try {
      const {
        target: { value },
      } = event;
      this.setState({
        personName: typeof value === "string" ? value.split(",") : value,
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleFullWidthChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ fullWidth: event.target.checked });
  };

  handleVerticalIconClick = () => {
    this.setState({
      showPieChart: true,
    });
  };

  handleStraightIconClick = () => {
    this.setState({
      showPieChart: false,
    });
  };
  render() {
    const { fullWidth, personName, showPieChart } = this.state;
    const { handleClose, msg } = this.props;
    const rowData = showPieChart ? "100%" : "calc(100% - 415px)";
    return (
      <div id="sales-model">
        <div className="main-div-image">
          <div>
            {showPieChart ? (
              <img
                src="https://app.neuralmind.io/assets/images/straight.svg"
                alt="straight"
                style={{ cursor: "pointer" }}
                onClick={this.handleStraightIconClick}
              />
            ) : (
              <img
                src="https://app.neuralmind.io/assets/images/straight-active.svg"
                alt="straight active"
                style={{ cursor: "pointer" }}
                onClick={this.handleStraightIconClick}
              />
            )}

            {showPieChart ? (
              <img
                src="https://app.neuralmind.io/assets/images/verticular-active.svg"
                alt="vertical active"
                style={{ cursor: "pointer" }}
                onClick={this.handleVerticalIconClick}
              />
            ) : (
              <img
                src="https://app.neuralmind.io/assets/images/verticular.svg"
                alt="vertical"
                style={{ cursor: "pointer" }}
                onClick={this.handleVerticalIconClick}
              />
            )}
          </div>

          {!showPieChart ? (
            <div className="pie-remove-content">
              <div>
                <Select
                  className="select-component"
                  value={personName}
                  onChange={this.handleChange}
                  input={<OutlinedInput />}
                  renderValue={(selected) => selected}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {names.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      style={getStyles(name, personName, theme)}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </div>

              {/* <div>
                <img
                  src="/assets/images/remove.svg"
                  alt="remove"
                  onClick={handleClose}
                  style={{ cursor: "pointer" }}
                />
              </div> */}
            </div>
          ) : (
            ""
            // <div>
            //   <img
            //     src="/assets/images/remove.svg"
            //     alt="remove"
            //     onClick={handleClose}
            //     style={{ cursor: "pointer" }}
            //   />
            // </div>
          )}
        </div>
        <Box
          sx={{
            display: "flex",
            flexDirection: showPieChart ? "column" : "row",
            justifyContent: "space-evenly",
            width: "100%",
            marginTop: "20px",
            gap: "20px",
            height: showPieChart ? "" : "300px",
          }}
        >
          <div
            className="table-container"
            style={{
              width: rowData,
            }}
          >
            <SalesChartTable msg={msg} />
          </div>
          {showPieChart ? (
            <div className="pie-remove-content">
              <div>
                <Select
                  className="select-component"
                  value={personName}
                  onChange={this.handleChange}
                  input={<OutlinedInput />}
                  renderValue={(selected) => selected}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {names.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      style={getStyles(name, personName, theme)}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="for-pie-chart">
            {personName.includes("Bar chart") ? (
              <DataBarChart data={msg} />
            ) : personName.includes("Line chart") ? (
              <DataLineChart data={msg} />
            ) : (
              <SalesPieChart data={msg} />
            )}
          </div>
        </Box>
      </div>
    );
  }
}

export default MaxWidthDialog;
