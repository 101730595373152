import React, { Component } from "react";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import { Button, Modal } from "react-bootstrap";
import { Button as MuiButton, Paper } from "@mui/material";
import {
  postAuthInfo,
  patchApiDocs,
  patchAuthInfo,
  postInitInsights,
  getConnectDBById,
  putAuthInfo,
  postConnectDB,
  patchConnectDB,
  patchMetaData,
  getDBById,
  patchKeywords,
  getColumnDB,
} from "../apis/connect-db";
import { Link } from "react-router-dom";
import { toastHelper } from "../helpers/toast";
import "../style/connect-db.scss";
import withRouter from "../helpers/params";
import { ModalFuncProps, Typography } from "antd";
import { Space, Switch } from "antd";
import PreviewFullEmbed from "../components/Embed/PreviewFullEmbed";
// import { Switch } from "antd";
// import CodeEditor from "../components/Connectdb/CodeEditor";
// import { AppBar, Toolbar, Typography } from "@mui/material";

interface ConnectDBProps {
  params: { id: string };
}

interface ConnectDBState {
  columnData: { column: [] };
  responseData: [{}];
  showModal: boolean;
  showDescription: boolean;
  errorMessage: string | null;
  currTab: number;
  _id: string | undefined;
  id: string | undefined;
  isSample: any;
  selectedDescription: any[];
  db_type: { label: string; value: string } | undefined;
  initialDbType: { label: string; value: string } | undefined;
  fileContent: any;
  name: string;
  host: number | undefined;
  port: number | undefined;
  database: string;
  username: string;
  password: string;
  metadata: any;
  csvData: any;
  fileName: string | undefined;
  table: { label: string; value: string }[] | undefined;
  tablesOption: { label: string; value: string }[];
  textArr: string[];
  currText: string;
  humanName: string;
  modelName: string;
  modelDescription: string;
  authType: any;
  authUrl: string;
  clientId: string;
  scope: string;
  humanDescription: string;
  description: string;
  tableDescription: any[];
  authenticationType: string | undefined;
  queryApiUrl: string;
  isDefaultOauth: boolean;
  cilentId: string;
  clientSecret: string;
  authorizationUrl: string;
  tokenUrl: string;
  userInfoUrl: string;
  successModalOpen: boolean;
  tableList: any;
  selectedOption: any;
  apiUrl: any;
  showHiddenForm: boolean;
  isActive: boolean;
  theme: string;
  code: string;
  authOptions: any;
  keywords: string[];
  selectedTableIdx: any;
  columnDescriptions: any[];
  // databaseTypeOption:any;
}

class ConnectDB extends Component<ConnectDBProps, ConnectDBState> {
  response: any;
  clientSecret: any;
  constructor(props: ConnectDBProps) {
    super(props);
    this.state = {
      columnData: { column: [] },
      responseData: [{}],
      showModal: false,
      selectedTableIdx: null,
      currTab: 0,
      selectedDescription: [],
      _id: this.props?.params?.id,
      id: this.props?.params?.id,
      isSample: false,
      db_type: undefined,
      initialDbType: undefined,
      fileContent: null,
      name: "",
      keywords: [],
      isDefaultOauth: false,
      host: undefined,
      port: undefined,
      humanName: "",
      modelName: "",
      modelDescription: "",
      authType: "",
      clientId: "",
      scope: "",
      userInfoUrl: "",
      authUrl: "",
      humanDescription: "",
      description: "",
      tableDescription: [],
      database: "",
      username: "",
      password: "",
      metadata: null,
      csvData: "",
      fileName: "",
      table: undefined,
      tablesOption: [],
      currText: "",
      textArr: [],
      authenticationType: undefined,
      queryApiUrl: "",
      cilentId: "",
      clientSecret: "",
      authorizationUrl: "",
      tokenUrl: "",
      successModalOpen: false,
      tableList: {},
      selectedOption: "database",
      apiUrl: "",
      showHiddenForm: true,
      isActive: false,
      theme: "light",
      code: "",
      showDescription: false,
      errorMessage: "",
      authOptions: [
        { value: "oauth2", label: "Oauth2" },
        // { value: "samlsso", label: "SAML SSO" },
        // { value: "autho", label: "AuthO" },
      ],
      columnDescriptions: [],
    };
  }
  databaseTypeOption = [
    // { value: "mongodb", label: "MongoDB" },
    { value: "postgres", label: "PostgreSQL" },
    // { value: "redshift", label: "Redshift" },
    // { value: "bigquery", label: "BigQuery" },
    { value: "mysql", label: "MySQL" },
    { value: "mariadb", label: "MariaDb" },
  ];
  /**this method is to handle first form submission connecting to oauth*/
  // handleStep1 = async (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   if (!this.props.params.id) {
  //     const loader = toastHelper.loading("Checking Connection");
  //     try {
  //       /** calling api for inserting OAuth */
  //       const response: any = await postAuthInfo({
  //         humanName: this.state.humanName,
  //         modelName: this.state.modelName,
  //         modelDescription: this.state.modelDescription,
  //         authType: this.state.authType.value,
  //         clientId: this.state.clientId,
  //         scope: this.state.scope,
  //         clientSecret: this.state.clientSecret,
  //         authUrl: this.state.authUrl,
  //         tokenUrl: this.state.tokenUrl,
  //         humanDescription: this.state.humanDescription,
  //       });
  //       toastHelper.success("Auth Info saved successfully!", loader);
  //       this.setState({ currTab: 3, _id: response.id });
  //     } catch (error) {
  //       toastHelper.error("Error while saving Auth Info", loader);
  //     }
  //   } else {
  //     const loader = toastHelper.loading("Checking Connection");
  //     try {
  //       let patchBody: any = {
  //         id: this.state._id,
  //         humanName: this.state.humanName,
  //         modelName: this.state.modelName,
  //         modelDescription: this.state.modelDescription,
  //         authType: this.state.authType.value,
  //         clientId: this.state.clientId,
  //         scope: this.state.scope,
  //         clientSecret: this.state.clientSecret,
  //         authUrl: this.state.authUrl,
  //         tokenUrl: this.state.tokenUrl,
  //         humanDescription: this.state.humanDescription,
  //       };

  //       /** calling patch api for updating OAuth */
  //       let response: any = await putAuthInfo(patchBody);
  //       toastHelper.success(response.message, loader);
  //       this.setState({
  //         currTab: 3,
  //         _id: response.id,
  //       });
  //     } catch (error) {
  //       toastHelper.error("Error while updating Auth Info", loader);
  //     }
  //   }
  // };

  handleStep1 = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const loader = toastHelper.loading("Checking Connection");
    try {
      /**calling api to post db connection details */
      if (this.props.params.id) {
        const response: any = await patchConnectDB({
          id: this.props.params.id,
          name: this.state.name,
          description: this.state.description,
          password: this.state.password,
          username: this.state.username,
          database: this.state.database,
          port: this.state.port,
          host: this.state.host,
          db_type: this.state.db_type?.value,
        });
        /**loader  */
        toastHelper.success("Connection Verified!", loader);
        let tables = response.metadata.tableDetails.map((item: any) => {
          return { value: item.tableName, label: item.tableName };
        });
        this.setState({
          currTab: 1,
          metadata: response.metadata,
          tablesOption: tables,
        });
      } else {
        const response: any = await postConnectDB({
          name: this.state.name,
          description: this.state.description,
          password: this.state.password,
          username: this.state.username,
          database: this.state.database,
          port: this.state.port,
          host: this.state.host,
          db_type: this.state.db_type?.value,
        });
        /**loader  */
        toastHelper.success("Connection Verified!", loader);
        let tables = response.metadata.tableDetails.map((item: any) => {
          return { value: item.tableName, label: item.tableName };
        });
        this.setState({
          currTab: 1,
          metadata: response.metadata,
          _id: response.id,
          tablesOption: tables,
        });
      }
    } catch (error) {
      toastHelper.error("Incorrect credentials. DB connection failed!", loader);
    }
  };

  // handleStep3 = async (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   if (!this.props.params.id) {
  //     const loader = toastHelper.loading(
  //       "Adding Authinfo and initiating Insight generation. This process may take a few minutes."
  //     );
  //     try {
  //       /** calling api for inserting OAuth */
  //       const response: any = await patchAuthInfo({
  //         id: this.state._id,
  //         humanName: this.state.humanName,
  //         modelName: this.state.modelName,
  //         modelDescription: this.state.modelDescription,
  //         authType: this.state.authType.value,
  //         clientId: this.state.clientId,
  //         scope: this.state.scope,
  //         clientSecret: this.state.clientSecret,
  //         authUrl: this.state.authUrl,
  //         tokenUrl: this.state.tokenUrl,
  //         humanDescription: this.state.humanDescription,
  //         isDefaultOauth: this.state.isDefaultOauth,
  //       });
  //       // try {
  //       //   const iniInsightResponse: any = await postInitInsights({
  //       //     connectDbId: this.state._id,
  //       //   });
  //       // } catch (error) {
  //       //   toastHelper.error("Error while Initiating Insight", loader);
  //       // }
  //       toastHelper.success("Auth Info saved successfully!", loader);
  //       this.setState({ currTab: 3, _id: response.id, successModalOpen: true });
  //     } catch (error) {
  //       toastHelper.error("Error while saving Auth Info", loader);
  //     }
  //   } else {
  //     const loader = toastHelper.loading(
  //       "Updating Insights Data, This process may take few minutes"
  //     );
  //     try {
  //       let patchBody: any = {
  //         id: this.state._id,
  //         humanName: this.state.humanName,
  //         modelName: this.state.modelName,
  //         modelDescription: this.state.modelDescription,
  //         authType: this.state.authType.value,
  //         clientId: this.state.clientId,
  //         scope: this.state.scope,
  //         clientSecret: this.state.clientSecret,
  //         authUrl: this.state.authUrl,
  //         tokenUrl: this.state.tokenUrl,
  //         humanDescription: this.state.humanDescription,
  //         isDefaultOauth: this.state.isDefaultOauth,
  //       };

  //       /** calling patch api for updating OAuth */
  //       let response: any = await patchAuthInfo(patchBody);
  //       try {
  //         const iniInsightResponse: any = await postInitInsights({
  //           connectDbId: this.state._id,
  //         });
  //       } catch (error) {
  //         toastHelper.error(
  //           "Error while Initiating Insight To Scheduler",
  //           loader
  //         );
  //       }
  //       toastHelper.success("Auth Info saved successfully!", loader);
  //       this.setState({
  //         currTab: 3,
  //         _id: response.id,
  //         successModalOpen: true,
  //       });
  //     } catch (error) {
  //       toastHelper.error("Error while updating Auth Info", loader);
  //     }
  //   }
  // };

  handleGetDataById = async () => {
    try {
      const { _id } = this.state;
      if (_id) {
        const { dbs }: any = await getConnectDBById(_id);
        const columnDescriptions: any = {};
        dbs[0].selectedMetadata.tableDetails.forEach((item: any) => {
          item.column.forEach((column: any) => {
            columnDescriptions[column.name] = column.description || "";
          });
        });

        this.setState({
          humanName: dbs[0].humanName,
          humanDescription: dbs[0].humanDescription,
          modelName: dbs[0].modelName,
          modelDescription: dbs[0].modelDescription,
          code: dbs[0].openApiDoc,
          authType: {
            label: dbs[0].authInfo.authType,
            value: dbs[0].authInfo.authType,
          },
          clientId: dbs[0].authInfo.clientId,
          scope: dbs[0].authInfo.scope,
          clientSecret: dbs[0].authInfo.clientSecret,
          authUrl: dbs[0].authInfo.authUrl,
          tokenUrl: dbs[0].authInfo.tokenUrl,
          name: dbs[0].dbInfo.name,
          description: dbs[0].dbInfo.description,
          showDescription: true,
          host: dbs[0].dbInfo.host,
          port: dbs[0].dbInfo.port,
          database: dbs[0].dbInfo.database,
          username: dbs[0].dbInfo.username,
          password: dbs[0].dbInfo.password,
          textArr: dbs[0].keywords,
          isDefaultOauth: dbs[0].isDefaultOauth,
          // table: {
          //   label: dbs[0].selectedMetadata.tableDetails[0].tableName,
          //   value: dbs[0].selectedMetadata.tableDetails[0].tableName,
          // },
          table: dbs[0].selectedMetadata.tableDetails.map(function (item: any) {
            return {
              label: item.tableName,
              value: item.tableName,
            };
          }),
          selectedDescription: dbs[0].selectedMetadata.tableDetails.map(
            function (item: any) {
              return {
                label: item.tableName,
                value: item.tableName,
              };
            }
          ),
          columnDescriptions,
          tableDescription: dbs[0].selectedMetadata.tableDetails.map(function (
            item: any
          ) {
            return item.description;
          }),
          db_type: {
            label: dbs[0].dbInfo.db_type,
            value: dbs[0].dbInfo.db_type,
          },
          // name:dbs[0].authInfo.name,
          showHiddenForm: false,
        });

        const newDescriptions = { ...this.state.columnDescriptions };
        for (let table of dbs[0].selectedMetadata.tableDetails) {
          for (let { name, description } of table.column) {
            if (!newDescriptions[table.tableName]) {
              newDescriptions[table.tableName] = {
                [name]: description,
              };
            } else {
              newDescriptions[table.tableName][name] = description;
            }
          }
        }
        this.setState({ columnDescriptions: newDescriptions });
      }
    } catch (error) {}
  };

  handleChange = (e: any) => {
    if (this.state.isSample) return null;
    this.setState({ [e.target.name]: e.target.value } as any);
  };

  handleDescription = (event: any, index: number) => {
    if (this.state.isSample) return null;
    const { name, value } = event.target;

    const updatedTableDescriptions = [...this.state.tableDescription];
    updatedTableDescriptions[index] = value;

    this.setState({
      tableDescription: updatedTableDescriptions,
    });
  };

  handleSelectChange = (authOptions: any) => {
    this.setState({ authType: authOptions });
  };

  handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (this.state.isSample) return null;
    e.target.style.height = "100px";
    e.target.style.height = e.target.scrollHeight + "px";
    this.setState({ currText: e.target.value });
  };

  handleTextSubmit = (e: any) => {
    const keyCode = e.which || e.keyCode;

    if (keyCode === 13 && !e.shiftKey) {
      e.preventDefault();

      let tmpArr = Array.from(this.state.textArr);
      tmpArr.push(this.state.currText.trim());

      e.target.value = "";
      this.handleTextChange(e);

      this.setState({ textArr: tmpArr });
    }
  };

  handleContinueClick = (event: any) => {
    event.preventDefault();
    const { currTab } = this.state;
    if (currTab < 1) {
      this.setState({ currTab: currTab + 1 });
    }
  };

  handleRemoveText = (i: number) => {
    let tmpArr = Array.from(this.state.textArr);
    tmpArr.splice(i, 1);
    this.setState({ textArr: tmpArr });
  };

  handleTableToggle = (key: string, type: string, newValue: boolean) => {
    let tmp = { ...this.state.tableList };
    tmp[key][type] = newValue;
    this.setState({ tableList: tmp });
  };

  handleTableDesc = (key: string, i: number, newValue: string) => {
    let tmp = { ...this.state.tableList };
    tmp[key].column[i]["Description"] = newValue;
    this.setState({ tableList: tmp });
  };

  handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    try {
      const reader = new FileReader();
      this.setState({ fileName: e.target.files![0].name });
      reader.readAsText(e.target.files![0]);
      reader.onload = (e) => {
        const text: string = e.target?.result as string;
        let dataArray: string[] = text.split("\r\n");
        let databaseName = dataArray[0];
        let allTables = dataArray[1].split(",");
        let allColumns = dataArray[2].split(",");

        let metadata = {
          databaseName: databaseName,
          tableDetails: [] as any[],
        };

        for (let table in allTables) {
          metadata.tableDetails.push({
            tableName: allTables[table],
            column: allColumns[table].split(";"),
          });
        }
        this.setState({ csvData: metadata });
      };
    } catch (error) {}
  };

  // handleStep2 = async (e: { preventDefault: () => void }) => {
  //   e.preventDefault();
  //   const loader = toastHelper.loading("Saving Metadata");
  //   // this.setState({
  //   //   successModalOpen: true,
  //   // });
  //   try {
  //     /**making patch metadata api call */
  //     await patchApiDocs({
  //       id: this.state._id ? this.state._id : this.props.params.id,
  //       openApiDoc: this.state.code,
  //     });
  //     toastHelper.success("Updated query API Url successfully", loader);
  //     this.setState({ currTab: 2 });
  //   } catch (error: any) {
  //     toastHelper.error(error.message, loader);
  //   }
  // };

  openModal = (item: any) => {
    // tableName
    // item.value.
    const idx = this.state.metadata?.tableDetails?.findIndex(
      (table: any) => table.tableName === item.value
    );

    this.setState({ showModal: true });
    this.setState({ selectedTableIdx: idx || 0 });
  };

  // Function to close the modal
  closeModal = () => {
    this.setState({ showModal: false });
  };

  handleTableChange = (selectedTables: any) => {
    // Check if the user is trying to select more than 5 tables
    if (selectedTables.length > 5) {
      this.setState({ errorMessage: "You can select up to 5 tables." });
      return;
    } else {
      // If the selection is valid, update the state
      this.setState({
        table: selectedTables,
        selectedDescription: selectedTables,
        showDescription: selectedTables.length !== 0,
        errorMessage: null,
      });

      let existingColumnDescription: any = Object.assign(
        {},
        this.state.columnDescriptions
      );
      let allPreviouslySelectedTables = Object.keys(existingColumnDescription);
      for (let { value } of selectedTables) {
        if (!allPreviouslySelectedTables.find((item: any) => item === value)) {
          existingColumnDescription[value] = {};
        }
      }

      this.setState({ columnDescriptions: existingColumnDescription });
    }
  };

  handleStep4 = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const loader = toastHelper.loading("Saving Metadata");
    try {
      /** getting selected tables */
      let selectedTables = this.state.table?.map((item: any) => item.value);

      /** forming metadata based on selected tables */
      let metadata: any = {
        databaseName: this.state.database,

        tableDetails: selectedTables?.map((item: any, index: number) => {
          let columnData: any = this.state.metadata.tableDetails.find(
            (table: any) => {
              return table.tableName === item;
            }
          );
          return {
            tableName: item,
            description: this.state.tableDescription[index] || "",
            column: columnData
              ? columnData.column.map((column: any) => ({
                  name: column,
                  description:
                    this.state.columnDescriptions[item][column] || "", // Include the description here
                }))
              : [],
          };
        }),
      };

      let metadataPatchBody: any = {
        id: this.state._id,
        metadata,
      };

      /** making patch metadata api call */
      await patchMetaData(metadataPatchBody);
      toastHelper.success("Saved Table details successfully!", loader);
      this.setState({ currTab: 2 });
    } catch (error: any) {
      toastHelper.error(error.message, loader);
    }
  };

  handleStep5 = async () => {
    const loader = toastHelper.loading(
      "Adding insight Query and initiating Insight generation. This process may take a few minutes."
    );
    try {
      await patchKeywords({
        id: this.state._id,
        keywords: this.state.textArr,
      });
      try {
        const iniInsightResponse: any = await postInitInsights({
          connectDbId: this.state._id,
        });
      } catch (error) {
        toastHelper.error("Error while Initiating Insight", loader);
      }
      toastHelper.success("Keywords Added!", loader);
      this.setState({ currTab: 2, successModalOpen: true });
    } catch (error) {
      toastHelper.error("Error: Adding keyword", loader);
    }

    // const loader = toastHelper.loading("Adding Keyword");
    // try {
    //   await patchKeywords({
    //     id: this.state._id,
    //     keywords: this.state.textArr,
    //   });

    //   toastHelper.success("Keywords Added!", loader);
    //   this.setState({ currTab: 5 });
    //   this.setState({
    //     successModalOpen: true,
    //   });
    // } catch (error) {
    //   toastHelper.error("Error: Adding keyword", loader);
    // }
  };

  handleCodeChange = (code: string) => {
    // Update the code in state when it changes
    this.setState({
      code: code,
    });
  };

  handleBack = () => {
    this.setState({ currTab: 0 });
  };

  componentDidMount(): void {
    this.handleGetDataById();
    if (this.state.isDefaultOauth == false) {
      this.setState({
        authType: "",
        clientId: "",
        scope: "",
        clientSecret: "",
        authUrl: "",
        tokenUrl: "",
      });
    }
    const queryParams = new URLSearchParams(window.location.search);
    this.setState({
      isSample:
        queryParams.get("is_sample") == "false" ||
        queryParams.get("is_sample") == null
          ? false
          : true,
    });
  }
  componentDidUpdate(
    prevProps: Readonly<ConnectDBProps>,
    prevState: Readonly<ConnectDBState>,
    snapshot?: any
  ): void {
    if (prevState.isDefaultOauth !== this.state.isDefaultOauth) {
      this.setState({
        authType: "",
        clientId: "",
        scope: "",
        clientSecret: "",
        authUrl: "",
        tokenUrl: "",
      });
    }
  }

  handleColumnDescriptionChange(table: any, column: any, event: any) {
    const newDescriptions = { ...this.state.columnDescriptions };
    newDescriptions[table][column] = event.target.value;
    this.setState({ columnDescriptions: newDescriptions });
  }

  getOauthFields = () => {
    if (this.state.isDefaultOauth) {
      return [
        {
          label: "Authentication Type",
          value: {
            value: this.state.authType,
            label: "*********",
          },
          name: "authType",
          isDisabled: true,
          options: this.state.authOptions,
          isSelect: true,
        },
        {
          label: "Client ID",
          value: "***************",
          name: "clientId",
        },
        {
          label: "Client Secret Key",
          value: "***************",
          name: "clientSecret",
          type: "text",
        },
        {
          label: "Authorization URL",
          value: "***************",
          name: "authUrl",
        },
        {
          label: "Token URL (Optional)",
          value: "***************",
          name: "tokenUrl",
          type: "text",
        },
        {
          label: "Scope (Optional)",
          value: "***************",
          name: "scope",
        },
      ];
    } else {
      return [
        // {
        //   label: "Name for Human",
        //   value: this.state.humanName,
        //   name: "humanName",
        // },
        // {
        //   label: "Name for Model",
        //   value: this.state.modelName,
        //   name: "modelName",
        // },
        // {
        //   label: "Description for Human",
        //   value: this.state.humanDescription,
        //   name: "humanDescription",
        // },
        // {
        //   label: "Description for Model",
        //   value: this.state.modelDescription,
        //   name: "modelDescription",
        // },
        {
          label: "Authentication Type",
          value: this.state.authType,
          name: "authType",
          options: this.state.authOptions,
          isDisabled: !!this.state.isSample,
          isSelect: true,
        },
        {
          label: "Client ID",
          value: this.state.clientId,
          name: "clientId",
        },
        {
          label: "Client Secret Key",
          value: this.state.clientSecret,
          name: "clientSecret",
          type: "text",
        },
        {
          label: "Authorization URL",
          value: this.state.authUrl,
          name: "authUrl",
        },
        {
          label: "Token URL (Optional)",
          value: this.state.tokenUrl || "",
          name: "tokenUrl",
          type: "text",
        },
        {
          label: "Scope (Optional)",
          value: this.state.scope || "",
          name: "scope",
        },
      ];
    }
  };

  render() {
    const { showHiddenForm, selectedOption, _id } = this.state;
    const blueColor = "blue";
    return (
      <div className="bg-white h-100 p-3">
        <div className="text-end mb-3">
          <Link to={"/existingdb"}>
            <Button className="btn-blue ">Existing Connections</Button>
          </Link>
        </div>
        <div
          // style={{ marginTop: "20px" }}
          className="d-flex px-2 align-items-center mb-3"
        >
          {/* &nbsp;&nbsp; */}
          {/* <img src="/assets/images/Line.svg" /> */}
          {/* <div className="db__divider">&nbsp;</div> */}
          {/* &nbsp;&nbsp; */}
          {/* <div
            style={{ marginLeft: "2px" }}
            className="d-flex align-items-center connectdb-step-2"
          >
            <p
              className={`db__number mb-0 ${
                this.state.currTab == 1 ? "db__numberActive" : ""
              }`}
            >
              2
            </p>
            <h5 className="mb-0 ms-2" style={{ color: "#1030B2" }}>
              API Library
            </h5>
          </div> */}
          <div
            style={{ marginLeft: "2px" }}
            className="d-flex align-items-center connectdb-step-1"
          >
            <p
              className={`db__number mb-0 ${
                this.state.currTab == 0 ? "db__numberActive" : ""
              }`}
            >
              1
            </p>
            {/* <img src="/assets/images/Ellipse 2.svg" /> */}
            <h5 className="mb-0 ms-2" style={{ color: "#1030B2" }}>
              Database Details
            </h5>
          </div>
          &nbsp;&nbsp;
          {/* <div className="db__divider">&nbsp;</div> */}
          <img src="/assets/images/Line.svg" />
          &nbsp;&nbsp;
          <div
            style={{ marginLeft: "2px" }}
            className="d-flex align-items-center connectdb-step-2"
          >
            <p
              className={`db__number mb-0 ${
                this.state.currTab == 1 ? "db__numberActive" : ""
              }`}
            >
              2
            </p>
            {/* <img src="/assets/images/Ellipse 2.svg" /> */}
            <h5 className="mb-0 ms-2" style={{ color: "#1030B2" }}>
              Table Details
            </h5>
          </div>
          &nbsp;&nbsp;
          {/* <div className="db__divider">&nbsp;</div> */}
          <img src="/assets/images/Line.svg" />
          &nbsp;&nbsp;
          <div
            style={{ marginLeft: "2px" }}
            className="d-flex align-items-center connectdb-step-3"
          >
            <p
              className={`db__number mb-0 ${
                this.state.currTab == 2 ? "db__numberActive" : ""
              }`}
            >
              3
            </p>
            {/* <img src="/assets/images/Ellipse 2.svg" /> */}
            <h5 className="mb-0 ms-2 " style={{ color: "#1030B2" }}>
              Insights Query
            </h5>
          </div>
          &nbsp;&nbsp;
          {/* <div className="db__divider">&nbsp;</div> */}
          {/* <img src="/assets/images/Line.svg" /> */}
          {/* &nbsp;&nbsp; */}
          {/* <div
            style={{ marginLeft: "10px" }}
            className="d-flex align-items-center connectdb-step-4 ml-15"
          > */}
          {/* <p
              className={`db__number mb-0 ${
                this.state.currTab == 3 ? "db__numberActive" : ""
              }`}
            >
              4
            </p> */}
          {/* <img src="/assets/images/Ellipse 1.svg" /> */}
          {/* <h5 className="mb-0 ms-2" style={{ color: "#1030B2" }}>
              OAuth
            </h5> */}
          {/* </div> */}
          {/* <div className="" style={{ marginLeft: "auto" }}>
            <Link to={"/existingdb"}>
              <Button className="btn-black">Existing Connections</Button>
            </Link>
          </div> */}
        </div>

        <div className="db__scroll px-3">
          {/* {this.state.currTab == 1 && (
                <>
                  <div className="disclaimer">
                    <div className="fw-bold d-flex align-items-center gap-2 ">
                      <img
                        src="assets/images/info-icon.svg"
                        width={24}
                        height={24}
                      />{" "}
                      Disclaimer
                    </div>
                    <div style={{ color: "#616585" }}>
                      Users can use natural language to execute requests through the
                      APIs integrated here. Be careful not to include any sensitive
                      API calls in this section. Additionally, adhere to the
                      following format in the Open API specification. For further
                      details, click on this link:{" "}
                      <a
                        target="_blank"
                        href="https://docs.neuralmind.io/connect-database/insights"
                      >
                        here
                      </a>
                      .{" "}
                    </div>
                  </div>
                  <Paper elevation={6}>
                    <div
                      style={{
                        borderRadius: "8px 8px 0px 0px",
                        background:
                          this.state.theme === "dark" ? "#000" : " #FAFBFF",
                      }}
                    >
                      <Toolbar style={{ height: 1 }}>
                        <Typography
                          variant="subtitle1"
                          color={this.state.theme == "dark" ? "white" : "black"}
                        >
                          Add API Library
                        </Typography>
                        <div style={{ flexGrow: 1 }} />
                        <Typography
                          variant="subtitle1"
                          color={this.state.theme == "dark" ? "white" : "black"}
                          mr={2}
                        >
                          Dark Mode
                        </Typography>
                        <Switch
                          checked={this.state.theme === "dark" ? true : false}
                          onChange={() => {
                            this.setState({
                              theme: this.state.theme == "dark" ? "light" : "dark",
                            });
                          }}
                        />
                      </Toolbar>
                    </div>
    
                    <CodeEditor
                      theme={this.state.theme}
                      code={this.state.code}
                      onChange={this.handleCodeChange}
                    />
                    <div
                      style={{
                        borderRadius: "0px 0px 8px 8px",
                        background:
                          this.state.theme === "dark" ? "#000" : " #FAFBFF",
                      }}
                    >
                      <Toolbar style={{ height: 1 }}>
                        <MuiButton
                          variant={
                            this.state.theme == "dark" ? "contained" : "outlined"
                          }
                          style={{
                            textTransform: "capitalize",
                            background: "white",
                            color: "#1030B2",
                            marginRight: "5px",
                            fontWeight: "bold",
                          }}
                          onClick={this.handleStep2}
                        >
                          Submit
                        </MuiButton>
                        <MuiButton
                          variant="text"
                          style={{
                            color: this.state.theme == "dark" ? "white" : "#1030B2",
                            fontWeight: "bold",
                            textTransform: "capitalize",
                          }}
                          onClick={() => this.setState({ code: "" })}
                        >
                          clear
                        </MuiButton>
                      </Toolbar>
                    </div>
                  </Paper>
                  <div className="info-container mt-3">
                    <img src="/assets/images/blue-info-icon.svg" className="me-2" />
                    <p className="info-text ">
                      Please keep your API key safe and do not share publicly.
                    </p>
                  </div>
                  <br />
                  <br />
                  <MuiButton
                    variant={this.state.theme == "dark" ? "contained" : "outlined"}
                    style={{
                      textTransform: "capitalize",
                      background: "white",
                      color: "#1030B2",
                      marginRight: "5px",
                      fontWeight: "bold",
                      borderColor: "rgb(16, 48, 178)",
                    }}
                    onClick={this.handleBack}
                  >
                    <img src="/assets/images/Back.svg" /> Back
                  </MuiButton>
                </>
              )} */}

          {this.state.currTab === 0 && (
            <div className="col-5">
              <div className="disclaimer">
                <div className="fw-bold d-flex align-items-center gap-2 ">
                  <img
                    src="/assets/images/info-icon.svg"
                    width={24}
                    height={24}
                  />{" "}
                  Disclaimer
                </div>
                <div style={{ color: "#616585" }}>
                  We only execute read queries or use our data warehouse for
                  analytics; we don't modify, alter, or update your database.
                  {/* {" "}
                  <a
                    target="_blank"
                    href="https://docs.neuralmind.io/connect-database/insights"
                  >
                    here
                  </a>
                  .{" "} */}
                </div>
              </div>
              <Form onSubmit={this.handleStep1}>
                {[
                  ...(this.state.isSample
                    ? [
                        {
                          label: "Name",
                          value: this.state.name,
                          name: "name",
                          type: "text",
                          placeholder: "Name your connection",
                          required: true,
                        },
                        {
                          label: "Description",
                          value: this.state.description,
                          name: "description",
                          as: "textarea",
                          placeholder: "Describe your connection.",
                          style: { height: "114px" },
                          required: true,
                        },
                        {
                          label: "Database Type",
                          value: this.state.db_type,
                          name: "db_type",
                          isSelect: true,
                          placeholder: "Select Database Type",
                          options: this.databaseTypeOption,
                          isDisabled: !showHiddenForm,
                          onChange: (e: any) => {
                            this.setState({ db_type: e });
                          },
                        },
                        {
                          label: "Host",
                          value: "***********",
                          name: "host",
                          type: "text",
                          placeholder: "Enter Host info",
                          required: true,
                        },
                        {
                          label: "Port",
                          value: "***********",
                          name: "port",
                          type: "text",
                          placeholder: "Enter Port number",
                        },
                        {
                          label: "Database Name",
                          value: "***********",
                          name: "database",
                          type: "text",
                          placeholder: "Enter Database name",
                          required: true,
                        },
                        {
                          label: "Username",
                          value: "***********",
                          name: "username",
                          type: "text",
                          placeholder: "Enter Username",
                          required: true,
                        },
                        {
                          label: "Password",
                          value: "***********",
                          name: "password",
                          type: "text",
                          placeholder: "Enter Password",
                          required: true,
                        },
                      ]
                    : [
                        {
                          label: "Name",
                          value: this.state.name,
                          name: "name",
                          type: "text",
                          placeholder: "Name your connection",
                          required: true,
                        },
                        {
                          label: "Description",
                          value: this.state.description,
                          name: "description",
                          as: "textarea",
                          placeholder: "Describe your connection.",
                          style: { height: "114px" },
                          required: true,
                        },
                        {
                          label: "Database Type",
                          value: this.state.db_type,
                          name: "db_type",
                          isSelect: true,
                          placeholder: "Select Database Type",
                          options: this.databaseTypeOption,
                          isDisabled: !showHiddenForm,
                          onChange: (e: any) => {
                            this.setState({ db_type: e });
                          },
                        },
                        {
                          label: "Host",
                          value: this.state.host,
                          name: "host",
                          type: "text",
                          placeholder: "Enter Host info",
                          required: true,
                        },
                        {
                          label: "Port",
                          value: this.state.port,
                          name: "port",
                          type: "text",
                          placeholder: "Enter Port number",
                        },
                        {
                          label: "Database Name",
                          value: this.state.database,
                          name: "database",
                          type: "text",
                          placeholder: "Enter Database name",
                          required: true,
                        },
                        {
                          label: "Username",
                          value: this.state.username,
                          name: "username",
                          type: "text",
                          placeholder: "Enter Username",
                          required: true,
                        },
                        {
                          label: "Password",
                          value: this.state.password,
                          name: "password",
                          type: "password",
                          placeholder: "Enter Password",
                          required: true,
                        },
                      ]),
                ].map((field, index) => (
                  <Form.Group key={index} className="my-3">
                    <Form.Label>{field.label}</Form.Label>
                    {field.isSelect ? (
                      <Select
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        isDisabled={field.isDisabled}
                        required={field.required}
                        options={field.options}
                      />
                    ) : (
                      <Form.Control
                        name={field.name}
                        value={field.value as string}
                        onChange={this.handleChange}
                        type={field.type || "text"}
                        placeholder={field.placeholder}
                        as={field.as as any}
                        style={field.style}
                        required={field.required}
                      />
                    )}
                  </Form.Group>
                ))}
                {this.state.isSample ? (
                  <Button
                    className="continue-button"
                    onClick={() =>
                      this.setState({ currTab: this.state.currTab + 1 })
                    }
                  >
                    Next
                  </Button>
                ) : (
                  <Button className="continue-button" type="submit">
                    Continue
                  </Button>
                )}
              </Form>
            </div>
          )}

          {this.state.currTab == 1 && (
            <div className="col-5">
              <Form onSubmit={this.handleStep4}>
                <Form.Group className="mb-5">
                  <Form.Label>Select Tables and connect metadata</Form.Label>
                  <Select
                    placeholder="Select Tables"
                    isMulti
                    options={this.state.tablesOption}
                    isDisabled={!!this.state.isSample}
                    value={this.state.table}
                    onChange={this.handleTableChange}
                  />

                  {/* Display the error message below the Select */}
                  {this.state.errorMessage && (
                    <div className="error-message">
                      {this.state.errorMessage}
                    </div>
                  )}
                </Form.Group>
                <div>
                  {Object.keys(this.state.tableList).map((key, i) => {
                    const curr = this.state.tableList[key];
                    return (
                      <div className="pb-3 mb-4 db__tableCol" key={i}>
                        <div className="d-flex justify-content-between align-items-center">
                          <h4>{curr.label}</h4>
                          <div className="d-flex align-items-center">
                            <Form.Group className="d-flex align-items-center me-4">
                              <Form.Switch
                                defaultChecked={curr.agent}
                                onChange={(e) =>
                                  this.handleTableToggle(
                                    key,
                                    "agent",
                                    e.target.checked
                                  )
                                }
                              />
                              <Form.Label className="mb-0">Agent</Form.Label>
                            </Form.Group>
                            <Form.Group className="d-flex align-items-center">
                              <Form.Switch
                                defaultChecked={curr.admin}
                                onChange={(e) =>
                                  this.handleTableToggle(
                                    key,
                                    "admin",
                                    e.target.checked
                                  )
                                }
                              />
                              <Form.Label className="mb-0">Admin</Form.Label>
                            </Form.Group>
                          </div>
                        </div>
                        <div className="my-2">
                          {curr.column.map((val: any, i: number) => (
                            <Form.Group className="mb-3" key={i}>
                              <Form.Label>{val.Field}</Form.Label>
                              <Form.Control
                                as="textarea"
                                placeholder="Description"
                                value={val.description}
                                onChange={(e) =>
                                  this.handleTableDesc(key, i, e.target.value)
                                }
                              />
                            </Form.Group>
                          ))}
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div>
                  {this.state.showDescription && (
                    <>
                      {this.state.selectedDescription.map((item, index) => {
                        return (
                          <>
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              {" "}
                              <div>
                                <span>{`Describe table ${item.label}`}</span>
                              </div>
                              <div>
                                <a
                                  onClick={() => this.openModal(item)}
                                  style={{ cursor: "pointer" }}
                                  className="view-button"
                                >
                                  Add Column Description
                                </a>
                              </div>
                            </div>

                            <textarea
                              className="db__textarea rounded"
                              placeholder={`Describe table ${item.label}`}
                              value={this.state.tableDescription[index] || ""}
                              name={`tableDescription-${index}`}
                              onChange={(event) =>
                                this.handleDescription(event, index)
                              }
                              // style={{ height: "114px" }}
                              required
                            />
                          </>
                        );
                      })}
                    </>
                  )}
                </div>
                {this.state.isSample ? (
                  <div className="d-flex align-items-end" style={{ gap: 10 }}>
                    <Button
                      className="btn-container btn-outline"
                      onClick={() =>
                        this.setState({ currTab: this.state.currTab - 1 })
                      }
                    >
                      Prev
                    </Button>
                    <Button
                      className="continue-button"
                      onClick={() =>
                        this.setState({ currTab: this.state.currTab + 1 })
                      }
                    >
                      Next
                    </Button>
                  </div>
                ) : (
                  <Button className="continue-button" type="submit">
                    Continue
                  </Button>
                )}
              </Form>
            </div>
          )}

          {this.state.currTab == 2 && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <div style={{ width: "50%" }}>
                <p className="mb-4">
                  Define the insights you want to provide to your customers out
                  of the box. These insights will be visible in the left panel
                  as shown in the screenshot.
                </p>
                {this.state.textArr.map((val: string, i: number) => (
                  <div className="db__pre d-flex justify-content-between mb-2 p-2 rounded">
                    <div className="d-flex">
                      <span className="me-2">{i + 1}.</span>
                      <pre>{val}</pre>
                    </div>
                    <img
                      onClick={() => this.handleRemoveText(i)}
                      src="/assets/images/close-icon.svg"
                      width="15"
                      height="15"
                      role="button"
                    />
                  </div>
                ))}
                <div className="text-center mt-3">
                  <textarea
                    className="db__textarea rounded"
                    placeholder="Define your business terms and press enter to add them. User results with these keywords will be produced based on how you define them here."
                    value={this.state.currText}
                    onKeyDown={this.handleTextSubmit}
                    onChange={this.handleTextChange}
                    name="keywords"
                  />
                </div>
                {this.state.isSample ? (
                  // <div className="d-flex align-items-end" style={{ gap: 10 }}>
                  //   <Button
                  //     className="btn-container btn-outline"
                  //     onClick={() =>
                  //       this.setState({ currTab: this.state.currTab - 1 })
                  //     }
                  //   >
                  //     Prev
                  //   </Button>
                  //   <Button
                  //     className="continue-button"
                  //     onClick={() =>
                  //       this.setState({ currTab: this.state.currTab + 1 })
                  //     }
                  //   >
                  //     Next
                  //   </Button>
                  //   <Button
                  //     className="continue-button"
                  //     onClick={() => (window.location.href = "/existingdb")}
                  //   >
                  //     Finish
                  //   </Button>
                  // </div>
                  <div className="d-flex align-items-end" style={{ gap: 10 }}>
                    <Button
                      className="btn-container btn-outline"
                      onClick={() =>
                        this.setState({ currTab: this.state.currTab - 1 })
                      }
                    >
                      Prev
                    </Button>
                    <Button
                      className="continue-button"
                      onClick={() => (window.location.href = "/existingdb")}
                    >
                      Finish
                    </Button>
                  </div>
                ) : (
                  <Button
                    className="continue-button"
                    type="submit"
                    onClick={this.handleStep5}
                  >
                    Continue
                  </Button>
                )}
              </div>
              <div style={{ width: "40%" }}>
                <PreviewFullEmbed />
              </div>
            </div>
          )}

          {/* {this.state.currTab === 3 && (
            <div className="col-5">
              <Typography.Title level={5}>
                Use Default Credentials
              </Typography.Title>
              <Switch
                checked={this.state.isDefaultOauth}
                onChange={(e) => this.setState({ isDefaultOauth: e })}
              />
              <Form onSubmit={this.handleStep3}>
                {this.getOauthFields()?.map((field, index) => (
                  <Form.Group key={index} className="my-3">
                    <Form.Label>
                      {field.label}
                      {field.label.includes("*") && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                    </Form.Label>
                    {field.isSelect ? (
                      <Select
                        name={field.name}
                        value={field.value}
                        options={field.options}
                        isDisabled={field.isDisabled}
                        onChange={this.handleSelectChange}
                        required={field.label.includes("*")}
                      />
                    ) : (
                      <Form.Control
                        placeholder={`Enter ${field.label}`}
                        name={field.name}
                        value={field.value}
                        onChange={this.handleChange}
                        type={field.type || "text"}
                        required={field.label.includes("*")}
                      />
                    )}
                  </Form.Group>
                ))}
                {this.state.isSample ? (
                  <div className="d-flex align-items-end" style={{ gap: 10 }}>
                    <Button
                      className="btn-container btn-outline"
                      onClick={() =>
                        this.setState({ currTab: this.state.currTab - 1 })
                      }
                    >
                      Prev
                    </Button>
                    <Button
                      className="continue-button"
                      onClick={() => (window.location.href = "/existingdb")}
                    >
                      Finish
                    </Button>
                  </div>
                ) : (
                  <Button className="continue-button" type="submit">
                    Continue
                  </Button>
                )}
              </Form>
              <div className="info-container mt-3">
                <img src="/assets/images/blue-info-icon.svg" className="me-2" />
                <p className="info-text ">
                  Note : To integrate OAuth smoothly, insert your Callback URL
                  in the OAuth section. This ensures secure communication
                  between our platform and OAuth service, enhancing your
                  experience. Callback URL:-
                  https://api.neuralmind.io/api/v1/insights/auth/callback
                </p>
              </div>
            </div>
          )} */}
        </div>

        <Modal
          show={this.state.successModalOpen}
          onHide={() => this.setState({ successModalOpen: false })}
        >
          <Modal.Header closeButton className="border-0" />
          <Modal.Body className="p-5">
            <h3 className="fw-bold text-center">
              Successfully added connect DB, head to the{" "}
              <Link to="/chat">Chat</Link> to test your connection
            </h3>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-end border-0">
            <Button
              className="btn-black"
              onClick={() => this.setState({ successModalOpen: false })}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Form
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Form.Group className="mb-3" controlId="column">
              <Form.Label>
                {this.state.metadata?.tableDetails.map(
                  (table: any, index: number) => (
                    <span key={table.tableName}>
                      {index == this.state.selectedTableIdx &&
                        table.column.map((column: any, index: number) => (
                          <>
                            <span key={index}>{column} </span>
                            <Form.Control
                              style={{ width: "455px", marginBottom: "10px" }}
                              type="text"
                              placeholder="Enter column description"
                              value={
                                this.state.columnDescriptions[table.tableName][
                                  column
                                ] || ""
                              }
                              onChange={(e) =>
                                this.handleColumnDescriptionChange(
                                  table.tableName,
                                  column,
                                  e
                                )
                              }
                            />
                          </>
                        ))}
                    </span>
                  )
                )}
              </Form.Label>
            </Form.Group>
          </Form>

          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              Close
            </Button>
            <Button variant="primary" onClick={this.closeModal}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default withRouter(ConnectDB);
function verifyConnection(arg0: {
  humanName: string;
  modelName: string;
  modelDescription: string;
  authType: any;
  clientId: string;
  scope: string;
  clientSecret: string;
  authUrl: string;
  tokenUrl: string;
  humanDescription: string;
}): any {
  throw new Error("Function not implemented.");
}
