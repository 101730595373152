import { stringify } from "himalaya";

export const getBlock = (children: any) => {
  switch (children.tagName) {
    case "h1":
      const h1block = {
        id: Math.floor(Math.random() * 100000) + 1,
        type: "header",
        data: {
          text: children.children[0].content,
        },
      };
      return h1block;

    case "h2":
      const h2block = {
        id: Math.floor(Math.random() * 100000) + 1,
        type: "header",
        data: {
          text: children.children[0].content,
        },
      };
      return h2block;

    case "h3":
      const h3block = {
        id: Math.floor(Math.random() * 100000) + 1,
        type: "header",
        data: {
          text: children.children[0].content,
        },
      };
      return h3block;

    case "h4":
      const h4block = {
        id: Math.floor(Math.random() * 100000) + 1,
        type: "header",
        data: {
          text: children.children[0].content,
        },
      };
      return h4block;

    case "h5":
      const h5block = {
        id: Math.floor(Math.random() * 100000) + 1,
        type: "header",
        data: {
          text: children.children[0].content,
        },
      };
      return h5block;

    case "h6":
      const h6block = {
        id: Math.floor(Math.random() * 100000) + 1,
        type: "header",
        data: {
          text: children.children[0].content,
        },
      };
      return h6block;

    case "p":
      const pblock = {
        id: Math.floor(Math.random() * 100000) + 1,
        type: "paragraph",
        data: {
          text: children.children[0].content,
        },
      };
      return pblock;

    case "ul":
      var itemList = [];

      for (var i = 0; i < children.children.length; i++) {
        if (
          children.children[i].children !== undefined &&
          children.children[i].children[0].content !== undefined &&
          children.children[i].children[0].content !== null
        ) {
          console.log(typeof children.children[i].children[0].content);
          itemList.push(children.children[i].children[0].content);
        }
      }
      const ulblock = {
        id: Math.floor(Math.random() * 100000) + 1,
        type: "list",
        data: {
          style: "unordered",
          items: itemList,
        },
      };
      return ulblock;

    case "ol":
      var itemList = [];
      for (var i = 0; i < children.children.length; i++) {
        if (children.children[i].children !== undefined) {
          itemList.push(children.children[i].children[0].content);
        }
      }
      const olblock = {
        id: Math.floor(Math.random() * 100000) + 1,
        type: "list",
        data: {
          style: "ordered",
          items: itemList,
        },
      };
      return olblock;

    case "img":
      const imgblock = {
        id: Math.floor(Math.random() * 100000) + 1,
        type: "image",
        data: {
          file: {
            url: children.attributes[0].value,
          },
        },
      };
      return imgblock;

    case "table":
      const table = [];
      var isHeading = false;

      for (var i = 0; i < children.children.length; i++) {
        if (children.children[i].children === undefined) continue;
        if (children.children[i].tagName === "thead") {
          isHeading = true;
        }
        const row = [];
        for (var j = 0; j < children.children[i].children.length; j++) {
          if (children.children[i].children[j].children === undefined) continue;
          row.push(children.children[i].children[j].children[0].content);
        }
        table.push(row);
      }

      const tableblock = {
        id: Math.floor(Math.random() * 100000) + 1,
        type: "table",
        data: {
          withHeadings: isHeading,
          content: table,
        },
      };
      return tableblock;

    case "code":
      const codeblock = {
        id: Math.floor(Math.random() * 100000) + 1,
        type: "code",
        data: {
          code: stringify(children.children),
        },
      };
      return codeblock;

    case "strong":
      const strongblock = {
        id: Math.floor(Math.random() * 100000) + 1,
        type: "paragraph",
        data: {
          text: children.children[0].content,
        },
      };
      return strongblock;

    case "text":
      const text = {
        id: Math.floor(Math.random() * 100000) + 1,
        type: "paragraph",
        data: {
          text: children.content,
        },
      };
      return text;
    case "a":
      const ablock = {
        id: Math.floor(Math.random() * 100000) + 1,
        type: "paragraph",
        data: {
          text:
            children.children.length > 0 ? children.children[0].content : "",
        },
      };
      return ablock;

    default:
      return {
        id: Math.floor(Math.random() * 100000) + 1,
        type: "paragraph",
        data: {
          text: "",
        },
      };
  }
};
