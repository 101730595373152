import axios from "../helpers/axios";
import { getCookie } from "../helpers/cookie";

export const getDbAPIs = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.get(
        "/api/v1/db-schema"
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const postDbAPI = async (api: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.post(
        "/api/v1/db-schema",
        api
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteDbAPI = async (api: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.delete(
        "/api/v1/db-schema/" + api._id
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};
