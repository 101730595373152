import React, { Component } from "react";
import { Layout, Tabs } from "antd";
import InsightsChat from "../DropDownChat/insights";
import AskChat from "../DropDownChat/Ask";
import { getAction, getLength, getTone } from "../../helpers/tools-selector";
import "../../style/dropDownChat.scss";
import TabPane from "antd/es/tabs/TabPane";
import axiosNode from "../../helpers/funcs/axiosNode";
import DatasetModal from "./Dataset";
import DBDataset from "../../interfaces/db-dataset";
import { toastHelper } from "../../helpers/toast";
import Compose from "./Compose";
import axiosPython from "../../helpers/axios-python";
import { getArticles } from "../../apis/article";

const { Sider, Content } = Layout;

interface TProps {
  embed?: any;
  botname?: string;
  showModalOption?: boolean;
  // modelArr: any[];
  disabled?: boolean;
  initialMsg?: string;
  // insightInitialMsg?: string;
  path?: string;
  insightMsg?: string;
}

interface msgType {
  body: any;
  align: string;
  data?: any;
  chartData?: any;
}
interface FullEmbedState {
  selectedPlugin: string;
  selectedURL: string;
  embed?: any;
  currTab: number;
  dataset: DBDataset;
  showDataset: boolean;
  collapsed: boolean;
  isTyped: any;
  showChart: boolean;
  selectedModel: any;
  modelArr: any[];
  msgArr: {
    compose: msgType[];
    apps: msgType[];
    ask: msgType[];
    insights: msgType[];
  };
  query: string;
  isToolVisible: boolean;
  tone: number;
  action: number;
  length: number;
  validationErr: string;
  loading: boolean;
  selectedApp: string;
  uniqueIdentifier: string;
  slideData: Array<string>;

  // Track active tab key
}

class DropDownChatNew extends Component<TProps, FullEmbedState> {
  msgBodyRef = React.createRef<HTMLDivElement>();
  constructor(props: TProps) {
    super(props);
    this.state = {
      selectedPlugin: "",
      collapsed: false,
      showChart: false,
      modelArr: [],
      selectedModel: undefined,
      msgArr: {
        compose: [],
        apps: [],
        ask: [],
        insights: [],
      },
      isTyped: {
        insights: false,
        ask: false,
        compose: false,
      },
      query: "",
      isToolVisible: true,
      tone: -1,
      action: -1,
      length: -1,
      validationErr: "",
      loading: false,
      selectedApp: "",
      uniqueIdentifier: window.localStorage.getItem("uniqueIdentifier") || "",
      slideData: ["loading...."],
      dataset: {},
      showDataset: false,
      currTab: 1,
      selectedURL: "",
      // Set the default active tab key
    };
  }
  clearChat = (tab: string) => {
    if (tab === "insights") this.state.msgArr.insights = [];
    else if (tab === "compose") this.state.msgArr.compose = [];
    else if (tab === "apps") this.state.msgArr.apps = [];
    else if (tab === "ask") this.state.msgArr.ask = [];
  };
  async componentDidMount() {
    this.fetchModels();
    let slideData = await this.getPredefinedInsight();
    this.setState({ slideData });
    const urlSearchParams = new URLSearchParams(window.location.search);
    const currTabParam = urlSearchParams.get("currTab");
    const selectedURL = urlSearchParams.get("url") || "";
    if (currTabParam !== null) {
      this.setState({
        currTab: parseInt(currTabParam, 10),
        selectedURL: selectedURL,
      });
    }
  }
  fetchModels = async () => {
    try {
      const res: any = await getArticles();
      this.setState({
        modelArr: res.articles,
        selectedModel: res.articles[0]._id,
      });
    } catch (err) {
      toastHelper.error("Could not fetch models!");
    }
  };

  cardStyle = {
    marginBottom: "16px",
    borderRadius: "5px",
    background: "#FFF",
    boxShadow: "rgba(175, 175, 175, 0.4) 0px 3px 5px 0px",
    padding: "15px",

    // Spacing btween cards
  };
  getPredefinedInsight = async () => {
    let response = await axiosNode.get(`/insights/predefined-insight`);
    return response.data;
  };
  getMsgType = (currTab: number) => {
    let type: "ask" | "compose" | "apps" | "insights" = "ask";
    if (currTab === 2) type = "ask";
    else if (currTab === 3) type = "compose";
    else if (currTab === 4) type = "apps";
    else if (currTab === 1) type = "insights";

    return type;
  };
  toggleCollapsed = () => {
    this.setState((prevState) => ({ collapsed: !prevState.collapsed }));
  };
  handleModelChange = (val: string) => {
    const { _id: selectedModelId } = this.state.modelArr.find(
      (model) => model.model_name === val
    );
    this.setState({ selectedModel: selectedModelId });
  };

  isInValid = (e: any) => {
    if (!e.target.value?.trim()) return "Please Select Tone, Action and Length";
    if (this.state.tone === -1) return "Select a tone";
    if (this.state.action === -1) return "Select an action";
    if (
      this.state.length === -1 &&
      this.state.action !== 3 &&
      this.state.action !== 4 &&
      this.state.action !== 5
    )
      return "Select a length";

    return "";
  };

  scrollToLatestMSg = () => {
    if (
      this.state.currTab === 1 ||
      !this.msgBodyRef ||
      !this.msgBodyRef.current ||
      !this.msgBodyRef.current.children ||
      !this.msgBodyRef.current.children.length
    )
      return;

    let childArr = this.msgBodyRef.current.children;
    childArr[childArr.length - 1].scrollIntoView();
  };
  handleDelete = () => {
    const type = this.getMsgType(this.state.currTab);
    const tmpMsgArr = { ...this.state.msgArr };
    tmpMsgArr[type] = [];
    this.setState({ msgArr: tmpMsgArr, query: "" });
    if (this.state.currTab === 3)
      this.setState({ tone: -1, length: -1, action: -1 });
  };

  handleSubmit = (e?: any) => {
    if (this.props.disabled) return;
    // if (!e.target.value) {
    //   // toastHelper.error("Enter Something!");
    //   return;
    // }
    var keyCode: any;
    if (e) {
      keyCode = e.which || e.keyCode;
    }

    if (e && (e.type === "click" || (keyCode === 13 && !e.shiftKey))) {
      e.preventDefault();
      const err =
        this.state.currTab === 3 && e.type !== "click" ? this.isInValid(e) : "";
      if (err) {
        this.setState({ isToolVisible: true, validationErr: err });
        setTimeout(() => {
          this.setState({ validationErr: "" });
        }, 3000);
        return;
      }

      const type = this.getMsgType(this.state.currTab);
      let tmpArr = { ...this.state.msgArr };
      tmpArr[type].push({
        body: this.state.query,
        align: "right",
      });

      e.target.value = "";
      this.setState(
        {
          msgArr: tmpArr,
          isToolVisible: false,
        },
        this.scrollToLatestMSg
      );

      this.searchQuery();
      // this.setState({ newResponseReceived: "" });
    } else if (e === undefined) {
      const type = this.getMsgType(this.state.currTab);
      let tmpArr = { ...this.state.msgArr };
      tmpArr[type].push({
        body: this.state.query,
        align: "right",
      });

      this.setState(
        {
          msgArr: tmpArr,
          isToolVisible: false,
        },
        this.scrollToLatestMSg
      );
      this.searchQuery();
      // this.setState({ newResponseReceived: "" });
    }
  };

  handleArrowButtonClick = async (value: any) => {
    await this.setState({ query: value });
    this.handleSubmit();
    this.setState({ query: "" });
  };

  handleToolChange = (type: string, val: number) => {
    if (type === "tone") this.setState({ tone: val });
    else if (type === "length") this.setState({ length: val });
    else if (type === "action") this.setState({ action: val });
  };

  openTools = () => {
    this.setState({ isToolVisible: true });
  };

  closeTools = () => {
    this.setState({ isToolVisible: false });
  };

  handleDatasetArrowButtonClick = async () => {
    let response = await axiosNode.post("/insights/dataset", {});
    this.setState({ dataset: response.data });
    this.setState({ showDataset: true });
    console.log(this.state.showDataset);
  };

  searchQuery = async () => {
    try {
      this.setState({ loading: true });

      let url = "";
      let payload: any = {};
      const type = this.getMsgType(this.state.currTab);

      if (this.state.currTab === 2) {
        url = "/chat";
        payload = {
          query: this.state.query,
          model_id: this.state.selectedModel,
          conversation: this.state.msgArr?.ask?.map((msg) => ({
            role: msg.align === "right" ? "user" : "assistant",
            content: msg.body,
          })),
        };
      } else if (this.state.currTab === 3) {
        url = "/compose";
        payload = {
          message: this.state.query,
          tone: getTone(this.state.tone),
          length: getLength(this.state.length),
          format: getAction(this.state.action),
          conversation: this.state.msgArr?.compose?.map((msg) => ({
            role: msg.align === "right" ? "user" : "assistant",
            content: msg.body,
          })),
        };
      } else if (this.state.currTab === 4) {
        url = "/chat-plugin";
        payload = {
          query: this.state.query,
          plugin_url: this.state.selectedPlugin,
          conversation: this.state.msgArr?.apps?.map((msg) => ({
            role: msg.align === "right" ? "user" : "assistant",
            content: msg.body,
          })),
        };
      } else if (this.state.currTab === 1) {
        url = `/insights/chat`;
        payload = {
          query: this.state.query,
          conversation: this.state.msgArr?.insights?.map((msg) => ({
            role: msg.align === "right" ? "user" : "assistant",
            content: msg.body,
          })),
        };
      }
      let response;
      console.log(url);
      if (this.state.currTab === 1) {
        response = await axiosNode.post(url, payload);
      } else {
        response = await axiosPython.post(url, payload);
      }

      if (this.state.currTab != 1) {
        let tmpMsgArr = { ...this.state.msgArr };
        tmpMsgArr[type].push({
          body: response.data,
          align: "left",
        });

        this.setState(
          {
            msgArr: tmpMsgArr,
            loading: false,
            query: "",
            isTyped: {
              ...this.state.isTyped,
              [type]: false, // Set isTyped for the current tab to false
            },
          },
          this.scrollToLatestMSg
        );
      } else {
        let tmpMsgArr = { ...this.state.msgArr };
        tmpMsgArr[type].push({
          body: response.data.message,
          data: response?.data.data,
          chartData: response?.data.chartData || [],
          align: "left",
        });
        this.setState(
          {
            msgArr: tmpMsgArr,
            loading: false,
            query: "",
            isTyped: {
              ...this.state.isTyped,
              [type]: false,
            },
          },
          this.scrollToLatestMSg
        );
      }
    } catch (err: any) {
      this.setState({ loading: false });
      toastHelper.error(err.message || "Something went wrong!");
    }
  };
  handleChange = (e: any) => {
    if (e?.target?.value?.length > 1000) return;

    this.setState({ query: e.target?.value });
  };

  // toggleShowChart = () => {
  //   this.setState((prevState) => ({ showChart: !prevState.showChart }));
  // };

  handleTabChange = (tab: string) => {
    this.setState({
      currTab: parseInt(tab, 10), // Convert tab back to a number if needed
      query: "",
    });
  };
  render() {
    const { collapsed, showChart, slideData } = this.state;

    return (
      <Layout style={{ height: "100vh" }}>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          className="sider-component"
          width={350}
        >
          <div className="demo-logo-vertical" />
          <Tabs
            style={{ width: "300px" }}
            defaultActiveKey={this.state.currTab.toString()}
            onChange={this.handleTabChange} // Handle tab change
          >
            <TabPane
              tab={<span className="chat-item-step-2">Insights</span>}
              key="1"
            >
              <div className="tab-scroller">
                {slideData.map((card, index) => (
                  <div key={index} style={this.cardStyle}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          borderRadius: "7px",
                          border: "2px solid #F9F8F8",
                          background:
                            "linear-gradient(180deg, #EFF4FF 0%, #FFF 100%)",
                          boxShadow: "0px 2px 14px 0px #C9C9C9",
                          width: "30px",
                          display: "flex",
                          justifyContent: "center",
                          height: "30px",
                        }}
                      >
                        <img
                          src="/assets/images/bulb.png"
                          alt={`Card ${index}`}
                        />
                      </div>
                      {/* )}  */}
                      <div style={{ paddingLeft: "10px" }}>{card}</div>
                    </div>
                  </div>
                ))}
              </div>
            </TabPane>
            <TabPane
              tab={<span className="chat-item-step-1">Ask(Beta)</span>}
              key="2"
            >
              {/* Content for Second Tab */}
            </TabPane>
            {/* <TabPane
              tab={<span className="chat-item-step-3">Compose(Beta)</span>}
              key="3"
            ></TabPane> */}
            {/* <TabPane tab={<span>Dashboard</span>} key="4">
              Content for fourth Tab
            </TabPane> */}
          </Tabs>
        </Sider>
        <Layout>
          <Content
            style={{
              // padding: "20px",
              background: "white", // You should replace this with the actual color
            }}
          >
            <div
              style={{
                // width: "100%",
                // display: "flex",
                // flexDirection: "column",
                // // justifyContent: "flex-end",
                // justifyContent: "space-between",
                height: "100%",
              }}
            >
              <div
                style={{
                  // width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "space-between",
                  height: "100%",
                }}
                // onClick={this.toggleShowChart}
              >
                {this.state.currTab === 1 && (
                  <InsightsChat
                    isTyped={this.state.isTyped.insights}
                    setIsTyped={(value: boolean) =>
                      this.setState({
                        isTyped: { ...this.state.isTyped, insights: value },
                      })
                    }
                    botname={this.props.botname || ""}
                    path={this.props.path || ""}
                    currTab={this.state.currTab}
                    embed={this.props.embed}
                    handleChange={this.handleChange}
                    handleDelete={this.handleDelete}
                    handleSubmit={this.handleSubmit}
                    clearChat={this.clearChat}
                    query={this.state.query}
                    handleArrowButtonClick={this.handleArrowButtonClick}
                    handleDatasetArrowButtonClick={
                      this.handleDatasetArrowButtonClick
                    }
                    msgArr={this.state.msgArr.insights}
                    loading={this.state.loading}
                    msgBodyRef={this.msgBodyRef}
                    initialMsg={this.props.insightMsg}
                    handleModelChange={this.handleModelChange}
                    modelArr={this.state.modelArr}
                  />
                )}
                {this.state.currTab === 2 && (
                  <AskChat
                    handleChange={this.handleChange}
                    isTyped={this.state.isTyped.ask}
                    setIsTyped={(value: boolean) =>
                      this.setState({
                        isTyped: { ...this.state.isTyped, ask: value },
                      })
                    }
                    handleDelete={this.handleDelete}
                    handleSubmit={this.handleSubmit}
                    query={this.state.query}
                    msgArr={this.state.msgArr.ask}
                    loading={this.state.loading}
                    msgBodyRef={this.msgBodyRef}
                    initialMsg={this.props.initialMsg}
                    showModalOption={this.props.showModalOption}
                    handleModelChange={this.handleModelChange}
                    modelArr={this.state.modelArr}
                    handleArrowButtonClick={this.handleArrowButtonClick}
                    embed={this.props.embed}
                    botname={this.props.botname || ""}
                  />
                )}
                {/* {this.state.currTab === 3 && (
                  <Compose
                    embed={this.props.embed}
                    isTyped={this.state.isTyped.compose}
                    setIsTyped={(value: boolean) =>
                      this.setState({
                        isTyped: { ...this.state.isTyped, compose: value },
                      })
                    }
                    handleChange={this.handleChange}
                    handleDelete={this.handleDelete}
                    handleSubmit={this.handleSubmit}
                    query={this.state.query}
                    msgArr={this.state.msgArr.compose}
                    loading={this.state.loading}
                    msgBodyRef={this.msgBodyRef}
                    tone={this.state.tone}
                    length={this.state.length}
                    action={this.state.action}
                    validationErr={this.state.validationErr}
                    isToolVisible={this.state.isToolVisible}
                    handleToolChange={this.handleToolChange}
                    openTools={this.openTools}
                    closeTools={this.closeTools}
                  />
                )} */}
              </div>
              {this.state.showDataset ? (
                <DatasetModal
                  data={this.state.dataset}
                  isOpen={this.state.showDataset}
                  handleOnClose={() => this.setState({ showDataset: false })}
                />
              ) : null}
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default DropDownChatNew;
