import React, { Component } from "react";
import Select from "react-select";
import { Button } from "react-bootstrap";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import toast from "../../helpers/toast";
import { getSlacks, postSlack } from "../../apis/slack";
import { getArticles } from "../../apis/article";

interface State {
  token: string;
  signingSecret: string;
  appToken: string;
  botName: string;
  loading: boolean;
  selectedOptions: string[];
  options: any; // [{value: string, label: string}];
  errors: {
    [key: string]: string;
  };
  bots: {
    id: number;
    name: string;
    status: string;
  }[];
}

class ConnectToSlack extends Component<{}, State> {
  state: State = {
    token: "",
    signingSecret: "",
    appToken: "",
    botName: "",
    errors: {},
    loading: false,
    selectedOptions: [],
    options: [],
    bots: []
  };

  componentDidMount() {
    this.getData();
    this.getSlackBots();
  }

  getSlackBots = async () => {
    try {
      const response: any = await getSlacks();
      const slackBots = response.slacks;

      for (let i = 0; i < slackBots.length; i++) {
        slackBots[i].sid = i + 1;
      }

      this.setState({ bots: slackBots });
    } catch (error) {
      console.log(error);
    }
  };

  async getData() {
    try {
      let response: any = await getArticles();
      let options = [];
      for (let i = 0; i < response.articles.length; i++) {
        let article = response.articles[i];
        options.push({
          value: article.model_name.replace(/\s+/g, "-").toLowerCase(),
          label: article.model_name,
        });
      }
      this.setState({ options });
    } catch (error) {
      console.log(error);
    }
  }

  handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { token, signingSecret, appToken, botName } = this.state;

    // Validate the form
    let errors: State["errors"] = {};
    if (!token) {
      errors.token = "Slack Bot User OAuth Token is required";
    }

    if (!signingSecret) {
      errors.signingSecret = "Slack Bot Signing Secret is required";
    }
    if (!appToken) {
      errors.appToken = "Slack Bot App Token is required";
    }

    if (!botName) {
      errors.botName = "AI Model Name is required";
    }

    if (this.state.selectedOptions.length == 0) {
      errors.selectModel = "At least select one model";
    }

    this.setState({ errors });

    // If there are no errors, submit the form
    if (Object.keys(errors).length === 0) {
      // Make API call to add the bot to Slack using the token and secret
      // ...
      try {
        this.setState({ loading: true });
        const response = await postSlack({
          token,
          signingSecret,
          appToken,
          botName,
          models: this.state.selectedOptions,
        });
        this.setState({
          token: "",
          signingSecret: "",
          appToken: "",
          botName: "",
          loading: false,
          selectedOptions: [],
        });
        toast.success("New Slack Bot added.");
        this.getSlackBots();
      } catch (error) {
        console.log(error);
      }
    }
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ [event.target.name]: event.target.value } as any);
  };

  columns: GridColDef[] = [
    {
      field: 'sid',
      headerName: 'S. No',
      width: 180,
      filterable: false,
      sortable: false,
    },
    {
      field: 'botName',
      headerName: 'Slack Bot name',
      width: 200,
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      minWidth: 150,
      filterable: false,
      flex: 1,
      renderCell: (params) => <div className="api__botStatus" />
    },
  ];

  render() {
    const { token, signingSecret, appToken, errors, botName } = this.state;

    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <div className="col-sm-6">
            <input
              required
              className="form-control"
              type="text"
              placeholder="AI Model Name"
              name="botName"
              value={botName}
              onChange={this.handleChange}
            />
            {errors.botName && (
              <div className="invalid-feedback">{errors.botName}</div>
            )}
          </div>

          <div className="col-sm-6 mt-3">
            <Select
              required
              placeholder="Select your AI model"
              isMulti
              options={this.state.options}
              value={this.state.selectedOptions}
              onChange={(selectedOptions: any) => {
                this.setState({ selectedOptions });
              }}
            />
            {errors.selectModel && (
              <div className="invalid-feedback">{errors.selectModel}</div>
            )}
          </div>

          <div className="col-sm-6 mt-3">
            <input
              required
              className="form-control"
              type="text"
              placeholder="Slack Bot User OAuth token"
              name="token"
              value={token}
              onChange={this.handleChange}
            />
            {errors.token && (
              <div className="invalid-feedback">{errors.token}</div>
            )}
          </div>

          <div className="col-sm-6 mt-3">
            <input
              required
              className="form-control"
              type="text"
              placeholder="Slack Bot Signing Secret"
              name="signingSecret"
              value={signingSecret}
              onChange={this.handleChange}
            />
            {errors.signingSecret && (
              <div className="invalid-feedback">{errors.signingSecret}</div>
            )}
          </div>

          <div className="col-sm-6 mt-3">
            <input
              required
              className="form-control"
              type="text"
              placeholder="Slack Bot App Token"
              name="appToken"
              value={appToken}
              onChange={this.handleChange}
            />
            {errors.appToken && (
              <div className="invalid-feedback">{errors.appToken}</div>
            )}
          </div>

          <div className="col-sm-6 mt-3">
            <Button
              type="submit"
              className="btn-green"
              disabled={this.state.loading}
            >
              Create new Slack bot
              {this.state.loading && (
                <div className="spinner-border text-light ms-2" role="status">
                  &nbsp; <span className="sr-only"></span>
                </div>
              )}
            </Button>
          </div>
        </form>
        <div className="col-9 mt-5">
          <h5>Live Slack Bots</h5>
          <DataGrid
            rows={this.state.bots}
            columns={this.columns}
            getRowId={(row) => row._id}
            checkboxSelection={false}
            rowsPerPageOptions={[]}
            disableColumnMenu
            style={{ height: "300px" }}
          />
        </div>
      </>
    );
  }
}

export default ConnectToSlack;
