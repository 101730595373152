import React from "react";
import { Button, Modal, Form } from "react-bootstrap";
import Select from 'react-select';
import "../../style/App.css"
import toast from "../../helpers/toast";
import { postAgent } from "../../apis/agent";
import { agentRoleOptions } from "../../helpers/modalType";


interface NewAgentProps {
  open: boolean;
  close: Function;
  loading: boolean;
  getDatas: Function;
}

interface NewAgentState {
  loading: boolean,
  agent_email: string,
  agent_access: string | undefined
  agent_name: string,
  success: any[],
  error: any[],
}

class NewAgent extends React.Component<NewAgentProps, NewAgentState> {
  state: NewAgentState = {
    loading: false,
    agent_email: "",
    agent_access: undefined,
    agent_name: "",
    success: [],
    error: [],
  };

  onChange(event: any) {
    //@ts-ignore
    return this.setState({ [event.target.name]: event.target.value });
  }

  async handleSubmit(event: any) {
    event.preventDefault();
    if (!this.state.agent_email) {
      toast.error("Please enter the agent's name.");
      return;
    }
    else if (!this.state.agent_email) {
      toast.error("Please enter the agent's email.");
      return;
    }
    else if (!this.state.agent_access) {
      toast.error("Please select an agent role.");
      return;
    }

    try {
      this.setState({ loading: true });
      this.setState((success) => ({
        success: [],
        error: [],
      }));
      const res: any = await postAgent({
        agent_email: this.state.agent_email,
        agent_access: this.state.agent_access,
        agent_name: this.state.agent_name,
      });

      await this.props.getDatas();
      this.setState((success) => ({
        // questions: [""],
        agent_email: "",
        agent_access: undefined,
        agent_name: "",
      }));
      toast.success(`Agent ${this.state.agent_email} has been added.`);
      this.props.close();
    } catch (error:any) {
      toast.error((error?.response?.data?.message)?error.response.data.message:"Something went wrong, try again or contact support.")
    }

    this.setState({ loading: false });
  }

  render() {
    return (
      <>
        <Modal
          show={this.props.open}
          onHide={() => this.props.close()}
        >
          <Modal.Header  className="border-0" />
          <form onSubmit={(event) => this.handleSubmit(event)}>
            <Modal.Body className="pt-0">
              <h4 className=" mb-4 model-heading">Add an agent</h4>
              <Form.Group className="mb-3">
              <label  className="form-label form-font" >Agent Name</label>
                <Form.Control
                  required
                  type="text"
                  name="agent_name"
                  placeholder="Enter Agent name"
                  value={this.state.agent_name}
                  onChange={(event) => this.onChange(event)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label form-font" >Agent Email</label>

                <Form.Control
                  required
                  type="email"
                  name="agent_email"
                  placeholder="Enter Agent email"
                  onChange={(event) => this.onChange(event)}
                  value={this.state.agent_email}
                />
              </Form.Group>

              <label htmlFor="exampleFormControlInput1" className="form-label form-font" >Agent Role</label>

              <Select
                placeholder="Role"
                isClearable={true}
                isSearchable={true}
                name="agent_access"
                onChange={(e: any) => this.setState({ ...this.state, agent_access: e.value })}
                value={agentRoleOptions.filter((option) => {
                  return option.value === this.state.agent_access;
                })}
                options={agentRoleOptions}
              />
            </Modal.Body>
            <hr />
            <Modal.Footer className="d-flex justify-content-end  border-0 ">
              <Button
                className=" btn-outline btn-container"
                variant="primary"
                onClick={() => this.props.close()}
              >
                <img src="/assets/images/blue-cross-icon.svg"/>
                Cancel
              </Button>
              <Button
                // variant="secondary"
                className="btn-container btn-blue "
                type="submit"
                disabled={this.state.loading}
              >
                <img src="/assets/images/white-tick-icon.svg"/>
                Add
                {this.state.loading && (
                  <div className="spinner-border text-light ms-2" role="status">
                    &nbsp; <span className="sr-only"></span>
                  </div>
                )}
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    );
  }
}

export default NewAgent;
