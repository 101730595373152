import axios from "axios";
import { getCookie } from "../helpers/cookie";

export const getIntegrations = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.get(
        process.env.REACT_APP_NODE_REST_API + "api/v1/integration"
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const postIntegration = async (integration: any) => {
  integration.owner = getCookie("company_id");
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.post(
        process.env.REACT_APP_NODE_REST_API + "api/v1/integration",
        integration
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteIntegration = async (integration: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.delete(
        process.env.REACT_APP_NODE_REST_API +
          "api/v1/integration/" +
          integration._id
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};
