import { Component } from "react";
import { ArrowCounterclockwise, Send } from "react-bootstrap-icons";
import Typewriter from "typewriter-effect";
import { Form } from "react-bootstrap";
import { Button, Tooltip } from "@mui/material";
import MaxWidthDialog from "../SalesReportChart/Model";
import { DownOutlined, DownloadOutlined, UpOutlined } from "@ant-design/icons";
import { Collapse, CollapseProps } from "antd";

interface ContentProps {
  // expandedPanels: {},
  msgArr: {
    body: any;
    align: string;
    data?: any;
  }[];
  isTyped?: any;
  // newResponseReceived?: any;
  setIsTyped?: any;
  loading: boolean;
  embed: boolean;
  msgBodyRef: any;
  isLoggedIn?: boolean;
}
interface ContentState {
  expandedPanels: { [key: number]: boolean };
  showDialog: boolean;
  messageList: any;
  dialogBodyData: any;
  clickedMessageId: number;
}
const { Panel } = Collapse; // Extract Panel from Collapse

export class ChatContent extends Component<ContentProps, ContentState> {
  constructor(props: ContentProps) {
    super(props);
    this.state = {
      expandedPanels: {},
      showDialog: false,
      messageList: [],
      dialogBodyData: {},
      clickedMessageId: 0,
    };
  }

  togglePanel = (panelId: number) => {
    this.setState((prevState) => ({
      expandedPanels: {
        ...prevState.expandedPanels,
        [panelId]: !prevState.expandedPanels[panelId],
      },
    }));
  };

  scrollToLatestMSg = () => {
    if (
      !this.props.msgBodyRef ||
      !this.props.msgBodyRef.current ||
      !this.props.msgBodyRef.current.children ||
      !this.props.msgBodyRef.current.children.length ||
      (!this.props.loading && this.props.isTyped)
    )
      return;

    let childArr = this.props.msgBodyRef.current.children;
    childArr[childArr.length - 1].scrollIntoView(false);
  };
  render() {
    const { msgArr, loading, isLoggedIn } = this.props;
    const { expandedPanels } = this.state;
    const { showDialog, dialogBodyData, clickedMessageId } = this.state;
    const updatedArray = msgArr?.map((mes: any, index) => ({
      ...mes,
      id: 1 + index,
    }));

    return (
      <>
        {/* {showDialog && (
          <MaxWidthDialog
            handleClose={() =>
              this.setState({
                showDialog: false,
                clickedMessageId: 0,
                dialogBodyData: {},
              })
            }
            msg={dialogBodyData}
          />
        )} */}

        {updatedArray.map((val, i) => {
          return (
            <div key={i}>
              {val.body !== "" && (
                <div>
                  <div className="d-flex flex-column col">
                    <div
                      style={{ margin: "10px" }}
                      className={`dropDownChat__msg rounded shadow py-2 px-3 mb-2 ${
                        val.align === "right" ? "dropDownChat__msg-user" : ""
                      }`}
                    >
                      {val.align === "left" &&
                      !this.props.isTyped &&
                      i === updatedArray.length - 1 ? (
                        <>
                          <Typewriter
                            options={{ delay: 0 }}
                            onInit={(typewriter) => {
                              const inter = setInterval(
                                this.scrollToLatestMSg,
                                2000
                              );
                              typewriter
                                .typeString(val.body)
                                .start()
                                .callFunction(() => {
                                  this.scrollToLatestMSg();
                                  clearInterval(inter);
                                  this.props.setIsTyped(true);
                                });
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <pre
                            style={{
                              cursor: val?.data?.length ? "pointer" : "",
                            }}
                            className="m-0"
                            onClick={(e) => {
                              this.togglePanel(val.id);
                              // this.scrollToLatestMSg();
                            }}
                          >
                            {val.body}
                          </pre>
                          {val?.data?.length ? (
                            <Collapse
                              accordion
                              expandIconPosition="end"
                              ghost={true}
                              activeKey={
                                expandedPanels[val.id] ? "1" : undefined
                              }
                            >
                              <Panel
                                showArrow={false}
                                // collapsible="header"
                                className="custom"
                                header={
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                    onClick={() => {
                                      this.togglePanel(val.id);

                                      // this.scrollToLatestMSg();
                                    }}
                                  >
                                    {expandedPanels[val.id] ? (
                                      ""
                                    ) : (
                                      <div
                                        style={{
                                          color: "#3E6FE1",
                                          fontFamily: "Inter",
                                          fontSize: "14px",
                                          fontWeight: "400",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        View More{" "}
                                        <DownOutlined
                                          style={{
                                            color: "#3E6FE1",
                                          }}
                                          rev={undefined}
                                        />
                                      </div>
                                    )}
                                  </div>
                                }
                                key="1"
                              >
                                <MaxWidthDialog
                                  handleClose={() =>
                                    this.setState({
                                      showDialog: false,
                                      clickedMessageId: 0,
                                      dialogBodyData: {},
                                    })
                                  }
                                  msg={val}
                                />
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.togglePanel(val.id);
                                  }}
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    cursor: "pointer",
                                  }}
                                >
                                  {expandedPanels[val.id] ? (
                                    <div
                                      style={{
                                        color: "#3E6FE1",
                                        fontFamily: "Inter",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        display: "flex",
                                        alignItems: "center",
                                        marginTop: "10px",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        this.togglePanel(val.id);
                                      }}
                                    >
                                      View less{" "}
                                      <UpOutlined
                                        style={{
                                          color: "#3E6FE1",
                                          marginLeft: "5px",
                                        }}
                                        rev={undefined}
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </Panel>
                            </Collapse>
                          ) : null}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })}

        <div>
          {loading && (
            <div className="text-secondary d-flex align-items-center fs-6 mb-2">
              <div className="dropDownChat__loader me-3">
                <span></span>
                <span></span>
              </div>
              <p className="fw-bold mb-0">Generating answers for you &nbsp;</p>
              <Typewriter
                options={{
                  loop: true,
                  cursor: "",
                  delay: 0,
                }}
                onInit={(typewriter) => {
                  typewriter
                    .typeString(". . . . . .")
                    .start()
                    .callFunction(() => {
                      this.scrollToLatestMSg();
                    });
                }}
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

interface TextAreaProps {
  handleDelete: (e: any) => void;
  handleChange: (e: any) => void;
  handleSubmit: (e: any) => void;
  botname: string;
  query: string;
  showModalOption?: boolean;
  modelArr?: any[];
  handleModelChange?: (e: string) => void;
  style?: any;
  resetInsight?: any;
  insights?: boolean;
  embed: boolean;
}

interface TextAreaState {}

export class ChatTextArea extends Component<TextAreaProps, TextAreaState> {
  render() {
    const {
      handleDelete,
      handleChange,
      handleSubmit,
      query,
      showModalOption,
      modelArr,
      resetInsight,
      insights,
      handleModelChange,
      style,
    } = this.props;

    return (
      <>
        <div
          className="col d-flex flex-column justify-content-end px-3 pb-1"
          style={style}
        >
          {modelArr &&
            handleModelChange &&
            !this.props.embed &&
            this.props.botname !== "nav" && (
              <div className="d-flex mb-3">
                <Form.Group>
                  <Form.Select
                    onChange={(e) => handleModelChange(e.target.value)}
                  >
                    {modelArr.length === 0 && <option value="">Loading</option>}
                    {modelArr.length &&
                      modelArr.map((val, i) => (
                        <option key={val._id} value={val.model_name}>
                          {val.model_name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </div>
            )}
          <div className="w-100 d-flex align-items-end align-self-end">
            <Tooltip title="Reset" placement="top">
              <div
                className="dropDownChat__icon d-flex justify-content-center align-items-center p-2 text-white fs-5"
                role="button"
                onClick={insights ? resetInsight : handleDelete}
              >
                <ArrowCounterclockwise />
              </div>
            </Tooltip>

            <div className="col">
              <div className="d-flex justify-content-center align-items-center p-2 rounded-5 shadow ms-2">
                <textarea
                  placeholder="Type your message"
                  className="dropDownChat__input text-secondary w-100 px-2"
                  value={query}
                  onChange={handleChange}
                  onKeyDown={handleSubmit}
                />
                <div
                  className="dropDownChat__icon d-flex justify-content-center align-items-center p-2 text-white "
                  role="button"
                  onClick={handleSubmit}
                >
                  <Send />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <a
              className="text-secondary text-decoration-none d-flex align-items-center justify-content-center"
              href="https://neuralmind.io/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://app.neuralmind.io/assets/images/logo-small-icon.svg"
                alt=""
              />
              <p className="mb-0 ms-1">Powered by Neuralmind.io</p>
            </a>
          </div>
        </div>
      </>
    );
  }
}
