import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Button } from 'react-bootstrap';

interface AgentTableProps {
  editRow: Function,
  selectionChange: Function,
  agents: any[]
}

export default function AgentTable(props: AgentTableProps) {
  const { editRow, selectionChange, agents } = props;

  const columns: GridColDef[] = [
    {
      field: 'agent_name',
      headerName: 'Name',
      width: 180,
    },
    {
      field: 'agent_email',
      headerName: 'Email',
      width: 200,
    },
    {
      field: 'agent_access',
      headerName: 'Role',
      type: 'number',
      width: 130,
      headerAlign: "left",
      align: "left",
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {
              params.row.owner != "Super User" && (
                <>
                  <Button
                    onClick={() => editRow(params.row)}
                    variant="secondary"
                    size='sm'
                  >
                    Edit
                  </Button>
                </>
              )
            }
          </div>
        )
      }
    },
  ];


  return (
    <DataGrid
      rows={agents}
      columns={columns}
      getRowId={(row) => {
        if (row._id == undefined) return -1;
        else return row._id
      }}
      isRowSelectable={(params) => params.row._id != undefined}
      pageSize={10}
      checkboxSelection
      disableColumnMenu
      rowsPerPageOptions={[]}
      onSelectionModelChange={(ids) => {
        const selectedIDs = new Set(ids);
        selectedIDs.delete(-1); //remove super user from selection
        const selectedRowData = agents.filter((row) => selectedIDs.has(row._id));
        selectionChange(selectedRowData);
      }}
      disableSelectionOnClick
      style={{
        height: "480px"
      }}
    />
  );
}