import axios from "../helpers/axios";

export const getSubscriptionDetails = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.get(
        "/api/v1/subscription/get"
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const createSubscription = async (priceId: number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.post(
        "/api/v1/subscription/create-subscription",
        {
          plan_id: priceId
        }
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateubscription = async (priceId: string, subscriptionId: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.post(
        "/api/v1/subscription/update-subscription",
        {
          price_id: priceId,
          subscription_id: subscriptionId
        }
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const cancelSubscription = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.delete("/api/v1/subscription/cancel-subscription");
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

