import axios from "../helpers/axios";
import { getCookie } from "../helpers/cookie";

export const getUserTourData = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const email = getCookie("email");
      const response: any = await axios.get(`/api/v1/user-tourdata/${email}`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateUserTourData = async (updatedData: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const email = getCookie("email");
      const response: any = await axios.put("/api/v1/user-tourdata", {
        email,
        ...updatedData,
      });
      resolve(response.data.data);
    } catch (error) {
      reject(error);
    }
  });
};
