export const typeOption = [
    { value: "sales/crm", label: "Sales/CRM" },
    { value: "marketing", label: "Marketing" },
    { value: "analytics", label: "Analytics" },
    { value: "cad&plm", label: "CAD & PLM" },
    { value: "collab", label: "Collaboration & Productivity" },
    { value: "commerce", label: "Commerce" },
    { value: "content-management", label: "Content Management" },
    { value: "customer-support", label: "Customer Support" },
    { value: "data-privacy", label: "Data Privacy" },
    { value: "design-development", label: "Design Development" },
    { value: "digital-advertising", label: "Digital Advertising" },
    { value: "erp", label: "ERP" },
    { value: "risk&compliance", label: "Governance, Risk & Compliance" },
    { value: "hosting", label: "Hosting" },
    { value: "hr", label: "HR" },
    { value: "it-infrastructure", label: "IT Infrastructure" },
    { value: "it-management", label: "IT Management" },
    { value: "office", label: "Office" },
    { value: "security", label: "Security" },
    { value: "supply-chain", label: "Supply Chain & Logistics" },
    { value: "construction-education", label: "Construction Education" },
    { value: "financial-services", label: "Financial Services" },
    { value: "health-care", label: "Health Care" },
    { value: "legal", label: "Legal" },
    { value: "nonprofit", label: "Nonprofit" },
    { value: "transportation", label: "Transportation" },
    { value: "religious-institutions", label: "Religious Institutions" },
    { value: "others", label: "Others" },
]

export const agentRoleOptions = [
    { label: "Admin", value: "Admin" },
    { label: "Agent", value: "Agent" }
];