import * as React from "react";
import { Button } from "react-bootstrap";

import { getAgents, deleteAgent } from "../../apis/agent";
import DeleteModal from "../../helpers/delete-modal";
import NewAgent from "./NewAgent";
import EditAgent from "./EditAgent";
import AgentTable from "./AgentTable";
import toast from "../../helpers/toast";

interface AgentsProps {}

interface AgentsState {
  agents: any[];
  open: Boolean;
  agent: Boolean;
  edit: Boolean;
  payload: Object;
  key: Number;
  deletes: Object[];
  newAgentLoading: boolean;
  deleteLoading: boolean;
  success: string;
}

class Agents extends React.Component<AgentsProps, AgentsState> {
  state = {
    agents: [],
    open: false,
    agent: false,
    edit: false,
    payload: {},
    key: 1,
    deletes: [
      {
        agent_name: "",
      },
    ],
    success: "",
    error: "",
    newAgentLoading: false,
    deleteLoading: false,
    editAgentLoading: false,
  };

  async componentDidMount() {
    await this.getDatas();
  }

  getDatas = async () => {
    try {
      const response: any = await getAgents();
      this.setState({ agents: response.agents });
    } catch (error: any) {
      if (error?.response?.data?.message === "Not found any agent") {
        this.setState({ agents: [] });
      } else {
        console.log(error);
      }
    }
  };

  runDelete = async () => {
    try {
      this.setState({ deleteLoading: true });
      const deletes = this.state.deletes;
      const promises = [];

      for (let index = 0; index < deletes.length; index++) {
        const deleted = deletes[index];
        promises.push(deleteAgent(deleted));
      }

      await Promise.all(promises);
      await this.getDatas();
      this.setState({
        success: "Successfully Deleted!",
        open: false,
        deleteLoading: false,
      });

      toast.success("Agent has been deleted!");
    } catch (error) {
      console.log(error);
      this.setState({ deleteLoading: false });
    }
  };

  render() {
    return (
      <div className="bg-white p-3 h-100">
        <h3 className="fw-bold">Agents</h3>
        <div>
          <div className="d-flex justify-content-end mt-3">
            <Button
              className="me-2"
              variant="danger"
              onClick={() => {
                if (!this.state.deletes.length) return;
                this.setState({ open: true });
              }}
            >
              Delete
            </Button>
            <Button
              onClick={() => this.setState({ agent: true })}
              style={{
                borderRadius: "5px",
                border: "unset",
                background: "linear-gradient(132deg, #4776F1 0%, #1333B6 100%)",
                boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.15)",
              }}
            >
              Add New Agent
            </Button>
          </div>
          <div className="mt-3">
            <AgentTable
              agents={this.state.agents}
              editRow={(row: any) => {
                this.setState({
                  payload: row,
                  edit: true,
                  key: Math.round(Math.random() * 100),
                });
              }}
              selectionChange={(rows: any[]) => {
                this.setState({ deletes: rows });
              }}
            />
          </div>
        </div>
        <DeleteModal
          open={this.state.open}
          close={() => this.setState({ open: false })}
          deleteAction={() => {
            this.runDelete();
          }}
          agent={true}
          loading={this.state.deleteLoading}
          title={
            this.state.deletes.length > 1
              ? `Delete ${this.state.deletes.length} agents`
              : `Delete Agent ${this.state.deletes[0]?.agent_name}`
          }
          subtitle="Are you sure you want to delete this agent?"
        />
        <NewAgent
          open={this.state.agent}
          close={() => this.setState({ agent: false })}
          getDatas={this.getDatas}
          loading={this.state.newAgentLoading}
        />
        <EditAgent
          open={this.state.edit}
          close={() => this.setState({ edit: false })}
          payload={this.state.payload}
          getDatas={this.getDatas}
          key={this.state.key}
          loading={this.state.editAgentLoading}
        />
      </div>
    );
  }
}

export default Agents;
