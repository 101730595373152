import React, { Component } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Select from "react-select";

import toast from "../../helpers/toast";
import { putAgent } from "../../apis/agent";
import { agentRoleOptions } from "../../helpers/modalType";

interface EditAgentProps {
  open: boolean;
  close: Function;
  loading: boolean;
  getDatas: Function;
  payload: any;
}

interface EditAgentState {}

class EditAgent extends React.Component<EditAgentProps, EditAgentState> {
  state = {
    loading: false,
    agent_email: "",
    agent_access: "",
    agent_name: "",
    success: [],
    error: [],
  };

  componentDidMount() {
    this.setState({
      agent_email: this.props.payload.agent_email,
      agent_access: this.props.payload.agent_access,
      agent_name: this.props.payload.agent_name,
    });
  }

  onChange(event: any) {
    return this.setState({ [event.target.name]: event.target.value });
  }

  async handleSubmit(event: any) {
    event.preventDefault();
    try {
      this.setState({ loading: true });
      this.setState((success) => ({
        success: [],
        error: [],
      }));
      const res: any = await putAgent({
        agent_email: this.state.agent_email,
        agent_access: this.state.agent_access,
        agent_name: this.state.agent_name,
        _id: this.props.payload._id,
      });

      const arr: any[] = [...this.state.success];
      arr.push(res.message);
      this.setState((success) => ({
        success: [...arr],
        questions: [""],
        agent_email: "",
        agent_access: "",
        agent_name: "",
      }));
      this.props.getDatas();
      toast.success("Agent details updated successfully!");
      this.props.close();
    } catch (error) {
      console.log(error);
    }

    this.setState({ loading: false });
  }

  render() {
    return (
      <>
      <Modal
          show={this.props.open}
          onHide={() => this.props.close()}
        >
          <Modal.Header closeButton className="border-0" />
          <form onSubmit={(event) => this.handleSubmit(event)}>
            <Modal.Body className="pt-0">
              <h3 className="fw-bold mb-4">Edit agent details</h3>
              <Form.Group className="mb-3">
              <label  className="form-label form-font" >Agent Name</label>
                <Form.Control
                  required
                  type="text"
                  name="agent_name"
                  placeholder="Agent name"
                  value={this.state.agent_name}
                  onChange={(event) => this.onChange(event)}
                />
              </Form.Group>
              <label htmlFor="exampleFormControlInput1" className="form-label form-font" >Agent Email</label>
              <Form.Group className="mb-3">
                <Form.Control
                  required
                  type="email"
                  name="agent_email"
                  placeholder="Agent email"
                  onChange={(event) => this.onChange(event)}
                  value={this.state.agent_email}
                />
              </Form.Group>
              <label htmlFor="exampleFormControlInput1" className="form-label form-font" >Agent Role</label>
              <Select
                placeholder="Role"
                isClearable={true}
                isSearchable={true}
                name="agent_access"
                onChange={(e: any) => this.setState({ ...this.state, agent_access: e.value })}
                value={agentRoleOptions.filter((option) => {
                  return option.value === this.state.agent_access;
                })}
                options={agentRoleOptions}
              />
            </Modal.Body>
            <hr />
            <Modal.Footer className="d-flex justify-content-end border-0">
              <Button
                className=" btn-outline btn-container"
                onClick={() => this.props.close()}
              >
                No
              </Button>
              <Button
                className=" btn-container btn-blue"
                type="submit"
                disabled={this.state.loading}
              >
                Update
                {this.state.loading && (
                  <div className="spinner-border text-light ms-2" role="status">
                    &nbsp; <span className="sr-only"></span>
                  </div>
                )}
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    );
  }
}

export default EditAgent;
