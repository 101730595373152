import React, { Component } from "react";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import { MenuItem, OutlinedInput, Select, SelectChangeEvent, Theme, createTheme } from "@mui/material";

interface BasicTableProps {
  data: any;
}
interface State {
  selectedXAxis: any;
  selectedYAxis: any;
  chartData: any;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
    },
  },
};

const theme = createTheme();

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
class SalesPieChart extends Component<BasicTableProps, State> {
  constructor(props: BasicTableProps) {
    super(props);
    this.state = {
      selectedXAxis: [this.props.data.chartData[0].xAxis[0]],
      selectedYAxis: [this.props.data.chartData[0].yAxis[0]],
      chartData: {
        ...this.props.data.chartData[0],
        labels: this.props.data.chartData[0].dataSet.map((e: any) => e.label),
        datasets: [
          {
            label: this.props.data.chartData[0].yAxis,
            data: this.props.data.chartData[0].dataSet.map((e: any) => e.value), // Example data, replace with your actual data
            backgroundColor: this.props.data.chartData[0].dataSet[0].fill,
          },
        ],
      }
    };
  }
  handleXAxisChange = (event: SelectChangeEvent<typeof this.state.selectedXAxis>) => {
    try {
      const {
        target: { value },
      } = event;
      this.setState({
        selectedXAxis: typeof value === "string" ? value.split(",") : value,
      });
      this.setChartData()
    } catch (error) {
      console.log(error);
    }
  };
  handleYAxisChange = (event: SelectChangeEvent<typeof this.state.selectedYAxis>) => {
    try {
      const {
        target: { value },
      } = event;
      this.setState({
        selectedYAxis: typeof value === "string" ? value.split(",") : value,
      });
      this.setChartData()
    } catch (error) {
      console.log(error);
    }
  };
  setChartData = () => {
    this.setState((state, props) => ({
      chartData: {
        ...props.data.chartData[0],
        dataSet: props.data.data.map((e: any) => ({ label: e[state.selectedXAxis[0]], value: e[state.selectedYAxis[0]] })),
      }
    }))
  }
  render() {
    // const { data } = this.props;
    const { selectedXAxis, selectedYAxis, chartData } = this.state;
    console.log(chartData);
    const data = {
      labels: chartData.dataSet.map((e: any) => e.label),
      datasets: [
        {
          data: chartData.dataSet.map((e: any) => e.value),
          backgroundColor: chartData.dataSet.map((e: any) => e.fill),
        },
      ],
    };
    const options = {
      plugins: {
        legend: {
          display: false,
        },
      },
    };

    return (
      <div className="pie-content ">
        <div className="axis-select-container">
          <Select
            className="axis-select-component"
            value={selectedXAxis}
            onChange={this.handleXAxisChange}
            input={<OutlinedInput />}
            renderValue={(selected) => selected}
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "Without label" }}
          >
            {chartData.xAxis.map((name: typeof selectedXAxis[0]) => (
              <MenuItem
                key={name}
                value={name}
                style={getStyles(name, selectedXAxis, theme)}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
          <Select
            className="axis-select-component"
            value={selectedYAxis}
            onChange={this.handleYAxisChange}
            input={<OutlinedInput />}
            renderValue={(selected) => selected}
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "Without label" }}
          >
            {chartData.yAxis.map((name: typeof selectedYAxis[0]) => (
              <MenuItem
                key={name}
                value={name}
                style={getStyles(name, selectedYAxis, theme)}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="inside-chart">
          <div className="chart-options">
            <Chart type="pie" data={data} options={options} />
          </div>
          <div className="data-mapping">
            {chartData.dataSet.map((data: any, i: number) => (
              <div className="item-centering " key={i}>
                <div
                  className="filling-color"
                  style={{
                    backgroundColor: `${data.fill}`,
                  }}
                ></div>
                <div className="data-label">{data.label}</div>
                <div className="data-value">{data.value}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default SalesPieChart;
