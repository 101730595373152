import { ReactNode } from "react";
import { toast as ReactToast } from "react-toastify";
import "../index.css"
const toast = {
  success: (msg: string | ReactNode, id: any = undefined) => {
    if (id !== undefined) {
      return ReactToast.update(id, {
        render: (
          <>
            <div className="d-flex align-items-center">
              <img
                src="/assets/images/victory-icon.svg"
                className="me-4 bg-white p-2 rounded-5"
                alt="victory-icon"
              />
              {msg}
            </div>
          </>
        ),
        type: "success",
        style: { background: '#AFF4C3' },
        progressStyle: { background: '#62BB7B' },
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
      });
    }

    return ReactToast.success(
      <div className="d-flex align-items-center">
        <img
          src="/assets/images/victory-icon.svg"
          className="me-4 bg-white p-2 rounded-5"
          alt="victory-icon"
        />
        {msg}
      </div>
    );
  },
  error: (msg: string | ReactNode, id: any = undefined) => {
    if (id !== undefined) {
      return ReactToast.update(id, {
        render: (
          <>
            <div className="d-flex align-items-center">
              <img
                src="/assets/images/close-icon.svg"
                className="me-4 bg-white p-2 rounded-5"
                alt="close-icon"
              />
              {msg}
            </div>
          </>
        ),
        type: "error",
        isLoading: false,
        style: { background: '#FF8964' },
        progressStyle: { background: '#E74C3C' },
        autoClose: 5000,
        closeOnClick: true,
      });
    }

    return ReactToast.error(
      <div className="d-flex align-items-center">
        <img
          src="/assets/images/close-icon.svg"
          className="me-4 bg-white p-2 rounded-5"
          alt="close_icon"
        />
        {msg}
      </div>
    );
  },
  loading: (msg: string | ReactNode, id: any = undefined) => {
    if (id !== undefined) {
      return ReactToast.update(id, {
        render: msg,
        type: "info",
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
      });
    }

    return ReactToast.loading(
      <div className="d-flex align-items-center">
        <div className="spinner-border me-4" role="status">
          <span className="visually-hidden"></span>
        </div>
        {msg}
      </div>,
      {
        closeButton: true,
        closeOnClick: true,
      }
    );
  },
  info: (msg: string | ReactNode, id: any = undefined) => {
    if (id !== undefined) {
      return ReactToast.update(id, {
        render: (
          <>
            <div className="d-flex align-items-center">
              <img
                src="/assets/images/info-icon.svg"
                className="me-4 bg-white p-2 rounded-3"
                alt="info-icon"
                width={40}
                height={40}
              />
              {msg}
            </div>
          </>
        ),
        type: "info",
        isLoading: false,
        theme: "colored",
        autoClose: 5000,
        closeOnClick: true,
      });
    }

    return ReactToast.info(
      <div className="d-flex bg-yellow align-items-center">
        <img
          src="/assets/images/info-icon.svg" 
          className="me-4 bg-white p-2 rounded-5"
          alt="info-icon"
          width={40}
          height={40}
        />
        {msg}
      </div>,
      {
        closeButton: true,
        theme:"colored",
        style: { background: '#81A7FF' },
        progressStyle: { background: '#3867D6' },
        closeOnClick: true,
      }
    );
  },
};

export { toast as toastHelper };
export default toast;
