import React, { Component } from "react";
import { styled, withTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Button } from "react-bootstrap";
import { isAdmin } from "../../apis/is-admin";
import "../../style//header.scss";
import { getConnectDBs } from "../../apis/connect-db";
import { Link } from "react-router-dom";
import withRouter from "../../helpers/params";
import introJs from "intro.js";
import "intro.js/introjs.css";
import { getUserTourData, updateUserTourData } from "../../apis/header";
import "../../style/introjs.scss";
import { Modal, Tooltip } from "antd";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  borderRadius: 1,
  "&:hover": {
    borderRadius: 1,
    width: "100%",
  },
}));

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
interface HeaderProps {
  theme: Theme;
  pathKey: number;
  togglesidebar: boolean;
  handleOpen: () => void;
  handleClose: () => void;
}
interface HeaderState {
  show: Boolean;
  open: Boolean;
  tourStarted: Boolean;
  userTourData: any;
}

interface ListItemProps {
  to: string;
  target?: string;
  primary: string;
  active?: boolean;
  externalIcon?: string;
  icon: string;
}

class Header extends Component<HeaderProps, HeaderState> {
  constructor(props: HeaderProps) {
    super(props);
    this.state = {
      open: props.togglesidebar, // Initialize the 'open' state with the value from props
      show: false,
      tourStarted: false,
      userTourData: {
        chat_menu: true,
        embed_menu: true,
        connect_db: true,
        train_ai_model: true,
      },
    };
  }

  async componentDidMount() {
    try {
      const response = await isAdmin();
      if (response === "OK") {
        this.setState({ show: true });
      } else {
        this.setState({ show: false });
      }
      const getTourData: any = await getUserTourData();

      this.setState({
        userTourData: getTourData,
      });
      const fieldsToCheck = [
        "chat_menu",
        "embed_menu",
        "connect_db",
        "train_ai_model",
      ];

      const allFieldsFalse = fieldsToCheck.every(
        (field) => getTourData[field] === false
      );

      if (allFieldsFalse) {
        Modal.info({
          title:
            "Welcome to Neuralmind.io, the Product Analytics assistant for your customers to help them query their data.",
          icon: null,
          okButtonProps: {
            className: "btn-blue",
          },
          onOk: () => {
            this.startTour(this.props.pathKey, getTourData);
          },
          maskClosable: false,
          keyboard: false,
        });
      }

      // this.startTour(this.props.pathKey, getTourData);
    } catch (error) {
      console.log(error);
    }
  }

  startIntroJsTour = async (pathkey?: any, propertyName?: string) => {
    var listItems: any = [];
    // Define the intro.js steps and options for each custom list item
    if (pathkey === 1 || pathkey === 4) {
      listItems = [
        {
          title: "Train",
          element: ".list-item-train",
          intro:
            "Train your AI model with your specific content. It's equipped to manage queries about your product, FAQs, and any other information you provide for training.",
        },
        // {
        //   title: "Info",
        //   element: ".list-item-info",
        //   intro:
        //     "The information icon comes with docs and a video guide on how to navigate each section of the website.",
        // },
        {
          title: "Text",
          element: ".list-item-text",
          intro:
            "Construct your Knowledge Base by submitting the content that you want your AI model to absorb.",
        },
        {
          title: "Sync",
          element: ".list-item-sync",
          position: "left",
          intro:
            'After inputting your content, select "Sync with AI" to begin the learning process of the model.',
        },
        // {
        //   title:"Link",
        //   element: ".list-item-link",
        //   intro:
        //     " You can also import your existing Knowledge Base from a URL, prompting the AI model to commence learning automatically.",
        // },
      ];
    } else if (pathkey === 6) {
      listItems = [
        {
          title: "Database Details",
          element: ".connectdb-step-1",
          intro: "Enter your DB details to fetch data. ",
        },
        {
          title: "Table Details",
          element: ".connectdb-step-2",
          intro: "Enter your DB details to fetch data. ",
        },
        {
          title: "Insight Query",
          element: ".connectdb-step-3",
          intro: "Enter your DB details to fetch data. ",
        },
        // {
        //   title: "Going Live",
        //   element: ".connectdb-step-4",
        //   intro: "Paste the script provided to go live.",
        // },
      ];
    } else if (pathkey === 2) {
      listItems = [
        {
          title: "Insights",
          element: ".chat-item-step-2",
          intro:
            "We have connected a sample dataset for you to test the product, Ask questions on the data and our AI will produce results. You can also connect your own DB and verify your results.",
        },
        {
          title: "Ask",
          element: ".chat-item-step-1",
          intro:
            "AI assistant to help answer product related questions/FAQs for your customers.",
        },
        // {
        //   title: "Compose",
        //   element: ".chat-item-step-3",
        //   intro:
        //     "Helps you generate emails, paragraphs, blogs and more in an instant.",
        // },
        // {
        //   element: ".chat-item-step-4",
        //   intro:
        //     "Login to your favourite apps and query them to fetch notes or update tasks or get order details and much more!",
        // },
      ];
    } else if (pathkey === 8) {
      listItems = [
        {
          title: "Create An Embed",
          element: ".embed-item-step-1",
          intro:
            "Customise your embed before placing it in your software and going live.",
        },
        {
          title: "Preview",
          element: ".embed-item-step-2",
          intro:
            "Quick preview of how your embed will look inside your product.",
        },
        {
          title: "Live Embeds",
          element: ".embed-item-step-3",
          intro: " A list of your existing embeds.",
        },
        {
          title: "Iframe Embed Code             ",
          element: ".embed-item-step-4",
          intro: "Embed Code to be pasted inside your product.",
        },
      ];
    } else {
      return [];
    }

    // Create an intro.js instance
    const intro = introJs();

    // Attach the list item steps to the intro.js instance
    intro.setOptions({
      steps: listItems,
      showProgress: true,
      showBullets: false,
      showButtons: true,
      exitOnOverlayClick: false,
      keyboardNavigation: true,
      disableInteraction: true,
    });

    intro.oncomplete(async () => {
      // Update the corresponding property in userTourData and refresh the component
      if (propertyName) {
        const updatedData = await updateUserTourData({ [propertyName]: true });
        this.setState({ userTourData: updatedData });
      }
    });

    intro.start();
    this.setState({ tourStarted: false });
  };

  async componentDidUpdate(prevProps: HeaderProps, prevState: HeaderState) {
    const { pathKey } = this.props;
    const { tourStarted, userTourData } = this.state;

    // Check if pathKey has changed

    if (prevProps.pathKey !== pathKey) {
      // Call the tour starting function here based on conditions
      this.startTour(pathKey, userTourData);
    } else if (tourStarted) {
      this.startIntroJsTour(pathKey);
    }
  }

  startTour = (pathKey: any, getTourData: any) => {
    let propertyName = "";

    if (pathKey === 1 || pathKey === 4) {
      propertyName = "train_ai_model";
    } else if (pathKey === 6) {
      propertyName = "connect_db";
    } else if (pathKey === 2) {
      propertyName = "chat_menu";
    } else if (pathKey === 8) {
      propertyName = "embed_menu";
    }

    const { tourStarted } = this.state;

    if (propertyName && !getTourData[propertyName] && !tourStarted) {
      this.startIntroJsTour(pathKey, propertyName);
    }
  };

  CustomListItem = ({
    to,
    target,
    primary,
    active,
    icon,
    externalIcon,
  }: ListItemProps) => {
    const itemclass = `list-item-${primary.toLowerCase()}`;
    const currentPath = new URL(window.location.href).pathname;
    return (
      <Tooltip
        title={!this.state.open && primary}
        placement="right"
        color="geekblue"
      >
        <div
          style={{ width: "100%", paddingInline: "8px", marginBottom: "8px" }}
        >
          <Link
            onClick={() =>
              target
                ? window.open(to, "_blank")
                : primary == "Guided Tour"
                ? this.setState({ tourStarted: true })
                : null
            }
            to={!target ? to : currentPath}
            style={{
              border:
                this.state.open && active
                  ? "1px solid #CED8EF"
                  : !this.state.open
                  ? "1px solid #CED8EF"
                  : "",
            }}
            rel="noopener noreferrer"
            className={`${
              active ? "sidebar__active" : ""
            } fw-normal text-black  d-block text-decoration-none ml-2 rounded ${itemclass}`}
          >
            <ListItemButton
              sx={{
                justifyContent: this.state.open ? "initial" : "center",
                borderRadius: 1,
              }}
            >
              <img
                src={active ? icon + "-selected.svg" : icon + ".svg"}
                style={{ fill: "blue" }}
                alt=""
                width="18"
                height="18"
              />
              <ListItemText
                primary={
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "0.9rem", // Adjust the font sizes as needed
                      opacity: this.state.open ? 1 : 0,
                      ml: this.state.open ? 2 : 0,
                    }}
                  >
                    {primary}
                  </Typography>
                }
              />
              {externalIcon && this.state.open && (
                <img src={externalIcon} alt="" width="20" height="20" />
              )}
            </ListItemButton>
          </Link>
        </div>
      </Tooltip>
    );
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
    this.props.handleOpen(); // Call the handleOpen function passed via props
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
    this.props.handleClose(); // Call the handleClose function passed via props
  };

  render() {
    const { theme } = this.props;
    const { open } = this.state;

    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Drawer variant="permanent" open={!!open}>
          <DrawerHeader
            style={{
              display: open ? "flex" : "grid",
              marginTop: "10px",
              marginBottom: "30px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              className={
                open ? "mb-4 mt-2 text-center mx-2" : " mt-2 text-center"
              }
            >
              <Link to="/chat" style={{ cursor: "pointer" }}>
                <img
                  src="/assets/images/logo.svg"
                  className="img-fluid"
                  width="45"
                  height="45"
                />
              </Link>
            </div>
            <div
              style={{ border: "1px solid #CED8EF" }}
              className={` text-black  d-block  rounded mb-4 mt-2 text-center`}
            >
              <CustomIconButton
                onClick={
                  this.state.open
                    ? this.handleDrawerClose
                    : this.handleDrawerOpen
                }
              >
                <img
                  src={
                    !open
                      ? "/assets/images/collapse-icon.svg"
                      : "/assets/images/un-collapse-icon.svg"
                  }
                  style={{
                    cursor: "pointer",
                  }}
                  width="20"
                  height="20"
                />
              </CustomIconButton>
            </div>
          </DrawerHeader>
          {/* <Divider /> */}
          <div className="h-100">
            <div className="d-flex flex-column col justify-content-between h-100">
              <div className="d-flex flex-column align-items-stretch col">
                {open && (
                  <p
                    className="fw-bold ps-3 "
                    style={{ marginBottom: open ? "0px" : "" }}
                  >
                    Test and Go Live
                  </p>
                )}
                <this.CustomListItem
                  to="/chat"
                  primary="Chat"
                  active={this.props.pathKey === 2}
                  icon="/assets/images/chat-icon"
                />
                <this.CustomListItem
                  to="/embed"
                  primary="Embed"
                  active={this.props.pathKey === 8}
                  icon="/assets/images/embed-icon"
                />
                <hr style={{ marginTop: "0.2rem" }} />
                {open && (
                  <p
                    className="fw-bold ps-3 "
                    style={{ marginBottom: open ? "0px" : "" }}
                  >
                    Insights
                  </p>
                )}

                <this.CustomListItem
                  to="/connectdb"
                  primary="Connect DB"
                  active={this.props.pathKey === 6}
                  icon="/assets/images/db-icon"
                />
                <hr style={{ marginTop: "0.2rem" }} />
                <p
                  className="fw-bold ps-3"
                  style={{
                    visibility: open ? "visible" : "hidden",
                    display: open ? "block" : "none",
                    marginBottom: open ? "2px" : "0px",
                  }}
                >
                  Ask
                </p>
                <this.CustomListItem
                  to="/train"
                  primary="Train AI Model"
                  active={this.props.pathKey === 1 || this.props.pathKey === 4}
                  icon="/assets/images/train-icon"
                />
                <hr style={{ marginTop: "0.2rem" }} />

                {this.state.show && (
                  <>
                    <this.CustomListItem
                      to="/admin"
                      primary="Admin"
                      active={this.props.pathKey === 3}
                      icon="/assets/images/setting-icon"
                    />
                    <hr style={{ marginTop: "0.2rem" }} />
                  </>
                )}
              </div>
              <div>
                <this.CustomListItem
                  to={
                    this.props.pathKey === 1
                      ? "/train"
                      : this.props.pathKey === 6
                      ? "/connectdb"
                      : this.props.pathKey == 2
                      ? "/chat"
                      : this.props.pathKey == 8
                      ? "/embed"
                      : this.props.pathKey === 3
                      ? "/chat"
                      : "/train"
                  }
                  primary="Guided Tour"
                  icon="/assets/images/guided-icon"
                />
                <this.CustomListItem
                  to="https://docs.neuralmind.io/"
                  target="_blank"
                  primary="Docs"
                  icon="/assets/images/docs-icon"
                  externalIcon="/assets/images/external-icon.svg"
                />
                <this.CustomListItem
                  to="/admin/bills"
                  primary="Upgrade Plan"
                  icon="/assets/images/upgrade-icon"
                />
              </div>
            </div>
          </div>
        </Drawer>
      </Box>
    );
  }
}

export default withRouter(Header);
