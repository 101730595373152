import React from "react";
import { Button, Modal } from "react-bootstrap";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import {
  cancelSubscription,
  createSubscription,
  getSubscriptionDetails,
  updateubscription,
} from "../../apis/stripe";
import Usage from "./Usage";
import Wrapper from "../../helpers/stripe";
import { toastHelper } from "../../helpers/toast";
import "../../style/bill.scss";
import type { ColumnsType } from "antd/es/table";

interface BillProps {}
interface BillState {
  showAnnual: boolean;
  usageModalOpen: boolean;
  confirmModalOpen: boolean;
  loading: boolean;
  priceIdIndex: number;
  showPayment: boolean;
  clientSecret: string;
  subscriptionId: string;
  prevPriceIndex: number;
  prevSubsId: string;
  selectedYear: number;
  showCards: boolean;
  tableData: any[];
  showUpgrade: boolean;
  showCurrentPlan: boolean;
  showCancel: boolean;
  showCancelPopUp: boolean;
  subscriptionCancelled: boolean;
}

interface DataType {
  key: React.Key;
  name: string;
  value: string;
}

const priceIdArr = [
  //USD
  //Growth -> monthly -> yearly
  { priceId: "price_1MveSVSDEFpjrVfNCnBG1hAQ", cost: "$399", index: 0 },
  { priceId: "price_1MveSVSDEFpjrVfNsUuvRxeJ", cost: "$4000", index: 1 },
  //Pro
  { priceId: "price_1MveR7SDEFpjrVfNltVKTHvy", cost: "$699", index: 2 },
  { priceId: "price_1MveR7SDEFpjrVfNUrm9sfXm", cost: "$7000", index: 3 },
  //Enterrise
  { priceId: "price_1MveTBSDEFpjrVfNMLTexASD", cost: "$1299", index: 4 },
  { priceId: "price_1MveTBSDEFpjrVfN4i0jdLoX", cost: "$12500", index: 5 },
];
// const priceIdArr = [
//    //INR
//   //Growth -> monthly -> yearly
//   { priceId: "price_1MvgGCSDEFpjrVfNdO46JBR7", cost: "$399", index: 0 },
//   { priceId: "price_1MvgG3SDEFpjrVfN1VvOtA5Z", cost: "$4000", index: 1 },
//   //Pro
//   { priceId: "price_1MvgGhSDEFpjrVfNTUAKOVlP", cost: "$699", index: 2 },
//   { priceId: "price_1MvgGaSDEFpjrVfNJspBGRWk", cost: "$7000", index: 3 },
//   //Enterrise
//   { priceId: "price_1MvgFFSDEFpjrVfNzUdzWihW", cost: "$1299", index: 4 },
//   { priceId: "price_1MvgFMSDEFpjrVfNyPRVexe0", cost: "$12500", index: 5 },
// ]

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const ORDER_BUTTON_TEXT = "Choose Plan";

class Bills extends React.Component<BillProps, BillState> {
  years: number[];
  numberOfYearsToShow: number;
  constructor(props: BillProps) {
    var currentYear = new Date().getFullYear();

    super(props);
    this.state = {
      showAnnual: false,
      usageModalOpen: false,
      confirmModalOpen: false,
      loading: false,
      priceIdIndex: -1,
      showPayment: false,
      clientSecret: "",
      subscriptionId: "",
      prevPriceIndex: -1,
      prevSubsId: "",
      selectedYear: currentYear,
      showCards: true,
      tableData: [],
      showUpgrade: false,
      showCurrentPlan: false,
      showCancel: false,
      showCancelPopUp: false,
      subscriptionCancelled: false,
    };

    // Set the number of years you want to display in the dropdown
    this.numberOfYearsToShow = 2; // You can adjust this as needed
    // Generate an array of years starting from the current year
    this.years = Array.from(
      { length: this.numberOfYearsToShow },
      (_, index) => currentYear - index
    );
  }

  checkSubscription = async () => {
    try {
      let { subscription }: any = await getSubscriptionDetails();
      if (subscription.planStatus === "active") {
        let planPrice =
          subscription.planPrice + " " + subscription.currencyType;
        subscription = { ...subscription, planPrice };
        this.setState({ showCards: false });
        const data: any = [];
        let i = 1;
        for (let key in subscription) {
          if (
            ![
              "_id",
              "owner",
              "customer_id",
              "transactionIds",
              "currencyType",
              "createdAt",
              "__v",
              "planID",
              "subscription_id",
              "subscriptionItem_id",
              "cancelled",
            ].find((item) => item === key)
          ) {
            let newkey = key;
            switch (key) {
              case "expiresIn":
                newkey = "expires in";
                break;
              case "planType":
                newkey = "plan type";
                break;
              case "planPrice":
                newkey = "plan price";
                break;
              case "planPeriod":
                newkey = "plan period";
                break;
              case "planStatus":
                newkey = "plan status";
                break;
              case "expiryDate":
                newkey = "plan expiry date";
                break;
              case "updatedAt":
                newkey = "plan purchase date";
                break;
              default:
                break;
            }

            let obj = {
              key: i,
              name: newkey,
              value: subscription[key],
            };
            data.push(obj);
            i++;
          }
        }
        this.setState({ tableData: data, showUpgrade: true, showCancel: true });
        if (subscription.cancelled) {
          this.setState({ subscriptionCancelled: true });
        }
        return;
      } else {
        this.setState({ showCards: true });
        toastHelper.success("No plans are active currently");
        return;
      }
    } catch (err) {
      this.setState({ showCards: true });
      toastHelper.success("No plans are active currently");
      return;
    }
  };

  async handleUpdatePlan() {
    try {
      let res: any = await createSubscription(1);
      toastHelper.success("Redirecting to Stripe");
      window.location = res.sessionUrl;
      return true;
    } catch (error) {
      window.location.href = "/admin";
      toastHelper.error(
        "Error while redirecting to stripe, Please try again later"
      );
      return false;
    }
  }

  componentDidMount(): void {
    this.checkSubscription();
  }

  closeUsageModal = () => this.setState({ usageModalOpen: false });
  openUsageModal = () => this.setState({ usageModalOpen: true });

  closeConfrimModal = () => {
    this.setState({
      confirmModalOpen: false,
      showPayment: false,
      clientSecret: "",
      subscriptionId: "",
      priceIdIndex: -1,
    });
  };

  openConfrimModal = (key: number) => {
    this.setState({ priceIdIndex: key, confirmModalOpen: true });
  };

  confirmPlanChange = async () => {
    this.setState({ loading: true });

    try {
      const res: any = await createSubscription(this.state.priceIdIndex);
      this.setState({
        clientSecret: res.id,
        subscriptionId: res.id,
        showPayment: true,
        loading: false,
      });
      window.location = res.sessionUrl;
    } catch (err: any) {
      console.log(err);
      toastHelper.error(err.message);
      this.setState({ showPayment: false, loading: false });
    }
  };
  // Function to handle the change of the selected year
  handleYearChange = (event: any) => {
    const selectedYear = parseInt(event.target.value, 10);
    this.setState({ selectedYear });
  };

  handleShowUpgrade = () => {
    this.setState({
      showCards: true,
      showCurrentPlan: true,
      showUpgrade: false,
    });
  };
  handleShowCurrentPlan = () => {
    this.setState({
      showCards: false,
      showCurrentPlan: false,
      showUpgrade: true,
      showCancel: true,
    });
  };

  handleCancel = () => {
    this.setState({ showCancelPopUp: true });
  };

  closeCancelPopUp = () => {
    this.setState({ showCancelPopUp: false, showCancel: true });
    return;
  };

  confirmCancelPlan = async () => {
    try {
      await cancelSubscription();
      this.setState({
        showCancelPopUp: false,
        showCards: true,
        showCancel: false,
        showUpgrade: false,
        showCurrentPlan: true,
        subscriptionCancelled: true,
      });
      toastHelper.success("Subscription Cancelled Successfully");
      return;
    } catch (error) {
      console.log(error);
      toastHelper.error("Subscription could not be cancelled");
      return;
    }
  };

  render() {
    const { tableData } = this.state;

    const getPlanValue = (name: any) => {
      const item = tableData.find((item) => item.name === name);
      return item ? item.value : null;
    };

    const planType = getPlanValue("plan type");
    const planPeriod = getPlanValue("plan period");
    const planPrice = getPlanValue("plan price");
    const planExpiryDate = getPlanValue("plan expiry date");
    const planPurchaseDate = getPlanValue("plan purchase date");
    const planStatus = getPlanValue("plan status");

    const isActive = planStatus && planStatus.toLowerCase() === "active";
    const typeWithPeriod =
      planType && planPeriod
        ? `${planType}_${
            planPeriod.toLowerCase().includes("year") ? "_yearly" : "_monthly"
          }`
        : null;

    const priceValue = planPrice
      ? `$${parseFloat(planPrice).toFixed(2)} per ${
          planPeriod && planPeriod.toLowerCase().includes("year")
            ? "year"
            : "month"
        }`
      : null;

    const formattedExpiryDate = planExpiryDate
      ? new Date(planExpiryDate).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      : null;
    const formattedPurchaseDate = planPurchaseDate
      ? new Date(planPurchaseDate).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      : null;

    // const columns: ColumnsType<DataType> = [
    //   {
    //     title: "Name",
    //     dataIndex: "name",
    //     width: "225px",
    //     render: (name: string) => (
    //       <div style={{ textAlign: "right", fontStyle: "bold" }}>
    //         {name.toUpperCase()}
    //       </div>
    //     ),
    //   },
    //   {
    //     title: "Value",
    //     dataIndex: "value",
    //     width: "175px",
    //     render: (value: string, row: any) => {
    //       if (typeof value === "string") {
    //         if (
    //           row.name === "plan expiry date" ||
    //           row.name === "plan purchase date"
    //         ) {
    //           return (
    //             <b style={{ marginLeft: "10px" }}>
    //               {new Date(value).toLocaleDateString("en-us", {
    //                 year: "numeric",
    //                 month: "long",
    //                 day: "numeric",
    //               })}
    //             </b>
    //           );
    //         } else if (row.name === "plan status") {
    //           return (
    //             <b
    //               style={{
    //                 color: row.value === "active" ? "green" : "red",
    //                 marginLeft: "10px",
    //               }}
    //             >
    //               {value.toUpperCase()}
    //             </b>
    //           );
    //         }

    //         return <b style={{ marginLeft: "10px" }}>{value.toUpperCase()}</b>;
    //       } else {
    //         return <b style={{ marginLeft: "10px" }}>{value}</b>;
    //       }
    //     },
    //   },
    // ];
    return (
      <div className="bg-white h-100 p-3">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="fw-bold">Plans and Billing</h5>
          <div>
            <select
              value={this.state.selectedYear}
              onChange={this.handleYearChange}
              style={{
                border: "none",
                outline: "none",
                borderRadius: "5px",
                marginRight: "15px",
              }}
            >
              {this.years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
            <Button
              style={{ marginRight: "15px" }}
              className="btn-blue"
              onClick={this.openUsageModal}
            >
              Show usage
            </Button>

            {/* <Button style={{background:"rgb(50, 168, 92)",borderColor:"rgb(50, 168, 92)"}}>
              Plan Info
            </Button> */}
          </div>
        </div>
        {this.state.showCards ? (
          <>
            <div className="d-flex justify-content-center">
              <div className="d-flex">
                <h5 className="mx-2 my-0">Monthly</h5>
                <IOSSwitch
                  value={this.state.showAnnual}
                  onChange={(e) =>
                    this.setState({ showAnnual: e.target.checked })
                  }
                />
                <h5 className="mx-2 my-0">Annual</h5>
              </div>
              <button
                className="save-button"
                style={{
                  background: "#3e6fe1",
                  borderRadius: "30px",
                  color: "#ffffff",
                  fontSize: "16px",
                  width: "10%",
                  border: "none",
                  height: "30px",
                  marginBottom: "auto",
                }}
              >
                Save 15%
              </button>
            </div>
            <div className="bill__scroll me-2 mt-2">
              <div className="d-flex justify-content-around align-items-start p-3">
                {this.state.showAnnual ? (
                  <>
                    <div
                      className={`bill__plans p-3 px-4 shadow rounded mt-5 ${
                        this.state.prevPriceIndex == 1
                          ? "bill__plansActive"
                          : ""
                      }`}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src="/assets/images/bill1-icon.png"
                          className="rounded-3"
                          width="50"
                          height="50"
                          alt=""
                        />
                        <h5 className="fw-bold ms-2 mb-0">Growth</h5>
                      </div>
                      <div className="mt-5">
                        <p className="fw-bold mb-3 d-flex align-items-center">
                          <img src="/assets/images/tick-icon.svg" alt="" />
                          <span className="ms-2">
                            1.5 million words per month
                          </span>
                        </p>
                        <p className="fw-bold mb-3 d-flex align-items-center">
                          <img src="/assets/images/tick-icon.svg" alt="" />
                          <span className="ms-2">
                            300 docs and articles training
                          </span>
                        </p>
                        <p className="fw-bold mb-3 d-flex align-items-center">
                          <img src="/assets/images/tick-icon.svg" alt="" />
                          <span className="ms-2">Unlimited agents</span>
                        </p>
                        <p className="fw-bold mb-3 d-flex align-items-center">
                          <img src="/assets/images/tick-icon.svg" alt="" />
                          <span className="ms-2">Unlimited apps</span>
                        </p>
                      </div>
                      <div className="mt-5 text-center">
                        <h3 className="fw-bold">$5,999 USD yearly</h3>
                        <button
                          className="bill__btn"
                          onClick={() => this.openConfrimModal(4)}
                          // disabled={true}
                        >
                          {/* Choose Plan */}
                          {ORDER_BUTTON_TEXT}
                        </button>
                      </div>
                    </div>

                    <div
                      className={`bill__plans p-3 px-4 shadow rounded ${
                        this.state.prevPriceIndex == 3
                          ? "bill__plansActive"
                          : ""
                      }`}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src="/assets/images/bill2-icon.png"
                          className="rounded-3"
                          width="50"
                          height="50"
                          alt=""
                        />
                        <h5 className="fw-bold ms-2 mb-0">Pro</h5>
                      </div>
                      <div className="mt-5">
                        <p className="fw-bold mb-3 d-flex align-items-center">
                          <img src="/assets/images/tick-icon.svg" alt="" />
                          <span className="ms-2">
                            3 million words per month
                          </span>
                        </p>
                        <p className="fw-bold mb-3 d-flex align-items-center">
                          <img src="/assets/images/tick-icon.svg" alt="" />
                          <span className="ms-2">
                            500 docs and articles training
                          </span>
                        </p>
                        <p className="fw-bold mb-3 d-flex align-items-center">
                          <img src="/assets/images/tick-icon.svg" alt="" />
                          <span className="ms-2">Unlimited agents</span>
                        </p>
                        <p className="fw-bold mb-3 d-flex align-items-center">
                          <img src="/assets/images/tick-icon.svg" alt="" />
                          <span className="ms-2">Unlimited apps</span>
                        </p>
                      </div>
                      <div className="mt-5 text-center">
                        <h3 className="fw-bold">$7,499 USD yearly</h3>
                        <button
                          className="bill__btn button-black"
                          onClick={() => this.openConfrimModal(5)}
                          // disabled={true}
                        >
                          {/* Choose Plan */}
                          {ORDER_BUTTON_TEXT}
                        </button>
                      </div>
                    </div>

                    <div
                      className={`bill__plans p-3 px-4 shadow rounded mt-5 ${
                        this.state.prevPriceIndex == 5
                          ? "bill__plansActive"
                          : ""
                      }`}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src="/assets/images/bill1-icon.png"
                          className="rounded-3"
                          width="50"
                          height="50"
                          alt=""
                        />
                        <h5 className="fw-bold ms-2 mb-0">Enterprise</h5>
                      </div>
                      <div className="mt-5">
                        <p className="fw-bold mb-3 d-flex align-items-center">
                          <img src="/assets/images/tick-icon.svg" alt="" />
                          <span className="ms-2">
                            5 million words+ per month
                          </span>
                        </p>
                        <p className="fw-bold mb-3 d-flex align-items-center">
                          <img src="/assets/images/tick-icon.svg" alt="" />
                          <span className="ms-2">
                            1000+ docs and articles training
                          </span>
                        </p>
                        <p className="fw-bold mb-3 d-flex align-items-center">
                          <img src="/assets/images/tick-icon.svg" alt="" />
                          <span className="ms-2">Unlimited agents</span>
                        </p>
                        <p className="fw-bold mb-3 d-flex align-items-center">
                          <img src="/assets/images/tick-icon.svg" alt="" />
                          <span className="ms-2">Unlimited apps</span>
                        </p>
                      </div>
                      <div className="mt-5 text-center">
                        <h3 className="fw-bold">$9,999+ USD yearly</h3>
                        <button
                          className="bill__btn"
                          onClick={() => this.openConfrimModal(6)}
                          // disabled={true}
                        >
                          {/* Choose Plan */}
                          {ORDER_BUTTON_TEXT}
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className={`bill__plans p-3 px-4 shadow rounded mt-5 ${
                        this.state.prevPriceIndex == 0
                          ? "bill__plansActive"
                          : ""
                      }`}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src="/assets/images/bill1-icon.png"
                          className="rounded-3"
                          width="50"
                          height="50"
                        />
                        <h5 className="fw-bold ms-2 mb-0">Growth</h5>
                      </div>
                      <div className="mt-5">
                        <p className="fw-bold mb-3 d-flex align-items-center">
                          <img src="/assets/images/tick-icon.svg" alt="" />
                          <span className="ms-2">
                            1.5 million words per month
                          </span>
                        </p>
                        <p className="fw-bold mb-3 d-flex align-items-center">
                          <img src="/assets/images/tick-icon.svg" alt="" />
                          <span className="ms-2">
                            300 docs and articles training
                          </span>
                        </p>
                        <p className="fw-bold mb-3 d-flex align-items-center">
                          <img src="/assets/images/tick-icon.svg" alt="" />
                          <span className="ms-2">Unlimited agents</span>
                        </p>
                        <p className="fw-bold mb-3 d-flex align-items-center">
                          <img src="/assets/images/tick-icon.svg" alt="" />
                          <span className="ms-2">Unlimited apps</span>
                        </p>
                      </div>
                      <div className="mt-5 text-center">
                        <h3 className="fw-bold">$599 USD monthly</h3>
                        <button
                          className="bill__btn"
                          onClick={() => this.openConfrimModal(1)}
                          // disabled={true}
                        >
                          {/* Choose Plan */}
                          {ORDER_BUTTON_TEXT}
                        </button>
                      </div>
                    </div>

                    <div
                      className={`bill__plans p-3 px-4 shadow rounded ${
                        this.state.prevPriceIndex == 2
                          ? "bill__plansActive"
                          : ""
                      }`}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src="/assets/images/bill2-icon.png"
                          className="rounded-3"
                          width="50"
                          height="50"
                        />
                        <h5 className="fw-bold ms-2 mb-0">Pro</h5>
                      </div>
                      <div className="mt-5">
                        <p className="fw-bold mb-3 d-flex align-items-center">
                          <img src="/assets/images/tick-icon.svg" alt="" />
                          <span className="ms-2">
                            3 million words per month
                          </span>
                        </p>
                        <p className="fw-bold mb-3 d-flex align-items-center">
                          <img src="/assets/images/tick-icon.svg" alt="" />
                          <span className="ms-2">
                            500 docs and articles training
                          </span>
                        </p>
                        <p className="fw-bold mb-3 d-flex align-items-center">
                          <img src="/assets/images/tick-icon.svg" alt="" />
                          <span className="ms-2">Unlimited agents</span>
                        </p>
                        <p className="fw-bold mb-3 d-flex align-items-center">
                          <img src="/assets/images/tick-icon.svg" alt="" />
                          <span className="ms-2">Unlimited apps</span>
                        </p>
                      </div>
                      <div className="mt-5 text-center">
                        <h3 className="fw-bold">$749 USD monthly</h3>
                        <button
                          className="bill__btn button-black"
                          onClick={() => this.openConfrimModal(2)}
                          // disabled={true}
                        >
                          {/* Choose Plan */}
                          {ORDER_BUTTON_TEXT}
                        </button>
                      </div>
                    </div>

                    <div
                      className={`bill__plans p-3 px-4 shadow rounded mt-5 ${
                        this.state.prevPriceIndex == 4
                          ? "bill__plansActive"
                          : ""
                      }`}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src="/assets/images/bill1-icon.png"
                          className="rounded-3"
                          width="50"
                          height="50"
                        />
                        <h5 className="fw-bold ms-2 mb-0">Enterprise</h5>
                      </div>
                      <div className="mt-5">
                        <p className="fw-bold mb-3 d-flex align-items-center">
                          <img src="/assets/images/tick-icon.svg" alt="" />
                          <span className="ms-2">
                            5 million words+ per month
                          </span>
                        </p>
                        <p className="fw-bold mb-3 d-flex align-items-center">
                          <img src="/assets/images/tick-icon.svg" alt="" />
                          <span className="ms-2">
                            1000+ docs and articles training
                          </span>
                        </p>
                        <p className="fw-bold mb-3 d-flex align-items-center">
                          <img src="/assets/images/tick-icon.svg" alt="" />
                          <span className="ms-2">Unlimited agents</span>
                        </p>
                        <p className="fw-bold mb-3 d-flex align-items-center">
                          <img src="/assets/images/tick-icon.svg" alt="" />
                          <span className="ms-2">Unlimited apps</span>
                        </p>
                      </div>
                      <div className="mt-5 text-center">
                        <h3 className="fw-bold">$999+ USD monthly</h3>
                        <button
                          className="bill__btn"
                          onClick={() => this.openConfrimModal(3)}
                          // disabled={true}
                        >
                          {/* Choose Plan */}
                          {ORDER_BUTTON_TEXT}
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <h6
              className="mt-2"
              style={{ fontWeight: "600", position: "relative" }}
            >
              Current Plan
            </h6>
            <div
              className="mt-2"
              style={{ borderBottom: "1px solid lightgrey" }}
            ></div>
            <div className="d-flex  justify-content-between mt-2">
              <div className="left-content">
                <div className="plan-detail">
                  {typeWithPeriod && (
                    <div className="plan-name">
                      {typeWithPeriod}
                      <div
                        className={`chip-${isActive ? "active" : "inactive"}`}
                        style={{
                          display: "inline-block",
                          borderRadius: "15px",
                          padding: "1px 5px",
                          backgroundColor: isActive ? "green" : "red",
                          color: "white",
                          marginLeft: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {isActive ? "Active" : "Inactive"}
                      </div>
                    </div>
                  )}

                  {priceValue && (
                    <div
                      className="plan-value fw-bold"
                      style={{ fontSize: "20px" }}
                    >
                      {priceValue}
                    </div>
                  )}

                  {formattedExpiryDate && (
                    <div
                      style={{ fontSize: "14px" }}
                      className="plan-value mt-2"
                    >
                      Your plan renews on {formattedExpiryDate}
                    </div>
                  )}

                  {formattedPurchaseDate && (
                    <div style={{ fontSize: "14px" }} className="plan-value">
                      Purchased on {formattedPurchaseDate}
                    </div>
                  )}
                </div>
              </div>

              <div className="d-flex align-items-center">
                <button
                  className="btn-blue"
                  style={{
                    padding: "8px",
                  }}
                  onClick={this.handleUpdatePlan}
                >
                  Manage plan with stripe
                </button>
              </div>
            </div>
          </>
        )}
        <Modal show={this.state.usageModalOpen} onHide={this.closeUsageModal}>
          <Modal.Header className="border-0" />
          <Modal.Body className="px-4">
            <h3 className="fw-bold mb-4 model-heading">Usage</h3>
            {/* Dropdown to select the year */}
            <p>{this.state.selectedYear}</p>
            <Usage year={this.state.selectedYear} />
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-end border-0">
            <Button
              className="btn-blue btn-container"
              type="submit"
              onClick={() => {
                this.setState({ usageModalOpen: false });
              }}
            >
              Done
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.confirmModalOpen}
          onHide={this.closeConfrimModal}
        >
          <Modal.Header closeButton className="border-0" />
          {this.state.showPayment ? (
            <Modal.Body className="p-5 pt-2">
              <Wrapper
                clientSecret={this.state.clientSecret}
                subscriptionId={this.state.subscriptionId}
                cost={priceIdArr[this.state.priceIdIndex].cost}
              />
            </Modal.Body>
          ) : (
            <>
              <Modal.Body className="p-5">
                <h3 className="fw-bold text-center">Confirm plan</h3>
                <span>You will be redirected to stripe payments page</span>
                <br />
                <span>to complete the payment.</span>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-end border-0">
                <Button className="btn-green" onClick={this.confirmPlanChange}>
                  Continue
                  {this.state.loading && (
                    <div
                      className="spinner-border text-light ms-2"
                      role="status"
                    >
                      &nbsp; <span className="sr-only"></span>
                    </div>
                  )}
                </Button>
                <Button className="btn-black" onClick={this.closeConfrimModal}>
                  No
                </Button>
              </Modal.Footer>
            </>
          )}
        </Modal>
      </div>
    );
  }
}

export default Bills;
