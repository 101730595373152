import * as React from "react";
import withLocation from "../../helpers/location";
import Code from "../../helpers/code";
import ConnectToSlack from "./ConnectToSlack";
import NewAPIKey from "./NewApiKey";
import DbAPIKey from "./DbApi";
import "../../style/api.scss";

interface ApiProps {
  location: any;
}

interface ApiState {
  currTab: number;
}

class Api extends React.Component<ApiProps, ApiState> {
  constructor(props: ApiProps) {
    super(props);
    this.state = {
      currTab: this.props.location.state?.currTab
        ? this.props.location.state.currTab
        : 0,
    };
  }

  apiCode = `curl 'https://api.neuralmind.io/v1/query?model=test-model&query=Will AI takeover?' -H 'Content-Type: application/json' -H 'Authorization: Bearer YOUR_API_KEY'`;

  render() {
    return (
      <div className="bg-white h-100">
        {/* <div className="d-flex border-bottom">
          <div
            className="col-4"
            role="button"
            onClick={() => this.setState({ currTab: 0 })}
          >
            <h5
              className={`text-center mb-0 py-2 fw-bold ${this.state.currTab == 0 ? "api__activeTab" : ""}`}
            >
              API Key
            </h5>
          </div>
         <div
            className="col-4"
            role="button"
            onClick={() => this.setState({ currTab: 1 })}
          >
            <h5
              className={`text-center mb-0 py-2 fw-bold ${this.state.currTab == 1 ? "api__activeTab" : ""}`}
            >
              Slack Integration
            </h5>
          </div>
          <div
            className="col-4"
            role="button"
            onClick={() => this.setState({ currTab: 2 })}
          >
            <h5
              className={`text-center mb-0 py-2 fw-bold ${this.state.currTab == 2 ? "api__activeTab" : ""}`}
            >
              Connect DB API Key
            </h5>
          </div>
        </div> */}
        <div className="api__scroll p-3 me-2 mt-2">
          {this.state.currTab == 0 && (
            <>
              <NewAPIKey />
              {/* <Code
                code={this.apiCode}
                title="GET Request - Connect Neuralmind answers to any tool."
                text={[
                  "Note:",
                  "Model Name: All lowercase separated by a hyphen",
                  "Query: Lowercase and Uppercase words are supported",
                ]}
                link="https://docs.neuralmind.io/api/answers-api"
              /> */}
            </>
          )}
          {this.state.currTab == 1 && (
            <>
              <ConnectToSlack />
            </>
          )}
          {this.state.currTab == 2 && (
            <>
              <DbAPIKey />
            </>
          )}
        </div>
      </div>
    );
  }
}

export default withLocation(Api);
