import React, { Component } from "react";
import { Select, Typography, Box } from "@mui/material";
import { Modal, Form, Button } from "react-bootstrap";
import { Gear } from "react-bootstrap-icons";
import { ChatContent, ChatTextArea } from "./ChatBody";
import axiosPython from "../../helpers/axios-python";
import { toastHelper } from "../../helpers/toast";
import { styled, alpha } from "@mui/material/styles";
import { Button as MuiButton } from "@mui/material";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

interface TProps {
  handleDelete: (e: any) => void;
  handleChange: (e: any) => void;
  handleSubmit: (e: any) => void;
  handlePlugin: (e: any) => void;
  handleAppSelectChange: (e: any) => void;
  botname: string;
  path: string;
  currTab: number;
  selectedURL: string;
  embed: boolean;
  query: string;
  msgArr: {
    body: any;
    align: string;
  }[];
  loading: boolean;
  msgBodyRef: React.RefObject<HTMLDivElement>;
  appArr: any[];
  selectedApp: string;
  updateMsgArray: () => void;
  clearChat: (e: any) => void;
}

interface TState {
  isModalVisible: boolean;
  isLoggedIn: boolean;
  redirectUrl: string;
  checkingUser: boolean;
  selectedOption: any;
  appsMsg: { body: any; align: string }[];
  anchorEl: any;
  isToastShown: boolean;
}
interface Option {
  value: string;
  label: string;
  icon: string;
}

const options: Option[] = [
  {
    value: "https://app.asana.com",
    label: "Asana",
    icon: "assets/images/asana.svg",
  },
  {
    value: "https://slack-openai.langdock.com",
    label: "Slack",
    icon: "assets/images/slack.svg",
  },
  // {
  //   value: "https://notiontest-langchain.langdock.com",
  //   label: "Notion",
  //   icon: "assets/images/notion.svg",
  // },
  // {
  //   value: "https://confluencetest-openai.langdock.com",
  //   label: "Confluence",
  //   icon: "assets/images/confluence.svg",
  // },
  // {
  //   value: "https://zendesktestplugin-openai.langdock.com",
  //   label: "Zendesk",
  //   icon: "assets/images/zendesk.svg",
  // },
  // {
  //   value: "https://shopify-openai.langdock.com",
  //   label: "Shopify",
  //   icon: "assets/images/shopify.svg",
  // },
  // {
  //   value: "https://hubspot-3451-openai.langdock.com",
  //   label: "Hubspot",
  //   icon: "assets/images/hubsport.svg",
  // },
];
const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top", // Change to "top"
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: -120,
    minWidth: 300,
    marginLeft: 140,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
const StyledMuiButton = styled(MuiButton)({
  "&:hover": {
    backgroundColor: "transparent",
    // Set the background color to transparent for the hover state
  },
});
export default class Apps extends Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = {
      isModalVisible: false,
      isLoggedIn: false,
      redirectUrl: "",
      checkingUser: false,
      selectedOption: this.props?.selectedURL || "",
      appsMsg: this.props.msgArr,
      anchorEl: null,
      isToastShown: false,
    };
  }
  componentDidUpdate(prevProps: TProps) {
    // Check if the msgArr prop has changed
    if (this.props?.msgArr !== prevProps.msgArr) {
      // Reset the appsMsg state to the new msgArr value
      this.setState({ appsMsg: this.props?.msgArr });
    }
  }
  async componentDidMount() {
    if (this.props?.selectedURL !== "") {
      const res = await axiosPython
        .post("/check-user-login", {
          plugin_url: this.props?.selectedURL,
        })
        .catch((err) => err.response);

      if (res?.data?.result) {
        this.setState({ isLoggedIn: true });
      } else {
        this.setState({ isLoggedIn: false });
      }
    }
  }

  openModal = () => this.setState({ isModalVisible: true });
  closeModal = () => this.setState({ isModalVisible: false });

  handleAppChange = async (value: any) => {
    this.setState({ selectedOption: value });

    this.setState({ checkingUser: true });
    this.props.handlePlugin(value);
    try {
      const res = await axiosPython.post("/check-user-login", {
        plugin_url: value,
      });
      this.setState({ isToastShown: false });

      this.props.handleAppSelectChange(value);
      this.setState({ isLoggedIn: true, checkingUser: false });
    } catch (err: any) {
      this.setState({ checkingUser: false });
      const result = err.response.data.result;

      if (result === undefined) {
        toastHelper.error(err.message || "Somthing Went Wrong");
        return;
      }

      if (!err.response.data.result && !this.state.isToastShown) {
        toastHelper.info("You need to login to access this plugin");
        this.setState({ isToastShown: true });
        this.setState({
          redirectUrl: `${
            process.env.REACT_APP_PYTHON_ENGINE
          }login?plugin_url=${value}&user_id=${
            err.response.data.user_id
          }&currTab=${this.props.currTab}&chat=${this.props.path || ""}${
            this.props.botname ? `&botname=${this.props.botname}` : ""
          }`,
          isLoggedIn: false,
        });
      }
    }
  };

  handleLogout = async () => {
    this.setState({ checkingUser: true });
    this.props.clearChat("apps");
    // console.log(this.props);
    try {
      const res = await axiosPython.delete("/logout-from-plugin", {
        data: {
          plugin_url: this.props.selectedURL || this.props.selectedApp,
        },
      });

      this.props.handleAppSelectChange("");
      this.setState({
        checkingUser: false,
        isLoggedIn: false,
        appsMsg: [],
      });
      this.handleAppChange(this.state.selectedOption);
    } catch (err: any) {
      this.setState({ checkingUser: false });
      toastHelper.error(err.message || "Somthing Went Wrong");
    }
  };
  handleClick = (e: any) => {
    this.setState({ anchorEl: e.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleMenuItemClick = (option: string) => {
    if (option === this.state.selectedOption) {
      // User clicked on the currently selected app, no need to do anything
      this.setState({ anchorEl: null }); // Close the menu
    } else {
      const isLoggedIn = option === this.props.selectedApp;
      this.handleAppChange(option);
      this.setState({
        checkingUser: false,
        isLoggedIn: isLoggedIn,
        selectedOption: option,
        anchorEl: null, // Close the menu
        isToastShown: false,
      });
      this.props.updateMsgArray();

      // Call the handleAppSelectChange method from the parent component to update the selected app URL
      this.props.handleAppSelectChange(option);
    }
  };
  render() {
    const { anchorEl, selectedOption, appsMsg } = this.state;
    const open = Boolean(anchorEl);
    const {
      handleDelete,
      handleChange,
      handleSubmit,
      query,
      msgArr,
      loading,
      msgBodyRef,
      handleAppSelectChange,
      appArr,
    } = this.props;
    const selectedOptionData = options.find(
      (option) => option.value === selectedOption
    );

    return (
      <>
        {!this.state.isLoggedIn && (
          <>
            {/* <div className="text-end">
                <Gear
                  color="black"
                  size={18}
                  onClick={this.openModal}
                  role="button"
                />
              </div> */}
            <div className="d-flex flex-column col align-items-center">
              <div className="dropDownChat__msg rounded shadow p-5 mb-4 ">
                <Form.Group>
                  <Form.Label className="fs-6  label-communicate">
                    Select an app and start communicating with it
                  </Form.Label>
                  <Select
                    value={this.state.selectedOption}
                    onChange={(e) => this.handleAppChange(e.target.value)}
                    fullWidth
                    defaultValue={selectedOption}
                    placeholder="Select a value"
                    id="selected-menu"
                    sx={{
                      "& .MuiSvgIcon-root": {
                        marginRight: "10px",
                      },
                    }}
                    IconComponent={() => (
                      <img
                        style={{ cursor: "pointer", paddingRight: "10px" }}
                        src="/assets/images/arrow-down.svg"
                      ></img>
                    )}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected === "") {
                        return (
                          <span className="placeholder-company-selector">
                            Select an app
                          </span>
                        );
                      }
                      const selectedOptionData = options.find(
                        (option) => option.value === selected
                      );

                      return (
                        <>
                          <Box display="flex" alignItems="center">
                            <img src={selectedOptionData?.icon} alt="" />
                            <Typography variant="body1" ml={1}>
                              {selectedOptionData?.label}
                            </Typography>
                          </Box>
                        </>
                      );
                    }}
                  >
                    {options.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        selected={this.state.selectedOption === option.value}
                        onClick={() => this.handleMenuItemClick(option.value)}
                      >
                        <Typography variant="body1">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                style={{
                                  marginRight: "8px",
                                  maxWidth: "20px",
                                }}
                                src={option?.icon}
                                alt=""
                              />
                              <span>{option?.label}</span>
                            </div>
                          </div>
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </Form.Group>
                <div className="mt-2 d-flex align-items-center justify-content-center">
                  {!this.state.isLoggedIn && this.state.redirectUrl && (
                    <Button
                      className="login-style"
                      style={{
                        borderRadius: "5px",
                        border: "unset",
                        background:
                          "linear-gradient(132deg, #4776F1 0%, #1333B6 100%)",
                        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.15)",
                      }}
                      disabled={
                        !this.state.redirectUrl || this.state.checkingUser
                      }
                      href={this.state.redirectUrl}
                      // target="_blank"
                    >
                      Login
                    </Button>
                  )}

                  {this.state.checkingUser && (
                    <div
                      className="spinner-border text-dark ms-2"
                      role="status"
                    >
                      &nbsp; <span className="sr-only"></span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        <div
          className={"msg-drop    px-3"}
          style={{ marginBottom: "50px" }}
          ref={msgBodyRef}
        >
          {this.state.isLoggedIn && (
            <>
              <span className="msg-style rounded shadow py-2 px-3 mb-4 align-items-center texting">
                Welcome! Let's start chatting with{" "}
                {selectedOptionData ? selectedOptionData.label : "an app"} .
                Here's a quick{" "}
                <a
                  href="https://docs.neuralmind.io/apps/third-party-apps" // The guide link
                  target="_blank" // Open the link in a new tab
                  rel="noopener noreferrer" // Recommended for security
                  className="guide-link" // Add a CSS class for styling (optional)
                >
                  guide
                </a>{" "}
                to get you started.{" "}
              </span>
              <ChatContent
                embed={this.props.embed}
                loading={loading}
                msgArr={this.state.appsMsg}
                msgBodyRef={msgBodyRef}
              />
            </>
          )}
        </div>
        {this.state.isLoggedIn && (
          <div
            className={`mt-2 align-center ${
              this.props.embed ? "embed-style" : "logout-button-apps"
            } `}
          >
            <div className="content-logout">
              <StyledMuiButton
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                disableRipple
                aria-expanded={open ? "true" : undefined}
                onClick={this.handleClick}
                sx={{ textTransform: "unset" }}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={(e) => e.stopPropagation()} // Disable click for the icon and label
                  style={{
                    marginRight: "5px",
                    cursor: "not-allowed",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span>
                    <img
                      src={selectedOptionData?.icon}
                      alt=""
                      style={{ cursor: "default" }}
                    />
                  </span>
                  <span
                    onClick={(e) => e.stopPropagation()} // Disable click for the icon and label
                    style={{
                      cursor: "default",
                      marginRight: 8,
                      color: "black",
                    }}
                  >
                    {selectedOptionData?.label}
                  </span>
                </div>
                <span>Change app</span>
              </StyledMuiButton>
              <StyledMenu
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={this.handleClose}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    selected={this.state.selectedOption === option.value}
                    onClick={() => this.handleMenuItemClick(option.value)}
                  >
                    <Typography variant="body1">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <img
                            style={{
                              marginRight: "8px",
                              maxWidth: "20px",
                            }}
                            src={option?.icon}
                            alt=""
                          />
                          <span>{option?.label}</span>
                        </div>
                      </div>
                    </Typography>
                  </MenuItem>
                ))}
              </StyledMenu>
            </div>
            <div>
              <StyledMuiButton
                onClick={this.handleLogout}
                style={{ textTransform: "capitalize" }}
              >
                <img
                  src="/assets/images/logout.svg"
                  alt="Logout"
                  style={{ marginRight: "8px" }}
                />
                <span className="logout-text">Logout</span>
              </StyledMuiButton>
            </div>
          </div>
        )}
        <ChatTextArea
          botname={this.props.botname || ""}
          embed={this.props.embed}
          handleChange={handleChange}
          handleDelete={handleDelete}
          handleSubmit={handleSubmit}
          query={query}
          style={
            this.state.isLoggedIn
              ? { opacity: 1 }
              : { opacity: 0.6, pointerEvents: "none" }
          }
        />

        <Modal show={this.state.isModalVisible} onHide={this.closeModal}>
          <Modal.Header closeButton />
          <Modal.Body>
            <Form.Group>
              <Form.Label>Change Credentials</Form.Label>
              <Form.Select>
                <option value="1">1</option>
              </Form.Select>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-end border-0">
            <Button variant="danger">Cancel</Button>
            <Button className="btn-black">Confirm</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
