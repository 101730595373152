import * as React from "react";
import { Button, Modal } from "react-bootstrap";

import toast from "../../helpers/toast";
import { deleteDbAPI, getDbAPIs, postDbAPI } from "../../apis/db-api";
import DeleteModal from "../../helpers/delete-modal";

interface DbAPIKeyProps { }

interface DbAPIKeyState {
  apis: any[];
  newAPIKey: string;
  newAPIKeyLoading: boolean;
  apiKeyDeleteLoading: boolean;
  openDeleteModal: boolean;
  deleteId: string;
  newModalOpen: boolean;
}

class DbAPIKey extends React.Component<DbAPIKeyProps, DbAPIKeyState> {
  constructor(props:DbAPIKeyProps){
    super(props);
    this.state = {
      apis: [],
      newAPIKey: "",
      newAPIKeyLoading: false,
      apiKeyDeleteLoading: false,
      openDeleteModal: false,
      deleteId: "",
      newModalOpen: false
    }
  }

  generateKey = async () => {
    try {
      this.setState({ newAPIKeyLoading: true });
      const response: any = await postDbAPI({});
      await this.getData();
      this.setState({ newAPIKey: response.apiKey, newAPIKeyLoading: false, newModalOpen: false });
      toast.success("New API key created.");
    } catch (error) {
      this.setState({ newAPIKeyLoading: false });
      console.log(error);
    }
  };

  getData = async () => {
    try {
      const response: any = await getDbAPIs();      
      this.setState({ apis: response.dbSchemas });
    } catch (error) {
      console.log(error);
    }
  };

  deleteAPIKey = async (_id: string) => {
    try {
      this.setState({ apiKeyDeleteLoading: true });
      await deleteDbAPI({ _id });
      await this.getData();
      this.setState({ apiKeyDeleteLoading: false, openDeleteModal: false });
      toast.success("API key has been deleted.");
    } catch (error) {
      console.log(error);
    }
  }

  async componentDidMount() {
    try {
      this.getData();
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <div>
        <div>
          <p className="fs-5">Database API Key</p>

          <div className="d-flex align-items-center">
            <div className="api__key p-2 rounded">
              <p className="mb-0">{this.state.newAPIKey}</p>
            </div>
            <div
              role="button"
              className="ms-3"
              onClick={() => {
                if(this.state.newAPIKey == "") 
                  return toast.error("Nothing to copy.");
                
                navigator.clipboard.writeText(this.state.newAPIKey);
                toast.success("Copied to clipboard.");
              }}
            >
              <img src="/assets/images/copy-icon.svg" />
            </div>
          </div>

          <Button
            className="btn-green mt-3"
            onClick={() => this.setState({ newModalOpen: true })}
            disabled={this.state.newAPIKeyLoading}
          >
            Create new DB API key
            {this.state.newAPIKeyLoading && (
              <div className="spinner-border text-light ms-2" role="status">
                &nbsp; <span className="sr-only"></span>
              </div>
            )}
          </Button>
        </div>
        <div className="mt-5">
          <p className="fs-5">Past Keys:</p>
          <div>
            {this.state.apis.map((value: any) => (
              <div className="d-flex mb-3">
                <div className="api__key p-2 rounded">
                  <p className="mb-0">
                    Created at {new Date(value.createdAt).getDate()}<sup>th</sup>{" "}
                    {new Date(value.createdAt).toLocaleString("default", {
                      month: "long",
                    })}{" "}
                    {new Date(value.createdAt).getFullYear()}
                  </p>
                </div>
                <Button
                  className="ms-3"
                  variant="danger"
                  onClick={() =>
                    this.setState({ deleteId: value._id, openDeleteModal: true })
                  }
                >
                  Delete
                </Button>
              </div>
            ))}
          </div>
        </div>
        <DeleteModal
          open={this.state.openDeleteModal}
          close={() => this.setState({ openDeleteModal: false })}
          deleteAction={async () => this.deleteAPIKey(this.state.deleteId)}
          loading={this.state.apiKeyDeleteLoading}
          title="Delete API Key?"
          subtitle="Note: you will lose all active integrations if you proceed."
        />
        <Modal
          show={this.state.newModalOpen}
          onHide={() => this.setState({ newModalOpen: false })}
        >
          <Modal.Header closeButton className="border-0" />
          <Modal.Body className="p-5">
            <h3 className="fw-bold text-center">
              Generate a new API key?
            </h3>
            <p className="fw-bold text-center mt-4">Note: Please keep your API key safe and do not share publicly.</p>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-end border-0">
            <Button
            className="btn-green" 
            onClick={() => {
              this.setState({ newModalOpen: false });
              this.generateKey();
            }}
            >
              Yes, create
            </Button>
            <Button className="btn-black" onClick={() => this.setState({ newModalOpen: false })}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default DbAPIKey;
