import { parse } from "himalaya";
import { getBlock } from "./get-block";

export const csvFileConvert = async (data: any) => {
  const id = data.ID;
  const category = data.Category;
  const folder = data.Folder;
  const title = data.Title;
  const description = data.Description;

  const json = parse(description);

  const dataToJSON: any = {
    time: "1234567890",
    blocks: [],
  };

  function recursion(value: any) {
    if (
      value.tagName === "h1" ||
      value.tagName === "h2" ||
      value.tagName === "h3" ||
      value.tagName === "h4" ||
      value.tagName === "h5" ||
      value.tagName === "h6" ||
      value.tagName === "p" ||
      value.tagName === "img" ||
      value.tagName === "ul" ||
      value.tagName === "ol" ||
      value.tagName === "table" ||
      value.tagName === "code" ||
      value.tagName === "strong" ||
      value.tagName === "a"
    ) {
      dataToJSON.blocks.push(getBlock(value));
    }
    if (value.type === "text") {
      dataToJSON.blocks.push(getBlock({ ...value, tagName: "text" }));
    }
    if (value.children !== undefined && value.children.length > 0) {
      for (let index = 1; index < value.children.length - 1; index++) {
        const child = value.children[index];
        recursion(child);
      }
    }
  }

  for (let j = 0; j < json.length; j++) {
    const object = json[j];
    recursion(object);
  }

  if (dataToJSON.blocks.length) {
    console.log(JSON.stringify(dataToJSON));
  }

  return dataToJSON;
};
