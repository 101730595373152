import axios from "../helpers/axios";
import { getCookie } from "../helpers/cookie";

export const getAPIs = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.get(
        process.env.REACT_APP_NODE_REST_API + "api/v1/api"
      );
      resolve(response.data);
    } catch (error: any) {
      resolve(error?.response?.data?.message);
    }
  });
};

export const getRESAPIEndPoint = () => `${process.env.REACT_APP_NODE_REST_API}`;

export const getAPIById = async (_id: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.get(`/api/v1/api/${_id}`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const postAPI = async (api: any) => {
  api.owner = getCookie("company_id");
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.post("/api/v1/api", api);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteAPI = async (api: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.delete("/api/v1/api/" + api._id);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const putAPI = async (api: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: any = await axios.put("/api/v1/api", api);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};
