import React, { Component } from "react";

import Ask from "./Ask";
import Apps from "./Apps";
import Compose from "./Compose";
import { toastHelper } from "../../helpers/toast";
import { getArticles } from "../../apis/article";
import axiosPython from "../../helpers/axios-python";

import "../../style/dropDownChat.scss";
import { getAction, getLength, getTone } from "../../helpers/tools-selector";
import Insights from "./insights";
import axiosNode from "../../helpers/funcs/axiosNode";
import DatasetModal from "./Dataset";
import DBDataset from "../../interfaces/db-dataset";

interface TProps {
  botname?: string;
  path?: string;
  disabled?: boolean;
  initialMsg?: string;
  showModalOption?: boolean;
  embed?: any;
  insightMsg?: string;
}

interface msgType {
  body: any;
  align: string;
  data?: any;
  chartData?: any;
}

interface TState {
  selectedURL: string;
  currTab: number;
  isTyped: any;

  // newResponseReceived: any;
  msgArr: {
    compose: msgType[];
    apps: msgType[];
    ask: msgType[];
    insights: msgType[];
  };
  query: string;
  selectedPlugin: string;
  loading: boolean;
  tone: number;
  action: number;
  length: number;
  isToolVisible: boolean;
  validationErr: string;
  modelArr: any[];
  selectedModel: any;
  appArr: any[];
  selectedApp: string;
  showDataset: boolean;
  dataset: DBDataset;
}

export default class DropDownChat extends Component<TProps, TState> {
  msgBodyRef = React.createRef<HTMLDivElement>();
  constructor(props: TProps) {
    super(props);
    this.state = {
      selectedURL: "",
      currTab: 3,
      isTyped: {
        insights: false,
        ask: false,
        compose: false,
      },
      // newResponseReceived: "",
      msgArr: {
        compose: [],
        apps: [],
        ask: [],
        insights: [],
      },
      query: "",
      selectedPlugin: "",
      loading: false,
      tone: -1,
      action: -1,
      length: -1,
      isToolVisible: true,
      validationErr: "",
      modelArr: [],
      selectedModel: undefined,
      appArr: [],
      selectedApp: "",
      showDataset: false,
      dataset: {},
    };
  }

  componentDidMount(): void {
    this.fetchModels();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const currTabParam = urlSearchParams.get("currTab");
    const selectedURL = urlSearchParams.get("url") || "";
    if (currTabParam !== null) {
      this.setState({
        currTab: parseInt(currTabParam, 10),
        selectedURL: selectedURL,
      });
    }
  }

  fetchModels = async () => {
    try {
      const res: any = await getArticles();
      this.setState({
        modelArr: res.articles,
        selectedModel: res.articles[0]._id,
      });
    } catch (err) {
      toastHelper.error("Could not fetch models!");
    }
  };

  getMsgType = (currTab: number) => {
    let type: "ask" | "compose" | "apps" | "insights" = "ask";
    if (currTab === 0) type = "ask";
    else if (currTab === 1) type = "compose";
    else if (currTab === 2) type = "apps";
    else if (currTab === 3) type = "insights";

    return type;
  };
  handleArrowButtonClick = (value: any) => {
    this.setState({ query: value });
    this.handleSubmit();
    this.setState({ query: "" });
  };

  handleDatasetArrowButtonClick = async () => {
    let response = await axiosNode.post("/insights/dataset", {});
    this.setState({ dataset: response.data });
    this.setState({ showDataset: true });
    console.log(this.state.showDataset);
  };

  searchQuery = async () => {
    try {
      this.setState({ loading: true });

      let url = "";
      let payload: any = {};
      const type = this.getMsgType(this.state.currTab);

      if (this.state.currTab === 0) {
        url = "/chat";
        payload = {
          query: this.state.query,
          model_id: this.state.selectedModel,
          conversation: this.state.msgArr?.ask?.map((msg) => ({
            role: msg.align === "right" ? "user" : "assistant",
            content: msg.body,
          })),
        };
      } else if (this.state.currTab === 1) {
        url = "/compose";
        payload = {
          message: this.state.query,
          tone: getTone(this.state.tone),
          length: getLength(this.state.length),
          format: getAction(this.state.action),
          conversation: this.state.msgArr?.compose?.map((msg) => ({
            role: msg.align === "right" ? "user" : "assistant",
            content: msg.body,
          })),
        };
      } else if (this.state.currTab === 2) {
        url = "/chat-plugin";
        payload = {
          query: this.state.query,
          plugin_url: this.state.selectedPlugin,
          conversation: this.state.msgArr?.apps?.map((msg) => ({
            role: msg.align === "right" ? "user" : "assistant",
            content: msg.body,
          })),
        };
      } else if (this.state.currTab === 3) {
        url = `/insights/chat`;
        payload = {
          query: this.state.query,
          conversation: this.state.msgArr?.insights?.map((msg) => ({
            role: msg.align === "right" ? "user" : "assistant",
            content: msg.body,
          })),
        };
      }
      let response;
      console.log(url);
      if (this.state.currTab === 3) {
        response = await axiosNode.post(url, payload);
      } else {
        response = await axiosPython.post(url, payload);
      }

      if (this.state.currTab != 3) {
        let tmpMsgArr = { ...this.state.msgArr };
        tmpMsgArr[type].push({
          body: response.data,
          align: "left",
        });

        this.setState(
          {
            msgArr: tmpMsgArr,
            loading: false,
            query: "",
            isTyped: {
              ...this.state.isTyped,
              [type]: false, // Set isTyped for the current tab to false
            },
          },
          this.scrollToLatestMSg
        );
      } else {
        let tmpMsgArr = { ...this.state.msgArr };
        tmpMsgArr[type].push({
          body: response.data.message,
          data: response?.data.data,
          chartData: response?.data.chartData || [],
          align: "left",
        });
        this.setState(
          {
            msgArr: tmpMsgArr,
            loading: false,
            query: "",
            isTyped: {
              ...this.state.isTyped,
              [type]: false,
            },
          },
          this.scrollToLatestMSg
        );
      }
    } catch (err: any) {
      this.setState({ loading: false });
      toastHelper.error(err.message || "Something went wrong!");
    }
  };

  scrollToLatestMSg = () => {
    if (
      !this.msgBodyRef ||
      !this.msgBodyRef.current ||
      !this.msgBodyRef.current.children ||
      !this.msgBodyRef.current.children.length
    )
      return;

    let childArr = this.msgBodyRef.current.children;
    childArr[childArr.length - 1].scrollIntoView();
  };

  handleDelete = () => {
    const type = this.getMsgType(this.state.currTab);
    const tmpMsgArr = { ...this.state.msgArr };
    tmpMsgArr[type] = [];
    this.setState({ msgArr: tmpMsgArr, query: "" });
    if (this.state.currTab === 1)
      this.setState({ tone: -1, length: -1, action: -1 });
  };

  handleChange = (e: any) => {
    // e.target.style.height = "30px";
    // e.target.style.height = e.target.scrollHeight + "px";
    if (e?.target?.value?.length > 1000) return;

    this.setState({ query: e.target?.value });
  };

  isInValid = (e: any) => {
    if (!e.target.value.trim()) return "Please Select Tone, Action and Length";
    if (this.state.tone === -1) return "Select a tone";
    if (this.state.action === -1) return "Select an action";
    if (
      this.state.length === -1 &&
      this.state.action !== 3 &&
      this.state.action !== 4 &&
      this.state.action !== 5
    )
      return "Select a length";

    return "";
  };

  handleSubmit = (e?: any) => {
    if (this.props.disabled) return;
    // if (!e.target.value) {
    //   // toastHelper.error("Enter Something!");
    //   return;
    // }
    var keyCode: any;
    if (e) {
      keyCode = e.which || e.keyCode;
    }

    if (e && (e.type === "click" || (keyCode === 13 && !e.shiftKey))) {
      e.preventDefault();
      const err =
        this.state.currTab === 1 && e.type !== "click" ? this.isInValid(e) : "";
      if (err) {
        this.setState({ isToolVisible: true, validationErr: err });
        setTimeout(() => {
          this.setState({ validationErr: "" });
        }, 3000);
        return;
      }

      const type = this.getMsgType(this.state.currTab);
      let tmpArr = { ...this.state.msgArr };
      tmpArr[type].push({
        body: this.state.query,
        align: "right",
      });

      e.target.value = "";
      this.setState(
        {
          msgArr: tmpArr,
          isToolVisible: false,
        },
        this.scrollToLatestMSg
      );

      this.searchQuery();
      // this.setState({ newResponseReceived: "" });
    } else if (e === undefined) {
      const type = this.getMsgType(this.state.currTab);
      let tmpArr = { ...this.state.msgArr };
      tmpArr[type].push({
        body: this.state.query,
        align: "right",
      });

      this.setState(
        {
          msgArr: tmpArr,
          isToolVisible: false,
        },
        this.scrollToLatestMSg
      );
      this.searchQuery();
      // this.setState({ newResponseReceived: "" });
    }
  };

  handleToolChange = (type: string, val: number) => {
    if (type === "tone") this.setState({ tone: val });
    else if (type === "length") this.setState({ length: val });
    else if (type === "action") this.setState({ action: val });
  };

  openTools = () => {
    this.setState({ isToolVisible: true });
  };

  closeTools = () => {
    this.setState({ isToolVisible: false });
  };

  handleModelChange = (val: string) => {
    const { _id: selectedModelId } = this.state.modelArr.find(
      (model) => model.model_name === val
    );
    this.setState({ selectedModel: selectedModelId });
  };

  handleTabChange = (tab: number) => {
    this.setState({
      currTab: tab,
      query: "",
    });
  };

  clearChat = (tab: string) => {
    if (tab === "insights") this.state.msgArr.insights = [];
    else if (tab === "compose") this.state.msgArr.compose = [];
    else if (tab === "apps") this.state.msgArr.apps = [];
    else if (tab === "ask") this.state.msgArr.ask = [];
  };
  handleAppSelectChange = (val: string) => {
    this.setState({ selectedApp: val });
  };

  render() {
    return (
      <div className="dropDownChat bg-white shadow h-100 w-100">
        <div className="d-flex flex-column h-100">
          <div>
            <div className="bg-white mb-3">
              <div className="p-2 px-3 d-flex align-items-center justify-content-between">
                <button
                  className={`dropDownChat__btn  ${
                    this.state.currTab === 3 ? "dropDownChat__btn-active " : ""
                  }`}
                  onClick={() => this.handleTabChange(3)}
                >
                  &nbsp;
                  <p>
                    Insights <span></span>
                  </p>
                </button>
                <button
                  className={`dropDownChat__btn  ${
                    this.state.currTab === 0 ? "dropDownChat__btn-active " : ""
                  }`}
                  onClick={() => this.handleTabChange(0)}
                >
                  &nbsp;
                  <p>
                    Ask <span></span>
                  </p>
                </button>
                <button
                  className={`dropDownChat__btn  ${
                    this.state.currTab === 1 ? "dropDownChat__btn-active " : ""
                  }`}
                  onClick={() => this.handleTabChange(1)}
                >
                  &nbsp;
                  <p>
                    Compose <span></span>
                  </p>
                </button>
              </div>
            </div>
          </div>
          {this.state.currTab === 0 && (
            <Ask
              botname={this.props.botname || ""}
              isTyped={this.state.isTyped.ask}
              setIsTyped={(value: boolean) =>
                this.setState({
                  isTyped: { ...this.state.isTyped, ask: value },
                })
              }
              handleChange={this.handleChange}
              handleArrowButtonClick={this.handleArrowButtonClick}
              embed={this.props.embed}
              handleDelete={this.handleDelete}
              handleSubmit={this.handleSubmit}
              query={this.state.query}
              msgArr={this.state.msgArr.ask}
              loading={this.state.loading}
              msgBodyRef={this.msgBodyRef}
              initialMsg={this.props.initialMsg}
              showModalOption={this.props.showModalOption}
              handleModelChange={this.handleModelChange}
              modelArr={this.state.modelArr}
            />
          )}
          {this.state.currTab === 1 && (
            <Compose
              embed={this.props.embed}
              isTyped={this.state.isTyped.compose}
              setIsTyped={(value: boolean) =>
                this.setState({
                  isTyped: { ...this.state.isTyped, compose: value },
                })
              }
              handleChange={this.handleChange}
              handleDelete={this.handleDelete}
              handleSubmit={this.handleSubmit}
              query={this.state.query}
              msgArr={this.state.msgArr.compose}
              loading={this.state.loading}
              msgBodyRef={this.msgBodyRef}
              tone={this.state.tone}
              length={this.state.length}
              action={this.state.action}
              validationErr={this.state.validationErr}
              isToolVisible={this.state.isToolVisible}
              handleToolChange={this.handleToolChange}
              openTools={this.openTools}
              closeTools={this.closeTools}
            />
          )}
          {this.state.currTab === 2 && (
            <Apps
              botname={this.props.botname || ""}
              path={this.props.path || ""}
              currTab={this.state.currTab}
              selectedURL={this.state.selectedURL}
              embed={this.props.embed}
              handleChange={this.handleChange}
              clearChat={this.clearChat}
              handleDelete={this.handleDelete}
              handleSubmit={this.handleSubmit}
              query={this.state.query}
              handlePlugin={(plugin_url: string) =>
                this.setState({ selectedPlugin: plugin_url })
              }
              msgArr={this.state.msgArr.apps}
              loading={this.state.loading}
              msgBodyRef={this.msgBodyRef}
              handleAppSelectChange={this.handleAppSelectChange}
              appArr={this.state.appArr}
              selectedApp={this.state.selectedApp}
              updateMsgArray={() =>
                this.setState({
                  msgArr: {
                    compose: [],
                    apps: [],
                    ask: [],
                    insights: [],
                  },
                })
              }
            />
          )}
          {this.state.currTab === 3 && (
            <Insights
              isTyped={this.state.isTyped.insights}
              setIsTyped={(value: boolean) =>
                this.setState({
                  isTyped: { ...this.state.isTyped, insights: value },
                })
              }
              botname={this.props.botname || ""}
              path={this.props.path || ""}
              currTab={this.state.currTab}
              embed={this.props.embed}
              handleChange={this.handleChange}
              handleDelete={this.handleDelete}
              handleSubmit={this.handleSubmit}
              clearChat={this.clearChat}
              query={this.state.query}
              handleArrowButtonClick={this.handleArrowButtonClick}
              handleDatasetArrowButtonClick={this.handleDatasetArrowButtonClick}
              msgArr={this.state.msgArr.insights}
              loading={this.state.loading}
              msgBodyRef={this.msgBodyRef}
              initialMsg={this.props.insightMsg}
              handleModelChange={this.handleModelChange}
              modelArr={this.state.modelArr}
            />
          )}
        </div>
        {this.state.showDataset ? (
          <DatasetModal
            data={this.state.dataset}
            isOpen={this.state.showDataset}
            handleOnClose={() => this.setState({ showDataset: false })}
          />
        ) : null}
      </div>
    );
  }
}
