import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

interface DeleteModalProps {
  deleteAction: Function;
  open: boolean;
  close: Function;
  loading: boolean;
  agent?: boolean;
  title: string,
  subtitle?: string;
}

interface DeleteModalState { }

class DeleteModal extends React.Component<DeleteModalProps, DeleteModalState> {
  state = {};

  deleteAction() {
    this.props.deleteAction();
  }

  render() {
    return (
      <>
        <Modal
          show={this.props.open}
          onHide={() => this.props.close()}
        >
          <Modal.Header  className="border-0" />
          <Modal.Body className="p-3">
            <h3 className="model-heading">
              {this.props.title}
            </h3>
            { 
              this.props.subtitle && !this.props.agent ?
              (
                <div className="err-container">
                  <p className="err-text"><img src="/assets/images/red-info-icon.svg" className="me-2" alt="" />{this.props.subtitle}</p>
                </div>
              ):
              <p className="fw-bold">{this.props.subtitle}</p>
            }
          </Modal.Body>
          <hr className="mx-2 " />
          <Modal.Footer className="d-flex justify-content-end border-0">
            <Button className="btn-outline btn-container" onClick={() => this.props.close()}>
            <img src="/assets/images/blue-cross-icon.svg" className="me-2" alt="" />Cancel
            </Button>
            <Button variant="danger" className="btn-container" onClick={() => this.deleteAction()}>
            <img src="/assets/images/delete-icon.svg" className="me-2" alt="" />Delete
              {this.props.loading && (
                <div className="spinner-border text-light ms-2" role="status">
                  &nbsp; <span className="sr-only"></span>
                </div>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default DeleteModal;
