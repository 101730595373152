export const getTone = (i: number) => {
    if(i === -1) return "";
    else if(i === 0) return "Professional";
    else if(i === 1) return "Casual";
    else if(i === 2) return "Enthusiastic";
    else if(i === 3) return "Infomational";
    else if(i === 4) return "Funny";
    else return "";
}

export const getAction = (i: number) => {
    if(i === -1) return "";
    else if(i === 0) return "Paragraph";
    else if(i === 1) return "Email";
    else if(i === 2) return "Blog";
    else if(i === 3) return "Summariser";
    else if(i === 4) return "Rephrase";
    else if(i === 5) return "Tone Enhancer";
    else return "";
}

export const getLength = (i: number) => {
    if(i === -1) return "";
    else if(i === 0) return "Short";
    else if(i === 1) return "Medium";
    else if(i === 2) return "Long";
    else return "";
}