import { useNavigate } from "react-router-dom";

const withNavigate = (WrappedComponent: any) => (props: any) => {
  const navigate = useNavigate();

  const navigateFun = (url: string) => {
    navigate(url);
  };

  return <WrappedComponent {...props} navigate={navigateFun} />;
};

export default withNavigate;
// export default withRouter(ExpandConversation);
