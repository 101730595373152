import React, { Component } from "react";

import {
  ArrowCounterclockwise,
  Send,
  WrenchAdjustableCircle,
  XCircle,
} from "react-bootstrap-icons";
import { ChatContent, ChatTextArea } from "./ChatBody";
import { Tooltip } from "@mui/material";

interface TProps {
  handleDelete: any;
  handleChange: any;
  handleSubmit: any;
  isTyped?: any;
  setIsTyped?: any;
  // newResponseReceived?: any;
  embed: boolean;
  query: string;
  msgArr: {
    body: any;
    align: string;
  }[];
  loading: boolean;
  msgBodyRef: React.RefObject<HTMLDivElement>;
  tone: number;
  action: number;
  length: number;
  isToolVisible: boolean;
  validationErr: string;
  handleToolChange: (type: string, val: number) => void;
  openTools: () => void;
  closeTools: () => void;
}

interface TState {}

export default class Compose extends Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
  }

  render() {
    const {
      handleDelete,
      handleChange,
      handleSubmit,
      query,
      msgArr,
      loading,
      msgBodyRef,
      tone,
      length,
      action,
      validationErr,
      isToolVisible,
      handleToolChange,
      openTools,
      closeTools,
    } = this.props;
    return (
      <>
        <div
          className="dropDownChat__msgBody mb-3 px-3"
          // style={{height:"440px"}}
        >
          <div className="d-flex flex-column col" ref={msgBodyRef}>
            <ChatContent
              embed={this.props.embed}
              isTyped={this.props.isTyped}
              setIsTyped={this.props.setIsTyped}
              // newResponseReceived={this.props.newResponseReceived}
              loading={loading}
              msgArr={msgArr}
              msgBodyRef={msgBodyRef}
            />
          </div>
        </div>
        <div className="col d-flex flex-column justify-content-end px-3 pb-1">
          <div className="w-100 d-flex align-items-end align-self-end position-relative">
            <div
              className={`
                dropDownChat__option w-100 bg-white rounded shadow-sm 
                ${isToolVisible ? "dropDownChat__option-active" : ""}
              `}
            >
              <div
                className="d-flex align-items-center justify-content-between p-2"
                role="button"
                onClick={closeTools}
              >
                <h4 className="text-black mb-0 fw-bold">Tools</h4>
                <XCircle size={20} color="black" role="button" />
              </div>
              <div className="text-danger px-2">{validationErr}</div>
              <div className="dropDownChat__item text-black rounded p-2 m-2">
                <p className="fs-5">Tone</p>
                <div className="d-flex align-items-center">
                  <button
                    className={`dropDownChat__btnOpt ${
                      tone === 0 ? "dropDownChat__btnOpt-active" : ""
                    }`}
                    onClick={() => handleToolChange("tone", 0)}
                  >
                    Professional
                  </button>
                  <button
                    className={`dropDownChat__btnOpt ${
                      tone === 1 ? "dropDownChat__btnOpt-active" : ""
                    }`}
                    onClick={() => handleToolChange("tone", 1)}
                  >
                    Casual
                  </button>
                  <button
                    className={`dropDownChat__btnOpt ${
                      tone === 2 ? "dropDownChat__btnOpt-active" : ""
                    }`}
                    onClick={() => handleToolChange("tone", 2)}
                  >
                    Enthusiastic
                  </button>
                  <button
                    className={`dropDownChat__btnOpt ${
                      tone === 3 ? "dropDownChat__btnOpt-active" : ""
                    }`}
                    onClick={() => handleToolChange("tone", 3)}
                  >
                    Infomational
                  </button>
                  <button
                    className={`dropDownChat__btnOpt ${
                      tone === 4 ? "dropDownChat__btnOpt-active" : ""
                    }`}
                    onClick={() => handleToolChange("tone", 4)}
                  >
                    Funny
                  </button>
                </div>
              </div>
              <div className="dropDownChat__item text-black rounded p-2 m-2">
                <p className="fs-5">Action</p>
                <div className="d-flex align-items-center">
                  <button
                    className={`dropDownChat__btnIcon ${
                      action === 0 ? "dropDownChat__btnIcon-active" : ""
                    }`}
                    onClick={() => handleToolChange("action", 0)}
                  >
                    <img src="/assets/images/book_open.svg" alt="" />
                    <p>Paragraph</p>
                  </button>
                  <button
                    className={`dropDownChat__btnIcon ${
                      action === 1 ? "dropDownChat__btnIcon-active" : ""
                    }`}
                    onClick={() => handleToolChange("action", 1)}
                  >
                    <img src="/assets/images/email_at.svg" alt="" />
                    <p>Email</p>
                  </button>
                  <button
                    className={`dropDownChat__btnIcon ${
                      action === 2 ? "dropDownChat__btnIcon-active" : ""
                    }`}
                    onClick={() => handleToolChange("action", 2)}
                  >
                    <img src="/assets/images/blog.svg" alt="" />
                    <p>Blog</p>
                  </button>
                  <button
                    className={`dropDownChat__btnIcon ${
                      action === 3 ? "dropDownChat__btnIcon-active" : ""
                    }`}
                    onClick={() => handleToolChange("action", 3)}
                  >
                    <img src="/assets/images/summariser.svg" alt="" />
                    <p>Summariser</p>
                  </button>
                  <button
                    className={`dropDownChat__btnIcon ${
                      action === 4 ? "dropDownChat__btnIcon-active" : ""
                    }`}
                    onClick={() => handleToolChange("action", 4)}
                  >
                    <img src="/assets/images/rephraser.svg" alt="" />
                    <p>Rephrase</p>
                  </button>
                  <button
                    className={`dropDownChat__btnIcon ${
                      action === 5 ? "dropDownChat__btnIcon-active" : ""
                    }`}
                    onClick={() => handleToolChange("action", 5)}
                  >
                    <img src="/assets/images/tone_enhancer.svg" alt="" />
                    <p>Tone Enhancer</p>
                  </button>
                </div>
              </div>
              <div className="dropDownChat__item text-black rounded p-2 m-2">
                <p className="fs-5">Length</p>
                <div className="d-flex align-items-center">
                  <button
                    className={`dropDownChat__btnOpt ${
                      length === 0 ? "dropDownChat__btnOpt-active" : ""
                    }`}
                    onClick={() => handleToolChange("length", 0)}
                    disabled={action === 3 || action === 4 || action === 5}
                  >
                    Short
                  </button>
                  <button
                    className={`dropDownChat__btnOpt ${
                      length === 1 ? "dropDownChat__btnOpt-active" : ""
                    }`}
                    onClick={() => handleToolChange("length", 1)}
                    disabled={action === 3 || action === 4 || action === 5}
                  >
                    Medium
                  </button>
                  <button
                    className={`dropDownChat__btnOpt ${
                      length === 2 ? "dropDownChat__btnOpt-active" : ""
                    }`}
                    onClick={() => handleToolChange("length", 2)}
                    disabled={action === 3 || action === 4 || action === 5}
                  >
                    Long
                  </button>
                </div>
              </div>
            </div>
            <div className="text-white">
              {/* {!isToolVisible && ( */}
              <Tooltip title="Tools" placement="top">
                <div
                  className="dropDownChat__icon d-flex justify-content-center align-items-center p-2 fs-5 mb-2"
                  role="button"
                  onClick={isToolVisible ? closeTools : openTools}
                >
                  <WrenchAdjustableCircle />
                </div>
              </Tooltip>
              {/* )} */}
              <Tooltip title="Reset" placement="right">
                <div
                  className="dropDownChat__icon d-flex justify-content-center align-items-center p-2 fs-5"
                  role="button"
                  onClick={handleDelete}
                >
                  <ArrowCounterclockwise />
                </div>
              </Tooltip>
            </div>
            <div className="col">
              <div className="d-flex justify-content-center align-items-center p-2 rounded-5 shadow ms-2">
                <textarea
                  placeholder="Type your message"
                  className="dropDownChat__input text-secondary w-100 px-2"
                  value={query}
                  onChange={handleChange}
                  onKeyDown={handleSubmit}
                />
                <div
                  className="dropDownChat__icon d-flex justify-content-center align-items-center p-2 text-white "
                  role="button"
                  onClick={handleSubmit}
                >
                  <Send />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <a
              className="text-secondary text-decoration-none d-flex align-items-center justify-content-center"
              href="https://neuralmind.io/"
              target="_blank"
            >
              <img src="/assets/images/logo-small-icon.svg" alt="" />
              <p className="mb-0 ms-1">Powered by Neuralmind.io</p>
            </a>
          </div>
        </div>
      </>
    );
  }
}
