import axios from "../helpers/axios";

export const getTrailInfo = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        "/api/v1/verify-account-trail"
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};