import * as React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Papa from "papaparse";
import * as pdfjs from "pdfjs-dist";
import sha256 from "crypto-js/sha256";
import { Button, Modal } from "react-bootstrap";

import {
  checkSync,
  getEditorJsDataById,
  postEditor,
  syncEditor,
  unSyncEditor,
} from "../../apis/editor";
import { toastHelper } from "../../helpers/toast";
import myEditor from "../../helpers/funcs/init-editor";
import withRouter from "../../helpers/params";
import withNavigate from "../../helpers/navigate";
import GetStarted from "../../components/Train/GetStarted";
import { getArticles, putArticle } from "../../apis/article";
import { csvFileConvert } from "../../helpers/funcs/csv-file-reader";
import {
  foundArticleFromTree,
  indexOfArticleSearch,
  prepareCSVToEditor,
} from "../../helpers/funcs/tree-founder";
import { writePdfToEditor } from "../../helpers/funcs/writePdfToEditor";

interface TProps {
  params: { id: string };
  queryModel: string;
  navigate: any;
  activeModel: string;
}

interface TState {
  id: string;
  callback?: any;
  savingStatus: any;
  savingLabel: any;
  syncStatus: boolean;
  csvData: "";
  objectData: [];
  file: any;
  csvModal: boolean;
}

class Train extends React.Component<TProps, TState> {
  state: TState = {
    id: "",
    savingStatus: false,
    savingLabel: "Save",
    syncStatus: false,
    csvData: "",
    objectData: [],
    file: null,
    csvModal: false,
  };

  upload: any = undefined;
  uploadPdf: any = undefined;
  changeSyncStatus = () => {
    this.setState({ ...this.setState, syncStatus: false });
  };

  counter = 0;
  editorContainer = myEditor(
    {
      time: 1674449797325,
      blocks: [
        {
          id: "BAgtkD0Vwr",
          type: "paragraph",
          data: { text: "Start here!" },
        },
      ],
      version: "2.26.4",
    },
    this.changeSyncStatus
  );

  constructor(props: TProps) {
    super(props);
    this.state.id = this.props.params.id;
    this.syncEditor = this.syncEditor.bind(this);
    this.unSyncEditor = this.unSyncEditor.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
  }

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ file: event.target.files?.[0] || null });
  };

  handlePdfFileUpload = async (event: any) => {
    const eFile: any = event.target.files?.[0];
    if (!eFile) {
      return;
    }

    const id = toastHelper.loading("Uploading");

    const data = await this.readFileContents(eFile);
    const text = await this.parsePDF(data);

    const res: any = await getArticles();
    const articles = res.articles;
    const location: number[] = await foundArticleFromTree(
      articles,
      this.props.params.id
    );

    const file_name = text
      .split(" ")
      .filter((value: any, index: any) => {
        if (index < 5) return value;
      })
      .join(" ");

    const update = articles[location[0]];
    update.categorys[location[1]].folders[location[2]].files.push({
      file_name,
    });

    await putArticle(update);
    const updatedArticlesRes: any = await getArticles();

    const postedArticleLocation: any = indexOfArticleSearch(
      updatedArticlesRes.articles,
      file_name
    );

    await postEditor({
      filename: `${sha256(
        `${
          updatedArticlesRes.articles[postedArticleLocation[0]].categorys[
            postedArticleLocation[1]
          ].folders[postedArticleLocation[2]].files[postedArticleLocation[3]]
            ._id
        }`
      ).toString()}.json`,
      payload: writePdfToEditor(text),
    });
    toastHelper.success("PDF uploaded!", id);

    // update.categorys[location[1]];
  };

  readFileContents = (file: File): Promise<ArrayBuffer> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) =>
        resolve(event.target?.result as ArrayBuffer);
      reader.onerror = (error: ProgressEvent<FileReader>) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  };

  parsePDF = (data: ArrayBuffer): Promise<string> => {
    return pdfjs.getDocument(data).promise.then((document) => {
      const pages = Array.from({ length: document.numPages }, (_, i) => i + 1);
      return Promise.all(
        pages.map((pageNumber) =>
          document.getPage(pageNumber).then((page) => page.getTextContent())
        )
      ).then((pageContents) =>
        pageContents
          .map((content) =>
            content.items.map((item: any) => item.str).join(" ")
          )
          .join("<br>")
      );
    });
  };

  handleFileUpload(event: any) {
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();

    reader.onload = async () => {
      const csv: any = reader.result as string;

      this.setState({ csvData: csv });
      Papa.parse(csv, {
        header: true,
        complete: async (results: any) => {
          this.setState({ objectData: results.data });
          const res: any = await getArticles();
          const articlesRes: any[] = res.articles;
          if (
            articlesRes.filter(
              (value) => value.model_name == this.props.activeModel
            ).length == 0
          )
            return toastHelper.error("Could not select the active model!");

          let update = articlesRes.filter(
            (value) => value.model_name == this.props.activeModel
          )[0];

          const articles: any[] = results.data;
          const files: any[] = [];
          const editors = [];
          const id = toastHelper.loading("Uploading");
          for (let i = 0; i < articles.length; i++) {
            const article = articles[i];
            if (!article["ID"])
              return toastHelper.error(
                "CSV file is invalid. ID column missing.",
                id
              );
            if (!article["Category"])
              return toastHelper.error(
                "CSV file is invalid. Category column missing.",
                id
              );
            if (!article["Folder"])
              return toastHelper.error(
                "CSV file is invalid. Folder column missing.",
                id
              );
            if (!article["Title"])
              return toastHelper.error(
                "CSV file is invalid. Title column missing.",
                id
              );
            if (!article["Description"])
              return toastHelper.error(
                "CSV file is invalid. Description column missing.",
                id
              );

            update = prepareCSVToEditor(
              update,
              article["Category"].trim(),
              article["Folder"].trim(),
              article["Title"].trim()
            );

            const articleResponse: any = await putArticle(update);
            // Let's update it in the actual editor too
            let _id = undefined;
            for (let j = 0; j < articleResponse.result.categorys.length; j++) {
              const category = articleResponse.result.categorys[j];
              // Loop through all categorys^

              for (let x = 0; x < category.folders.length; x++) {
                const folder: any = category.folders[x];
                for (let y = 0; y < folder.files.length; y++) {
                  const file: any = folder.files[y];
                  if (article["Title"].trim() == file.file_name) _id = file._id;
                }
              }
            }
            if (_id) {
              const payload = await csvFileConvert(article);

              await postEditor({
                filename: `${sha256(`${_id}`).toString()}.json`,
                payload: payload,
              });
            }
          }

          toastHelper.success("CSV file uploaded", id);
        },
      });
    };

    reader.readAsText(file);
  }

  componentDidMount(): void {
    this.checkSync();
    setTimeout(() => this.editor(), 300);
    // The workerSrc property shall be specified.
    pdfjs.GlobalWorkerOptions.workerSrc = "/assets/js/pdf.worker.js";
  }

  async componentDidUpdate(prevProps: TProps) {
    if (prevProps.params.id !== this.props.params.id) {
      this.checkSync();
      this.setState({ ...this.state });
      // First save the prev editor data
      if (this.props.params.id != undefined) {
        await this.editorContainer.save(prevProps.params.id);
        await this.editor();
      }
    }
  }

  async componentWillUnmount() {
    try {
      // Save the editor here
      // Save here frist
      await this.editorContainer.save(this.props.params.id);
      await this.editor();
    } catch (error) {}
  }

  saveBtn = async () => {
    try {
      this.setState({
        ...this.state,
        savingLabel: "Saving",
        savingStatus: true,
      });
      await this.editorContainer.save(this.props.params.id);
      this.setState({
        ...this.state,
        savingLabel: "Saved",
        savingStatus: false,
      });
      setTimeout(() => {
        this.setState({ ...this.state, savingLabel: "Save" });
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  editor = async () => {
    try {
      this.editorContainer.clean();
      const data: any = await getEditorJsDataById(this.props.params.id);
      this.editorContainer.refresh(data, this.props.params.id);
    } catch (error) {
      console.log(error);
    }
  };

  async syncEditor() {
    const processSync = new Promise(async (resolve: any, reject: any) => {
      try {
        const response = await syncEditor(
          this.props.params.id,
          this.props.queryModel
        );

        this.setState({ ...this.state, syncStatus: true });
        resolve("Sync Done!");
      } catch (error: any) {
        console.log(error);
        this.setState({ ...this.state, syncStatus: false });

        if (error == "Error: 414") {
          return reject(
            "You have reached the maximum word limit of 1.8k words per article. Please add the remaining content in a new article."
          );
        }
        return reject("Something wrong with Syncing");
      }
    });

    const id = toastHelper.loading("Syncing");

    processSync
      .then((response: any) => {
        toastHelper.success(response, id);
      })
      .catch((error: any) => {
        toastHelper.error(error, id);
      });
  }

  async unSyncEditor() {
    try {
      const response = await unSyncEditor(this.props.params.id);
      this.setState({ ...this.state, syncStatus: false });
      toastHelper.success("Article has been unsynced.");
    } catch (error) {
      console.log(error);
      toastHelper.error("Something went wrong, try again or contact support.");
    }
  }

  bulkSync = async () => {
    try {
      const id = toastHelper.loading("Syncing");
      // find the model name
      let response: any = await getArticles();

      const articles: any = response.articles.filter(
        (value: any) => value.model_name == this.props.activeModel
      );

      if (articles.length == 0)
        return toastHelper.error("Error: Couldn't select the active model.");

      let counter = 0;
      let syncs = 0;

      for (let index = 0; index < articles.length; index++) {
        const article = articles[index];

        for (let j = 0; j < article.categorys.length; j++) {
          const category: any = article.categorys[j];

          for (let y = 0; y < category.folders.length; y++) {
            const folder = category.folders[y];

            for (let x = 0; x < folder.files.length; x++) {
              const file = folder.files[x];
              counter++;
              console.log(file);
              try {
                const filename: string = `${sha256(
                  `${file._id}`
                ).toString()}.json`;

                await syncEditor(filename, this.props.activeModel);
                syncs++;
              } catch (error) {}
            }
          }
        }
      }

      toastHelper.success(`${syncs} articles synced out of ${counter}!`, id);
    } catch (error) {
      console.log(error);
    }
  };

  checkSync = async () => {
    try {
      const response = await checkSync(this.props.params.id);
      this.setState({ syncStatus: true });
    } catch (error) {
      this.setState({ syncStatus: false });
      console.log(error);
    }
  };

  render() {
    const { syncStatus } = this.state;
    const display = !this.props.params.id ? "none" : "block";
    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-2 p-2">
          {/* {this.props.params.id && ( */}
          <>
            <div className="d-flex align-items-center">
              {/* <Dropdown>
                  <Dropdown.Toggle className="btn-green" size="sm">
                    Upload a file
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => this.uploadPdf.click()}>
                      Upload a PDF
                    </Dropdown.Item>
                    <Dropdown.Divider />
                        <Dropdown.Item
                          onClick={() => {
                            this.setState({ csvModal: true })
                          }}
                        >
                          Upload a CSV{" "}
                        </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
              <p
                className="text-secondary ms-3 mb-0"
                style={{ fontSize: "14px" }}
              >
                Maximum of 1800 words per article
              </p>
            </div>
            <input
              type="file"
              accept=".csv"
              style={{ display: "none" }}
              ref={(ref) => (this.upload = ref)}
              onChange={this.handleFileUpload}
            />
            <input
              type="file"
              accept=".pdf"
              style={{ display: "none" }}
              ref={(ref) => (this.uploadPdf = ref)}
              onChange={this.handlePdfFileUpload}
            />
            <div
              style={{ gap: "5px" }}
              className="d-flex justify-content-between align-items-center list-item-sync"
            >
              {this.state.syncStatus && (
                <div style={{ display: "flex", gap: "5px" }}>
                  <img src="/assets/images/tick.svg" />
                  <span style={{ color: "#20A957" }}>Synced</span>
                </div>
              )}
              <Button
                onClick={syncStatus ? this.unSyncEditor : this.syncEditor}
                style={{
                  border: syncStatus ? "1px solid #3E6FE1" : "unset",
                  borderRadius: "5px",
                  background: syncStatus
                    ? "#FFF" // No background when unsynced
                    : "linear-gradient(to bottom right, #4978F2, #1030B2)",
                  color: syncStatus ? "#4978F2" : "#FFFFFF", // Change text color when unsynced
                  boxShadow: syncStatus
                    ? "0px 1px 2px 0px rgba(0, 0, 0, 0.15)"
                    : "0px 1px 2px 0px rgba(0, 0, 0, 0.15)" /* Increased the box shadow */,
                }}
                type="submit"
              >
                {syncStatus ? "End Sync" : "Sync with AI"}
              </Button>
            </div>
          </>
          {/* )} */}
        </div>
        <div>
          
          <div
            id="editorjs"
            className="w-100 h-100 p-2"
            style={{ display }}
          ></div>
        </div>
        <Modal
          show={this.state.csvModal}
          onHide={() => this.setState({ csvModal: false })}
        >
          <Modal.Header closeButton className="border-0" />
          <Modal.Body className="p-4">
            <h3 className="fw-bold text-center">CSV Format</h3>
            <div className="text-center mt-4">
              <Button
                className="btn-black"
                href="https://google.com"
                target="_blank"
              >
                Link
              </Button>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-end border-0">
            <Button
              className="btn-green"
              onClick={() =>
                this.setState({ csvModal: false }, this.upload.click())
              }
            >
              Continue
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default withNavigate(withRouter(Train));
