import * as React from "react";
import DropDownChat from "../DropDownChat/DropDownChat";

import "../../style/preview-embed.scss";
import DropDownChatNew from "../DropDownChat/DropDownChatNew";

interface PreviewEmbedProps {
  companyLogo: any;
  welcomeMsg: string;
  welcomeMsgInsights: string;
  placement: "top-start" | "top-end" | "bottom-start" | "bottom-end";
  insightCheckbox: boolean;
  productChecbox: boolean;
  buttonText: string;
  buttonType: string;
}

interface PreviewEmbedState {}

class PreviewEmbed extends React.Component<
  PreviewEmbedProps,
  PreviewEmbedState
> {
  textareaRef = React.createRef<HTMLTextAreaElement>();

  constructor(props: PreviewEmbedProps) {
    super(props);
  }

  getPlacementStyle = (): React.CSSProperties => {
    let arr = this.props.placement.split("-");

    return {
      display: "flex",
      flexDirection: arr[0] == "bottom" ? "column" : "column-reverse",
      alignItems: arr[1] == "start" ? "flex-start" : "flex-end",
    };
  };

  render() {
    return (
      <div className="preview__body h-100 d-flex flex-column">
        <h4 className="fw-bold embed-item-step-2">Preview</h4>
        <div style={this.getPlacementStyle()}>
          <button className="preview__btn">
            {this.props.buttonType == "text" ? (
              <span>{this.props.buttonText}</span>
            ) : (
              <img
                src={
                  this.props.companyLogo
                    ? this.props.companyLogo
                    : "/assets/images/logo.svg"
                }
                width="35"
                height="35"
              />
            )}
          </button>
          <div className="preview__chat">
            <DropDownChat
              embed={true}
              disabled={true}
              initialMsg={this.props.welcomeMsg}
              insightMsg={this.props.welcomeMsgInsights}
            />
            {/* <DropDownChatNew
              embed={true}
              disabled={true}
              initialMsg={this.props.welcomeMsg}
              insightMsg={this.props.welcomeMsgInsights}
            /> */}
          </div>
        </div>
      </div>
    );
  }
}

export default PreviewEmbed;
