export const writePdfToEditor = (text: string) => {
  return {
    time: {
      $numberLong: "1675179062539",
    },
    blocks: [
      {
        id: Math.floor(Math.random() * 100000) + 1,
        type: "paragraph",
        data: {
          text: text,
        },
      },
    ],
    version: "2.26.4",
  };
};
